import styled from "styled-components"

export const ActionBarSpacer = styled.div`
  flex: 1;
`

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  > .MuiButtonBase-root {
    margin-right: 12px;
  }
`

export default ActionBar
