import { Button } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { compose } from "redux"

import ActionBar from "../../components/ActionBar"
import BiographyImageUpload from "../../components/BiographyImageUpload"
import { PrimaryButton } from "../../components/Buttons"
import BiographyForm from "../../components/Forms/BiographyForm"
import Grid from "../../components/Grid"
import { HistoryLocation } from "../../components/HistoryLink"
import { UploadedImage } from "../../components/ImageUpload"
import { PageWrapper } from "../../components/Layout"
import Config from "../../core/Config"
import FormErrors from "../../core/errors/FormError"
import { Biography, NewBiography } from "../../core/models/Biography"
import privateRoute from "../../hoc/privateRoute"
import { AsyncResult } from "../../redux/middleware/asyncMiddleware"
import {
  fetchBiography,
  updateBiography,
  uploadProfilePicture,
} from "../../redux/modules/biography"
import { dispatchToast, ToastType } from "../../redux/modules/toasts"
import { StoreState } from "../../redux/reducer"

interface StoreProps {
  biography: Biography
}

interface DispatchProps {
  fetchBiography: (id: string) => Promise<AsyncResult>
  updateBiography: (updatedBiography: Biography) => Promise<AsyncResult>
  uploadProfilePicture: (
    biographyId: string,
    uploadedFile: File
  ) => Promise<AsyncResult>
  dispatchToast: (message: string, type: ToastType, timeout?: number) => string
}

interface EditBiographyPageProps extends StoreProps, DispatchProps {}

const EditBiographyPage: React.FC<EditBiographyPageProps> = ({
  biography,
  updateBiography,
  uploadProfilePicture,
  fetchBiography,
  dispatchToast,
}) => {
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()
  const { id } = useParams<{ id: string }>()

  const [uploadedImage, setUploadedImage] = useState<UploadedImage>()
  const [errors, setErrors] = useState<FormErrors>({})
  const [biographyToUpdate, setBiographyToUpdate] = useState<Biography>(
    biography
  )

  useEffect(() => {
    fetchBiography(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setBiographyToUpdate(biography)
  }, [biography])

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push(`/biography/${id}`)
  }

  const onSave = () => {
    if (biographyToUpdate) {
      updateBiography(biographyToUpdate)
        .then(() => {
          if (uploadedImage) {
            uploadProfilePicture(biography.id, uploadedImage.file)
              .then(goBack)
              .catch(e => {
                dispatchToast(
                  "Whoops, failed to upload images. Please try again.",
                  ToastType.Error,
                  8000
                )
              })
          } else {
            goBack()
          }
        })
        .catch(errorResponse => {
          if (errorResponse?.body?.name === "ValidationError") {
            dispatchToast(
              "Errors found in your form. Please make sure you have filled all mandatory fields.",
              ToastType.Error,
              8000
            )
            setErrors(errorResponse?.body?.errors)
          } else {
            dispatchToast(
              "Whoops, something went wrong. Please try again.",
              ToastType.Error,
              8000
            )
          }
        })
    }
  }

  const onBiographyChange = (updatedBiography: NewBiography) =>
    setBiographyToUpdate(updatedBiography as Biography)

  function onAddImages(uploadedImages: Array<UploadedImage>) {
    const [imageToUpload] = uploadedImages

    if (imageToUpload.fileSize >= Config.uploads.maxImgSizeMB) {
      dispatchToast(
        "Whoops, selected image is too big! Try to compress your image below 10MBs.",
        ToastType.Error,
        8000
      )
    } else {
      setUploadedImage(imageToUpload)
    }
  }

  return (
    <PageWrapper>
      <ActionBar>
        <Button onClick={goBack}>Back</Button>
        <PrimaryButton onClick={onSave}>Save</PrimaryButton>
      </ActionBar>

      <Grid columns="1fr 300px" gap="32px" align="start">
        <BiographyForm
          biography={biographyToUpdate || {}}
          errors={errors}
          onChange={onBiographyChange}
        />
        <BiographyImageUpload
          uploadedImage={uploadedImage}
          onAddImages={onAddImages}
        />
      </Grid>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      biography: state.biography.biography,
    }),
    {
      fetchBiography,
      updateBiography,
      uploadProfilePicture,
      dispatchToast,
    }
  )
)(EditBiographyPage)
