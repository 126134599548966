import ApiClient from "../../core/ApiClient"
import Config from "../../core/Config"
import Status from "../../core/enums/Status"
import Order from "../../core/models/Order"
import { AsyncAction, asyncType } from "../middleware/asyncMiddleware"

export interface OrderStore {
  activeOrder: Order | null
  activeOrderStatus: Status

  orders: Array<Order>
  ordersStatus: Status
}

const initialState = {
  activeOrder: null,
  activeOrderStatus: Status.INITIAL,

  orders: [] as Array<Order>,
  ordersStatus: Status.INITIAL,
}

const FETCH_ORDER = asyncType("redux.order.FETCH_ORDER")
const FETCH_ORDERS = asyncType("redux.order.FETCH_ORDERS")

export default function reducer(
  state = initialState,
  action: AsyncAction
): OrderStore {
  switch (action.type) {
    case FETCH_ORDER.INITIAL: {
      return {
        ...state,
        activeOrder: null,
        activeOrderStatus: Status.LOADING,
      }
    }

    case FETCH_ORDER.SUCCESS: {
      return {
        ...state,
        activeOrder: action.result?.body as Order,
        activeOrderStatus: Status.SUCCESS,
      }
    }

    case FETCH_ORDER.FAIL: {
      return {
        ...state,
        activeOrderStatus: Status.FAIL,
      }
    }

    case FETCH_ORDERS.INITIAL: {
      return {
        ...state,
        orders: [],
        ordersStatus: Status.LOADING,
      }
    }

    case FETCH_ORDERS.SUCCESS: {
      const newOrders = action.result?.body as Array<Order>

      const updatedOrders = state.orders.map(
        order => newOrders.find(newOrder => newOrder.id === order.id) || order
      )

      return {
        ...state,
        orders: updatedOrders.concat(
          newOrders.filter(
            newOrder =>
              !updatedOrders.some(
                existingOrder => existingOrder.id === newOrder.id
              )
          )
        ),
        ordersStatus: Status.SUCCESS,
      }
    }

    case FETCH_ORDERS.FAIL: {
      return {
        ...state,
        ordersStatus: Status.FAIL,
      }
    }

    default:
      return state
  }
}

export function fetchOrder(id: string) {
  return {
    types: FETCH_ORDER,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/order/${id}`),
  }
}

export function fetchOrders() {
  return {
    types: FETCH_ORDERS,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/order`),
  }
}
