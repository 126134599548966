import { Button } from "@material-ui/core"
import React, { useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { compose } from "redux"

import ActionBar from "../../components/ActionBar"
import { PrimaryButton } from "../../components/Buttons"
import AuctionForm from "../../components/Forms/AuctionForm"
import Grid from "../../components/Grid"
import { HistoryLocation } from "../../components/HistoryLink"
import { PageWrapper } from "../../components/Layout"
import Status from "../../core/enums/Status"
import FormErrors from "../../core/errors/FormError"
import { NewAuction } from "../../core/models/Auction"
import privateRoute from "../../hoc/privateRoute"
import { AsyncResult } from "../../redux/middleware/asyncMiddleware"
import { createAuction } from "../../redux/modules/auction"
import { dispatchToast, ToastType } from "../../redux/modules/toasts"
import { StoreState } from "../../redux/reducer"

interface StoreProps {
  activeAuctionStatus: Status
}

interface DispatchProps {
  createAuction: (newAuction: NewAuction) => Promise<AsyncResult>
  dispatchToast: (message: string, type: ToastType, timeout?: number) => string
}

interface NewAuctionPageProps extends StoreProps, DispatchProps {}

const NewAuctionPage: React.FC<NewAuctionPageProps> = ({
  activeAuctionStatus,
  createAuction,
  dispatchToast,
}) => {
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()

  const [errors, setErrors] = useState<FormErrors>({})
  const [newAuction, setNewAuction] = useState<NewAuction>({
    title: "",
    description: "",
    slug: "",
  })

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push("/auctions")
  }

  const onCreate = () => {
    if (newAuction) {
      createAuction(newAuction)
        .then(() => {
          goBack()
        })
        .catch(errorResponse => {
          if (errorResponse?.body?.name === "ValidationError") {
            dispatchToast(
              "Errors found in your form. Please make sure you have filled all mandatory fields.",
              ToastType.Error,
              8000
            )
            setErrors(errorResponse?.body?.errors)
          } else {
            dispatchToast(
              "Whoops, something went wrong. Please try again.",
              ToastType.Error,
              8000
            )
          }
        })
    }
  }

  const onAuctionChange = (newAuctionData: NewAuction) =>
    setNewAuction(newAuctionData)

  return (
    <PageWrapper>
      <ActionBar>
        <Button onClick={goBack}>Back</Button>
        <PrimaryButton
          onClick={onCreate}
          disabled={activeAuctionStatus === Status.LOADING}
        >
          Create
        </PrimaryButton>
      </ActionBar>

      <Grid columns="1fr 300px" gap="32px">
        <AuctionForm
          auction={newAuction || {}}
          errors={errors}
          onChange={onAuctionChange}
        />
      </Grid>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      activeAuctionStatus: state.auction.activeAuctionStatus,
    }),
    {
      createAuction,
      dispatchToast,
    }
  )
)(NewAuctionPage)
