enum ArtStyle {
  Abstract = "Abstract",
  AbstractExpressionism = "AbstractExpressionism",
  Marble = "Marble",
  ArtDeco = "ArtDeco",
  Cartoon = "Cartoon",
  ColourField = "ColourField",
  Conceptual = "Conceptual",
  Cubism = "Cubism",
  Dada = "Dada",
  Expressionistic = "Expressionistic",
  Figurative = "Figurative",
  Geometric = "Geometric",
  GeometricAbstract = "GeometricAbstract",
  Graphic = "Graphic",
  Illustrative = "Illustrative",
  Impressionistic = "Impressionistic",
  Minimalistic = "Minimalistic",
  Mosaic = "Mosaic",
  Photorealism = "Photorealism",
  PopArt = "PopArt",
  Realistic = "Realistic",
  Stencil = "Stencil",
  StreetArt = "StreetArt",
  Surrealist = "Surrealist",
}

export const ArtStyleTranslations = {
  [ArtStyle.Abstract]: "Abstract",
  [ArtStyle.AbstractExpressionism]: "Abstract Expressionism",
  [ArtStyle.Marble]: "Marble",
  [ArtStyle.ArtDeco]: "Art deco",
  [ArtStyle.Cartoon]: "Cartoon",
  [ArtStyle.ColourField]: "Colour Field",
  [ArtStyle.Conceptual]: "Conceptual",
  [ArtStyle.Cubism]: "Cubism",
  [ArtStyle.Dada]: "Dada",
  [ArtStyle.Expressionistic]: "Expressionistic",
  [ArtStyle.Figurative]: "Figurative",
  [ArtStyle.Geometric]: "Geometric",
  [ArtStyle.GeometricAbstract]: "Geometric Abstract",
  [ArtStyle.Graphic]: "Graphic",
  [ArtStyle.Illustrative]: "Illustrative",
  [ArtStyle.Impressionistic]: "Impressionistic",
  [ArtStyle.Minimalistic]: "Minimalistic",
  [ArtStyle.Mosaic]: "Mosaic",
  [ArtStyle.Photorealism]: "Photorealism",
  [ArtStyle.PopArt]: "Pop Art",
  [ArtStyle.Realistic]: "Realistic",
  [ArtStyle.Stencil]: "Stencil",
  [ArtStyle.StreetArt]: "Street Art",
  [ArtStyle.Surrealist]: "Surrealist",
}

export default ArtStyle
