enum ArtMedium {
  Collage = "Collage",
  CollageFabric = "CollageFabric",
  CollageMixedMedia = "CollageMixedMedia",
  CollagePaper = "CollagePaper",
  CollagePhoto = "CollagePhoto",
  Digital = "Digital",
  DigitalVector = "DigitalVector",
  Drawings = "Drawings",
  DrawingsChalk = "DrawingsChalk",
  DrawingsCharcoal = "DrawingsCharcoal",
  DrawingsCrayon = "DrawingsCrayon",
  DrawingsInk = "DrawingsInk",
  DrawingsPastel = "DrawingsPastel",
  DrawingsPencil = "DrawingsPencil",
  Installation = "Installation",
  Paintings = "Paintings",
  PaintingsAcrylic = "PaintingsAcrylic",
  PaintingsAirbrush = "PaintingsAirbrush",
  PaintingsFabric = "PaintingsFabric",
  PaintingsGouache = "PaintingsGouache",
  PaintingsInk = "PaintingsInk",
  PaintingsMixedMedia = "PaintingsMixedMedia",
  PaintingsOil = "PaintingsOil",
  PaintingsWatercolour = "PaintingsWatercolour",
  Photography = "Photography",
  PhotographyBlackWhite = "PhotographyBlackWhite",
  PhotographyColour = "PhotographyColour",
  PhotographyDigital = "PhotographyDigital",
  PhotographyMixedMedia = "PhotographyMixedMedia",
  Prints = "Prints",
  PrintsDigital = "PrintsDigital",
  PrintsEtching = "PrintsEtching",
  PrintsLino = "PrintsLino",
  PrintsLithograph = "PrintsLithograph",
  PrintsMixedMedia = "PrintsMixedMedia",
  PrintsMono = "PrintsMono",
  PrintsScreenprint = "PrintsScreenprint",
  PrintsTransfer = "PrintsTransfer",
  PrintsWoodcut = "PrintsWoodcut",
  Sculpture = "Sculpture",
  SculptureBronze = "SculptureBronze",
  SculptureCeramic = "SculptureCeramic",
  SculptureEnamel = "SculptureEnamel",
  SculptureGlass = "SculptureGlass",
  SculptureMetal = "SculptureMetal",
  SculpturePaper = "SculpturePaper",
  SculpturePlastic = "SculpturePlastic",
  SculptureResin = "SculptureResin",
  SculptureStone = "SculptureStone",
  SculptureWood = "SculptureWood",
}

export const ArtMediumTranslations = {
  [ArtMedium.Collage]: "Collage",
  [ArtMedium.CollageFabric]: "Collage > Fabric",
  [ArtMedium.CollageMixedMedia]: "Collage > Mixed Media",
  [ArtMedium.CollagePaper]: "Collage > Paper",
  [ArtMedium.CollagePhoto]: "Collage > Photo",
  [ArtMedium.Digital]: "Digital",
  [ArtMedium.DigitalVector]: "Digital > Vector",
  [ArtMedium.Drawings]: "Drawings",
  [ArtMedium.DrawingsChalk]: "Drawings > Chalk",
  [ArtMedium.DrawingsCharcoal]: "Drawings > Charcoal",
  [ArtMedium.DrawingsCrayon]: "Drawings > Crayon",
  [ArtMedium.DrawingsInk]: "Drawings > Ink",
  [ArtMedium.DrawingsPastel]: "Drawings > Pastel",
  [ArtMedium.DrawingsPencil]: "Drawings > Pencil",
  [ArtMedium.Installation]: "Installation",
  [ArtMedium.Paintings]: "Paintings",
  [ArtMedium.PaintingsAcrylic]: "Paintings > Acrylic",
  [ArtMedium.PaintingsAirbrush]: "Paintings > Airbrush",
  [ArtMedium.PaintingsFabric]: "Paintings > Fabric",
  [ArtMedium.PaintingsGouache]: "Paintings > Gouache",
  [ArtMedium.PaintingsInk]: "Paintings > Ink",
  [ArtMedium.PaintingsMixedMedia]: "Paintings > Mixed Media",
  [ArtMedium.PaintingsOil]: "Paintings > Oil",
  [ArtMedium.PaintingsWatercolour]: "Paintings > Watercolour",
  [ArtMedium.Photography]: "Photography",
  [ArtMedium.PhotographyBlackWhite]: "Photography > Black & White",
  [ArtMedium.PhotographyColour]: "Photography > Colour",
  [ArtMedium.PhotographyDigital]: "Photography > Digital",
  [ArtMedium.PhotographyMixedMedia]: "Photography > Mixed media",
  [ArtMedium.Prints]: "Prints",
  [ArtMedium.PrintsDigital]: "Prints > Digital",
  [ArtMedium.PrintsEtching]: "Prints > Etching",
  [ArtMedium.PrintsLino]: "Prints > Lino",
  [ArtMedium.PrintsLithograph]: "Prints > Lithograph",
  [ArtMedium.PrintsMixedMedia]: "Prints > Mixed media",
  [ArtMedium.PrintsMono]: "Prints > Mono",
  [ArtMedium.PrintsScreenprint]: "Prints > Screenprint",
  [ArtMedium.PrintsTransfer]: "Prints > Transfer",
  [ArtMedium.PrintsWoodcut]: "Prints > Woodcut",
  [ArtMedium.Sculpture]: "Sculpture",
  [ArtMedium.SculptureBronze]: "Sculpture > Bronze",
  [ArtMedium.SculptureCeramic]: "Sculpture > Ceramic",
  [ArtMedium.SculptureEnamel]: "Sculpture > Enamel",
  [ArtMedium.SculptureGlass]: "Sculpture > Glass",
  [ArtMedium.SculptureMetal]: "Sculpture > Metal",
  [ArtMedium.SculpturePaper]: "Sculpture > Paper",
  [ArtMedium.SculpturePlastic]: "Sculpture > Plastic",
  [ArtMedium.SculptureResin]: "Sculpture > Resin",
  [ArtMedium.SculptureStone]: "Sculpture > Stone",
  [ArtMedium.SculptureWood]: "Sculpture > Wood",
}

export default ArtMedium
