import ApiClient from "../../core/ApiClient"
import Config from "../../core/Config"
import Status from "../../core/enums/Status"
import { Biography, NewBiography } from "../../core/models/Biography"
import { AsyncAction, asyncType } from "../middleware/asyncMiddleware"

export interface BiographyStore {
  biographies: Array<Biography>
  biographiesStatus: Status

  biography?: Biography
  biographyStatus: Status

  userBiography?: Biography
  userBiographyStatus: Status

  imageUploadStatus: Status
}

const initialState = {
  biographies: [] as Array<Biography>,
  biographiesStatus: Status.INITIAL,

  biography: undefined,
  biographyStatus: Status.INITIAL,

  userBiography: undefined,
  userBiographyStatus: Status.INITIAL,

  imageUploadStatus: Status.INITIAL,
}

const FETCH_BIOGRAPHIES = asyncType("redux.biography.FETCH_BIOGRAPHIES")
const FETCH_BIOGRAPHY = asyncType("redux.biography.FETCH_BIOGRAPHY")
const FETCH_USER_BIOGRAPHY = asyncType("redux.biography.FETCH_USER_BIOGRAPHY")
const CREATE_BIOGRAPHY = asyncType("redux.biography.CREATE_BIOGRAPHY")
const UPDATE_BIOGRAPHY = asyncType("redux.biography.UPDATE_BIOGRAPHY")
const DELETE_BIOGRAPHY = asyncType("redux.biography.DELETE_BIOGRAPHY")
const UPLOAD_PROFILE_PICTURE = asyncType(
  "redux.biography.UPLOAD_PROFILE_PICTURE"
)

export default function reducer(
  state = initialState,
  action: AsyncAction
): BiographyStore {
  switch (action.type) {
    case FETCH_BIOGRAPHIES.INITIAL: {
      return {
        ...state,
        biographiesStatus: Status.LOADING,
      }
    }

    case FETCH_BIOGRAPHIES.SUCCESS: {
      return {
        ...state,
        biographies: action.result?.body as Array<Biography>,
        biographiesStatus: Status.SUCCESS,
      }
    }

    case FETCH_BIOGRAPHIES.FAIL: {
      return {
        ...state,
        biographiesStatus: Status.FAIL,
      }
    }

    case FETCH_BIOGRAPHY.INITIAL: {
      return {
        ...state,
        biographyStatus: Status.LOADING,
      }
    }

    case FETCH_BIOGRAPHY.SUCCESS: {
      return {
        ...state,
        biography: action.result?.body as Biography,
        biographyStatus: Status.SUCCESS,
      }
    }

    case FETCH_BIOGRAPHY.FAIL: {
      return {
        ...state,
        biographyStatus: Status.FAIL,
      }
    }

    case FETCH_USER_BIOGRAPHY.INITIAL: {
      return {
        ...state,
        userBiographyStatus: Status.LOADING,
      }
    }

    case FETCH_USER_BIOGRAPHY.SUCCESS: {
      return {
        ...state,
        userBiography: action.result?.body as Biography,
        userBiographyStatus: Status.SUCCESS,
      }
    }

    case FETCH_USER_BIOGRAPHY.FAIL: {
      return {
        ...state,
        userBiographyStatus: Status.FAIL,
      }
    }

    case UPDATE_BIOGRAPHY.INITIAL:
    case CREATE_BIOGRAPHY.INITIAL: {
      return {
        ...state,
        biographyStatus: Status.LOADING,
      }
    }

    case UPDATE_BIOGRAPHY.SUCCESS:
    case CREATE_BIOGRAPHY.SUCCESS: {
      return {
        ...state,
        biography: action.result?.body as Biography,
        biographyStatus: Status.SUCCESS,
      }
    }

    case UPDATE_BIOGRAPHY.FAIL:
    case CREATE_BIOGRAPHY.FAIL: {
      return {
        ...state,
        biographyStatus: Status.FAIL,
      }
    }

    case UPLOAD_PROFILE_PICTURE.INITIAL: {
      return {
        ...state,
        imageUploadStatus: Status.LOADING,
      }
    }

    case UPLOAD_PROFILE_PICTURE.SUCCESS: {
      return {
        ...state,
        imageUploadStatus: Status.SUCCESS,
      }
    }

    case UPLOAD_PROFILE_PICTURE.FAIL: {
      return {
        ...state,
        imageUploadStatus: Status.FAIL,
      }
    }

    case DELETE_BIOGRAPHY.INITIAL: {
      return {
        ...state,
        biographyStatus: Status.LOADING,
      }
    }

    case DELETE_BIOGRAPHY.SUCCESS: {
      return {
        ...state,
        biographyStatus: Status.SUCCESS,
      }
    }

    case DELETE_BIOGRAPHY.FAIL: {
      return {
        ...state,
        biographyStatus: Status.FAIL,
      }
    }

    default:
      return state
  }
}

export function fetchBiographies() {
  return {
    types: FETCH_BIOGRAPHIES,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/biography`),
  }
}

export function fetchBiography(id: string) {
  return {
    types: FETCH_BIOGRAPHY,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/biography/${id}`),
  }
}

export function fetchUserBiography(userId: string) {
  return {
    types: FETCH_USER_BIOGRAPHY,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/biography/user/${userId}`),
  }
}

export function createBiography(newBiography: NewBiography) {
  return {
    types: CREATE_BIOGRAPHY,
    promise: (client: ApiClient) =>
      client.post(`${Config.app.apiUrl}/admin/biography`, {
        body: JSON.stringify(newBiography),
      }),
  }
}

export function updateBiography(biography: Biography) {
  return {
    types: UPDATE_BIOGRAPHY,
    promise: (client: ApiClient) =>
      client.put(`${Config.app.apiUrl}/admin/biography`, {
        body: JSON.stringify(biography),
      }),
  }
}

export function uploadProfilePicture(biographyId: string, file: File) {
  const formData = new FormData()
  formData.append("file", file)

  return {
    types: UPLOAD_PROFILE_PICTURE,
    promise: (client: ApiClient) =>
      client.put(
        `${Config.app.apiUrl}/admin/biography/${biographyId}/upload/image`,
        {
          headers: {},
          body: formData,
        }
      ),
  }
}

export function deleteBiography(id: string) {
  return {
    types: DELETE_BIOGRAPHY,
    promise: (client: ApiClient) =>
      client.del(`${Config.app.apiUrl}/admin/biography/${id}`),
  }
}
