import { Button } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { compose } from "redux"

import ActionBar from "../../components/ActionBar"
import { PrimaryButton } from "../../components/Buttons"
import AuctionForm from "../../components/Forms/AuctionForm"
import Grid from "../../components/Grid"
import { HistoryLocation } from "../../components/HistoryLink"
import { PageWrapper } from "../../components/Layout"
import FormErrors from "../../core/errors/FormError"
import Auction, { NewAuction } from "../../core/models/Auction"
import privateRoute from "../../hoc/privateRoute"
import { AsyncResult } from "../../redux/middleware/asyncMiddleware"
import { fetchAuction, updateAuction } from "../../redux/modules/auction"
import { dispatchToast, ToastType } from "../../redux/modules/toasts"
import { StoreState } from "../../redux/reducer"

interface StoreProps {
  activeAuction: Auction
}

interface DispatchProps {
  fetchAuction: (id: string) => Promise<AsyncResult>
  updateAuction: (newAuctionData: Auction) => Promise<AsyncResult>
  dispatchToast: (message: string, type: ToastType, timeout?: number) => string
}

interface EditAuctionPageProps extends StoreProps, DispatchProps {}

const EditAuctionPage: React.FC<EditAuctionPageProps> = ({
  activeAuction,
  fetchAuction,
  updateAuction,
  dispatchToast,
}) => {
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()
  const { id } = useParams<{ id: string }>()

  const [errors, setErrors] = useState<FormErrors>({})
  const [auctionToUpdate, setAuctionToUpdate] = useState<Auction>(activeAuction)

  useEffect(() => {
    fetchAuction(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setAuctionToUpdate(activeAuction)
  }, [activeAuction])

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push(`/auction/${id}`)
  }

  const onSave = () => {
    if (auctionToUpdate) {
      updateAuction(auctionToUpdate)
        .then(() => {
          goBack()
        })
        .catch(errorResponse => {
          if (errorResponse?.body?.name === "ValidationError") {
            dispatchToast(
              "Errors found in your form. Please make sure you have filled all mandatory fields.",
              ToastType.Error,
              8000
            )
            setErrors(errorResponse?.body?.errors)
          } else {
            dispatchToast(
              "Whoops, something went wrong. Please try again.",
              ToastType.Error,
              8000
            )
          }
        })
    }
  }

  const onAuctionChange = (newAuctionData: NewAuction) =>
    setAuctionToUpdate(newAuctionData as Auction)

  if (!activeAuction) return null

  return (
    <PageWrapper>
      <ActionBar>
        <Button onClick={goBack}>Back</Button>
        <PrimaryButton onClick={onSave}>Save</PrimaryButton>
      </ActionBar>

      <Grid columns="1fr 300px" gap="32px">
        <AuctionForm
          auction={auctionToUpdate || {}}
          errors={errors}
          onChange={onAuctionChange}
        />
      </Grid>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      activeAuction: state.auction.activeAuction,
    }),
    {
      fetchAuction,
      updateAuction,
      dispatchToast,
    }
  )
)(EditAuctionPage)
