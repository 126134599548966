import { Button } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { compose } from "redux"

import ActionBar from "../../components/ActionBar"
import { PrimaryButton } from "../../components/Buttons"
import AuctionItemForm from "../../components/Forms/AuctionItemForm"
import Grid from "../../components/Grid"
import { HistoryLocation } from "../../components/HistoryLink"
import HoovusPaper, { PaperHeader } from "../../components/HoovusPaper"
import { PageWrapper } from "../../components/Layout"
import ProductGridItem from "../../components/ProductGridItem"
import Status from "../../core/enums/Status"
import FormErrors from "../../core/errors/FormError"
import AuctionItem, { NewAuctionItem } from "../../core/models/AuctionItem"
import privateRoute from "../../hoc/privateRoute"
import { AsyncResult } from "../../redux/middleware/asyncMiddleware"
import {
  updateAuctionItem,
  fetchAuctionItem,
} from "../../redux/modules/auction"
import { dispatchToast, ToastType } from "../../redux/modules/toasts"
import { StoreState } from "../../redux/reducer"

interface StoreProps {
  activeAuctionItem: AuctionItem
  activeAuctionItemStatus: Status
}

interface DispatchProps {
  fetchAuctionItem: (id: string) => Promise<AsyncResult>
  updateAuctionItem: (auctionItemToUpdate: AuctionItem) => Promise<AsyncResult>
  dispatchToast: (message: string, type: ToastType, timeout?: number) => string
}

interface EditAuctionItemPageProps extends StoreProps, DispatchProps {}

const EditAuctionItemPage: React.FC<EditAuctionItemPageProps> = ({
  activeAuctionItem,
  activeAuctionItemStatus,
  updateAuctionItem,
  fetchAuctionItem,
  dispatchToast,
}) => {
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()
  const { auctionId, id } = useParams<{ auctionId: string; id: string }>()

  const [errors, setErrors] = useState<FormErrors>({})
  const [auctionItemToUpdate, setAuctionItemToUpdate] = useState<AuctionItem>()

  useEffect(() => {
    fetchAuctionItem(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setAuctionItemToUpdate(activeAuctionItem)
  }, [activeAuctionItem])

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push(`/auction/${auctionId}`)
  }

  const onUpdate = () => {
    if (auctionItemToUpdate) {
      updateAuctionItem(auctionItemToUpdate)
        .then(() => {
          goBack()
        })
        .catch(errorResponse => {
          if (errorResponse?.body?.name === "ValidationError") {
            dispatchToast(
              "Errors found in your form. Please make sure you have filled all mandatory fields.",
              ToastType.Error,
              8000
            )
            setErrors(errorResponse?.body?.errors)
          } else {
            dispatchToast(
              "Whoops, something went wrong. Please try again.",
              ToastType.Error,
              8000
            )
          }
        })
    }
  }

  const onAuctionItemChange = (newAuctionItemData: NewAuctionItem) =>
    setAuctionItemToUpdate(newAuctionItemData as AuctionItem)

  const { product } = auctionItemToUpdate || {}

  return (
    <PageWrapper>
      <ActionBar>
        <Button onClick={goBack}>Back</Button>
        <PrimaryButton
          onClick={onUpdate}
          disabled={activeAuctionItemStatus === Status.LOADING}
        >
          Save
        </PrimaryButton>
      </ActionBar>

      <Grid columns="1fr 400px" gap="32px">
        <AuctionItemForm
          auctionItem={auctionItemToUpdate || {}}
          errors={errors}
          onChange={onAuctionItemChange}
        />
        <HoovusPaper>
          <PaperHeader>Artwork</PaperHeader>
          {product && (
            <ProductGridItem
              title={product.title}
              image={product.images[0]?.thumbnail?.url}
            />
          )}
        </HoovusPaper>
      </Grid>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      activeAuctionItem: state.auction.activeAuctionItem,
      activeAuctionItemStatus: state.auction.activeAuctionItemStatus,
    }),
    {
      updateAuctionItem,
      dispatchToast,
      fetchAuctionItem,
    }
  )
)(EditAuctionItemPage)
