import ApiClient from "../../core/ApiClient"
import Config from "../../core/Config"
import Status from "../../core/enums/Status"
import Auction, { NewAuction } from "../../core/models/Auction"
import AuctionItem, { NewAuctionItem } from "../../core/models/AuctionItem"
import { AsyncAction, asyncType } from "../middleware/asyncMiddleware"

export interface AuctionStore {
  auctions: Array<Auction>
  auctionsStatus: Status

  auctionItems: Array<AuctionItem>
  auctionItemsStatus: Status

  activeAuction?: Auction
  activeAuctionStatus: Status

  activeAuctionItem?: AuctionItem
  activeAuctionItemStatus: Status
}

const initialState = {
  auctions: [] as Array<Auction>,
  auctionsStatus: Status.INITIAL,

  auctionItems: [] as Array<AuctionItem>,
  auctionItemsStatus: Status.INITIAL,

  activeAuction: undefined,
  activeAuctionStatus: Status.INITIAL,

  activeAuctionItem: undefined,
  activeAuctionItemStatus: Status.INITIAL,
}

const FETCH_AUCTIONS = asyncType("redux.auction.FETCH_AUCTIONS")
const FETCH_AUCTION = asyncType("redux.auction.FETCH_AUCTION")
const CREATE_AUCTION = asyncType("redux.auction.CREATE_AUCTION")
const UPDATE_AUCTION = asyncType("redux.auction.UPDATE_AUCTION")
const DELETE_AUCTION = asyncType("redux.auction.DELETE_AUCTION")

const FETCH_AUCTION_ITEMS = asyncType("redux.auction.FETCH_AUCTION_ITEMS")
const FETCH_AUCTION_ITEM = asyncType("redux.auction.FETCH_AUCTION_ITEM")
const CREATE_AUCTION_ITEM = asyncType("redux.auction.CREATE_AUCTION_ITEM")
const UPDATE_AUCTION_ITEM = asyncType("redux.auction.UPDATE_AUCTION_ITEM")
const DELETE_AUCTION_ITEM = asyncType("redux.auction.DELETE_AUCTION_ITEM")

export default function reducer(
  state = initialState,
  action: AsyncAction
): AuctionStore {
  switch (action.type) {
    case FETCH_AUCTIONS.INITIAL: {
      return {
        ...state,
        auctionsStatus: Status.LOADING,
      }
    }

    case FETCH_AUCTIONS.SUCCESS: {
      return {
        ...state,
        auctions: action.result?.body as Array<Auction>,
        auctionsStatus: Status.SUCCESS,
      }
    }

    case FETCH_AUCTIONS.FAIL: {
      return {
        ...state,
        auctionsStatus: Status.FAIL,
      }
    }

    case FETCH_AUCTION.INITIAL: {
      return {
        ...state,
        activeAuctionStatus: Status.LOADING,
      }
    }

    case FETCH_AUCTION.SUCCESS: {
      return {
        ...state,
        activeAuction: action.result?.body as Auction,
        activeAuctionStatus: Status.SUCCESS,
      }
    }

    case FETCH_AUCTION.FAIL: {
      return {
        ...state,
        activeAuctionStatus: Status.FAIL,
      }
    }

    case UPDATE_AUCTION.INITIAL:
    case CREATE_AUCTION.INITIAL: {
      return {
        ...state,
        activeAuctionStatus: Status.LOADING,
      }
    }

    case UPDATE_AUCTION.SUCCESS:
    case CREATE_AUCTION.SUCCESS: {
      return {
        ...state,
        activeAuction: action.result?.body as Auction,
        activeAuctionStatus: Status.SUCCESS,
      }
    }

    case UPDATE_AUCTION.FAIL:
    case CREATE_AUCTION.FAIL: {
      return {
        ...state,
        activeAuctionStatus: Status.FAIL,
      }
    }

    case DELETE_AUCTION.INITIAL: {
      return {
        ...state,
        activeAuctionStatus: Status.LOADING,
      }
    }

    case DELETE_AUCTION.SUCCESS: {
      return {
        ...state,
        activeAuction: undefined,
        activeAuctionStatus: Status.SUCCESS,
      }
    }

    case DELETE_AUCTION.FAIL: {
      return {
        ...state,
        activeAuctionStatus: Status.FAIL,
      }
    }

    case FETCH_AUCTION_ITEMS.INITIAL: {
      return {
        ...state,
        auctionItemsStatus: Status.LOADING,
      }
    }

    case FETCH_AUCTION_ITEMS.SUCCESS: {
      return {
        ...state,
        auctionItems: action.result?.body as Array<AuctionItem>,
        auctionItemsStatus: Status.SUCCESS,
      }
    }

    case FETCH_AUCTION_ITEMS.FAIL: {
      return {
        ...state,
        auctionItemsStatus: Status.FAIL,
      }
    }

    case FETCH_AUCTION_ITEM.INITIAL: {
      return {
        ...state,
        activeAuctionItemStatus: Status.LOADING,
      }
    }

    case FETCH_AUCTION_ITEM.SUCCESS: {
      return {
        ...state,
        activeAuctionItem: action.result?.body as AuctionItem,
        activeAuctionItemStatus: Status.SUCCESS,
      }
    }

    case FETCH_AUCTION_ITEM.FAIL: {
      return {
        ...state,
        activeAuctionItemStatus: Status.FAIL,
      }
    }

    case UPDATE_AUCTION_ITEM.INITIAL:
    case CREATE_AUCTION_ITEM.INITIAL: {
      return {
        ...state,
        activeAuctionItemStatus: Status.LOADING,
      }
    }

    case UPDATE_AUCTION_ITEM.SUCCESS:
    case CREATE_AUCTION_ITEM.SUCCESS: {
      return {
        ...state,
        activeAuctionItem: action.result?.body as AuctionItem,
        activeAuctionItemStatus: Status.SUCCESS,
      }
    }

    case UPDATE_AUCTION_ITEM.FAIL:
    case CREATE_AUCTION_ITEM.FAIL: {
      return {
        ...state,
        activeAuctionItemStatus: Status.FAIL,
      }
    }

    case DELETE_AUCTION_ITEM.INITIAL: {
      return {
        ...state,
        activeAuctionItemStatus: Status.LOADING,
      }
    }

    case DELETE_AUCTION_ITEM.SUCCESS: {
      return {
        ...state,
        activeAuctionItem: undefined,
        activeAuctionItemStatus: Status.SUCCESS,
      }
    }

    case DELETE_AUCTION_ITEM.FAIL: {
      return {
        ...state,
        activeAuctionItemStatus: Status.FAIL,
      }
    }

    default:
      return state
  }
}

export function fetchAuctions() {
  return {
    types: FETCH_AUCTIONS,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/auction`),
  }
}

export function fetchAuction(id: string) {
  return {
    types: FETCH_AUCTION,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/auction/${id}`),
  }
}

export function createAuction(newAuction: NewAuction) {
  return {
    types: CREATE_AUCTION,
    promise: (client: ApiClient) =>
      client.post(`${Config.app.apiUrl}/admin/auction`, {
        body: JSON.stringify(newAuction),
      }),
  }
}

export function updateAuction(auctionToUpdate: Auction) {
  return {
    types: UPDATE_AUCTION,
    promise: (client: ApiClient) =>
      client.put(`${Config.app.apiUrl}/admin/auction`, {
        body: JSON.stringify(auctionToUpdate),
      }),
  }
}

export function deleteAuction(id: string) {
  return {
    types: DELETE_AUCTION,
    promise: (client: ApiClient) =>
      client.del(`${Config.app.apiUrl}/admin/auction/${id}`),
  }
}

export function fetchAuctionItems(auctionId: string) {
  return {
    types: FETCH_AUCTION_ITEMS,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/auction/${auctionId}/items`),
  }
}

export function fetchAuctionItem(id: string) {
  return {
    types: FETCH_AUCTION_ITEM,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/auction/item/${id}`),
  }
}

export function createAuctionItem(newAuctionItem: NewAuctionItem) {
  return {
    types: CREATE_AUCTION_ITEM,
    promise: (client: ApiClient) =>
      client.post(`${Config.app.apiUrl}/admin/auction/item`, {
        body: JSON.stringify(newAuctionItem),
      }),
  }
}

export function updateAuctionItem(auctionItemToUpdate: AuctionItem) {
  return {
    types: UPDATE_AUCTION_ITEM,
    promise: (client: ApiClient) =>
      client.put(`${Config.app.apiUrl}/admin/auction/item`, {
        body: JSON.stringify(auctionItemToUpdate),
      }),
  }
}

export function deleteAuctionItem(id: string) {
  return {
    types: DELETE_AUCTION_ITEM,
    promise: (client: ApiClient) =>
      client.del(`${Config.app.apiUrl}/admin/auction/item/${id}`),
  }
}
