import CloseIcon from "@material-ui/icons/Close"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { animated, useSpring } from "react-spring"
import { Action, compose } from "redux"
import styled from "styled-components"

import { removeToast, Toast, ToastType } from "../redux/modules/toasts"
import { StoreState } from "../redux/reducer"
import { CubicBezier } from "../utils/AnimationUtils"

const ToastsWrapper = styled.div`
  position: fixed;
  bottom: 12px;
  right: 12px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 1400;
`

const AlertTypeIcon = styled(ErrorOutlineIcon)`
  margin-right: 20px;
  color: white;
`

const CloseAlertBtn = styled(CloseIcon)`
  margin-left: 40px;
  color: white;
  cursor: pointer;
`

const AlertMessage = styled.div`
  flex: 1;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.01071em;
`

const StyledToast = styled(animated.div)<{ type: ToastType }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 36px 16px 16px;
  border-radius: 4px;
  transform-origin: bottom right;
  margin-bottom: 8px;

  background-color: ${({ type }) => {
    switch (type) {
      case ToastType.Success:
        return "#4caf50"
      case ToastType.Error:
        return "#f44336"
      default:
        return "#f44336"
    }
  }};
`

interface ToastItemProps {
  show: boolean
  message: string
  type: ToastType
  onClose: () => void
  onExited: () => void
}

const TOAST_ANIM_DURATION = 350
const TOAST_HIDE_ANIM_DURATION = 200

const TOAST_ANIM_CONF = {
  duration: TOAST_ANIM_DURATION,
  easing: CubicBezier,
}

const TOAST_HIDE_ANIM_CONF = {
  duration: TOAST_HIDE_ANIM_DURATION,
  easing: CubicBezier,
}

const ToastItem: React.FC<ToastItemProps> = ({
  show,
  message,
  type,
  onClose,
  onExited,
}) => {
  const toastRef = useRef<HTMLDivElement>(null)

  const [style, setStyle] = useSpring(() => ({
    opacity: 0,
    marginBottom: 8,
    transform: "scale(0.5)",
    config: TOAST_ANIM_CONF,
  }))

  useEffect(() => {
    setStyle({
      opacity: show ? 1 : 0,
      transform: `scale(${show ? 1 : 0.5})`,
    })

    if (!show) {
      setTimeout(() => {
        setStyle({
          marginBottom: show ? 8 : (toastRef.current?.offsetHeight || 0) * -1,
          config: TOAST_HIDE_ANIM_CONF,
        })
        setTimeout(() => {
          onExited()
        }, TOAST_HIDE_ANIM_DURATION)
      }, TOAST_ANIM_DURATION)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <StyledToast ref={toastRef} style={style} type={type}>
      <AlertTypeIcon />
      <AlertMessage>{message}</AlertMessage>
      <CloseAlertBtn onClick={onClose} />
    </StyledToast>
  )
}

interface StoreProps {
  toasts: Array<Toast>
}

interface DispatchProps {
  removeToast: (id: string) => Promise<Action>
}

interface ToastProps extends StoreProps, DispatchProps {}

const Toasts: React.FC<ToastProps> = ({ toasts, removeToast }) => {
  const [activeToasts, setActiveToasts] = useState<Array<Toast>>([])

  useEffect(() => {
    const newToasts = toasts.filter(
      toast => !activeToasts.some(activeToast => activeToast.id === toast.id)
    )

    if (newToasts.length > 0) {
      setActiveToasts(activeToasts.concat(newToasts))
    }
  }, [toasts, activeToasts])

  const onRemoveToast = (id: string) => () => {
    removeToast(id)
  }

  const onToastExited = (id: string) => () => {
    setActiveToasts(currentlyActiveToasts =>
      currentlyActiveToasts.filter(toast => toast.id !== id)
    )
  }

  return (
    <ToastsWrapper>
      {activeToasts.map(({ id, message, type }) => (
        <ToastItem
          key={id}
          show={toasts.some(toast => toast.id === id)}
          message={message}
          type={type}
          onClose={onRemoveToast(id)}
          onExited={onToastExited(id)}
        />
      ))}
    </ToastsWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  connect(
    (state: StoreState) => ({
      toasts: state.toast.toasts,
    }),
    {
      removeToast,
    }
  )
)(Toasts)
