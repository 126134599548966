import { ListItem, ListItemText } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import styled from "styled-components"

import { ProductSearch } from "../core/models/Search"
import privateRoute from "../hoc/privateRoute"
import useThrottle from "../hooks/useThrottle"
import { AsyncResult } from "../redux/middleware/asyncMiddleware"
import { clearProductSearch, searchProduct } from "../redux/modules/search"
import { StoreState } from "../redux/reducer"
import StyledInput from "./Input/StyledInput"

const SearchResult = styled(ListItem)<any>`
  &.MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiListItemText-primary {
    font-size: 14px;
  }

  .MuiListItemText-secondary {
    font-size: 12px;
  }
`

const SearchResults = styled.div`
  max-height: 500px;
  overflow-y: auto;
`

export enum SearchType {
  User,
  Biography,
}

interface StoreProps {
  productSearch: Array<ProductSearch>
}

interface DispatchProps {
  searchProduct: (search: string) => Promise<AsyncResult>
  clearProductSearch: () => void
}

interface OwnProps {
  onSelect: (product: ProductSearch) => void
}

interface ProductSearchInputProps extends OwnProps, StoreProps, DispatchProps {}

const ProductSearchInput: React.FC<ProductSearchInputProps> = ({
  productSearch,
  onSelect,
  searchProduct,
  clearProductSearch,
}) => {
  const [search, setSearch] = useState("")

  const onSearchThrottledCB = useThrottle(
    (searchString: string) => searchProduct(searchString),
    1000
  )

  const onSearchChange = (e: any) => {
    const { value: searchString } = e.target
    setSearch(searchString)
    onSearchThrottledCB(searchString)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearProductSearch, [])

  const onSelectProduct = (product: ProductSearch) => () => {
    clearProductSearch()
    setSearch("")
    onSelect(product)
  }

  return (
    <div>
      <StyledInput
        value={search}
        onChange={onSearchChange}
        placeholder="Search product"
      />

      <SearchResults>
        {productSearch.map(product => (
          <SearchResult
            key={product.id}
            button
            onClick={onSelectProduct(product)}
          >
            <ListItemText
              primary={product.title}
              secondary={`${product.author.realName} (${product.author.pseudonym})`}
            />
          </SearchResult>
        ))}
      </SearchResults>
    </div>
  )
}

export default compose<React.FC<OwnProps> & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      productSearch: state.search.productSearch,
    }),
    {
      searchProduct,
      clearProductSearch,
    }
  )
)(ProductSearchInput)
