import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link as RouterLink } from "react-router-dom"
import { compose } from "redux"

import ActionBar from "../../components/ActionBar"
import HoovusPaper from "../../components/HoovusPaper"
import { PageWrapper } from "../../components/Layout"
import Auction from "../../core/models/Auction"
import privateRoute from "../../hoc/privateRoute"
import { AsyncAction } from "../../redux/middleware/asyncMiddleware"
import { fetchAuctions } from "../../redux/modules/auction"
import { StoreState } from "../../redux/reducer"
import { formatDateString } from "../../utils/DateUtils"

interface StoreProps {
  auctions: Array<Auction>
}

interface DispatchProps {
  fetchAuctions: () => Promise<AsyncAction>
}

interface AuctionsPageProps extends StoreProps, DispatchProps {}

const AuctionsPage: React.FC<AuctionsPageProps> = ({
  auctions,
  fetchAuctions,
}) => {
  useEffect(() => {
    fetchAuctions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWrapper>
      <ActionBar>
        <Button component={RouterLink} to="/auction/new">
          ADD NEW
        </Button>
      </ActionBar>

      <HoovusPaper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Slug</TableCell>
              <TableCell width="115" align="right">
                Updated
              </TableCell>
              <TableCell width="115" align="right">
                Created
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auctions.map(auction => (
              <TableRow key={auction.id}>
                <TableCell>{auction.title}</TableCell>
                <TableCell>{auction.slug}</TableCell>
                <TableCell>
                  {formatDateString(auction.updatedAt, "dd.MM.Y HH:mm")}
                </TableCell>
                <TableCell>
                  {formatDateString(auction.createdAt, "dd.MM.Y HH:mm")}
                </TableCell>
                <TableCell align="right">
                  <Button component={RouterLink} to={`/auction/${auction.id}`}>
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </HoovusPaper>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      auctions: state.auction.auctions,
    }),
    {
      fetchAuctions,
    }
  )
)(AuctionsPage)
