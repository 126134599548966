export function priceToString(price: Number) {
  return price
}

export function dimensionToString(value: any, unit: string) {
  return value !== undefined && value !== null ? `${value} ${unit}` : "-"
}

export function generateItemsAnd(items: Array<string>) {
  const firstItems = [...items]
  const lastItem = firstItems.pop()

  return `${firstItems?.join(", ")}${
    firstItems.length > 0 ? ` and ` : ""
  }${lastItem}`
}
