import {
  Checkbox as MaterialCheckbox,
  FormControlLabel,
} from "@material-ui/core"
import React from "react"
import styled from "styled-components"

const CheckboxWrapper = styled(FormControlLabel)`
  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }

  .MuiTypography-root {
    padding-top: 2px;
    display: block;
    font-family: "Montserrat";
    font-size: 16px;
  }
`

interface CheckboxProps {
  label: string
  checked?: boolean
  onChange: (newValue: boolean) => void
  className?: string
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked = false,
  onChange,
  className,
}) => {
  return (
    <CheckboxWrapper
      className={className}
      control={
        <MaterialCheckbox
          checked={checked}
          onChange={(e: any, checked: boolean) => onChange(checked)}
        />
      }
      label={label}
    />
  )
}

export default Checkbox
