import React, { useEffect } from "react"
import { connect } from "react-redux"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { compose } from "redux"

import Header from "./components/Header"
import Toasts from "./components/Toasts"
import User from "./core/models/User"
import AuctionPage from "./pages/auction/AuctionPage"
import AuctionsPage from "./pages/auction/AuctionsPage"
import EditAuctionPage from "./pages/auction/EditAuctionPage"
import NewAuctionItemPage from "./pages/auction/NewAuctionItemPage"
import NewAuctionPage from "./pages/auction/NewAuctionPage"
import BiographiesPage from "./pages/biography/BiographiesPage"
import BiographyPage from "./pages/biography/BiographyPage"
import EditBiographyPage from "./pages/biography/EditBiographyPage"
import NewBiographyPage from "./pages/biography/NewBiographyPage"
import Dashboard from "./pages/Dashboard"
import LoginPage from "./pages/LoginPage"
import OrderPage from "./pages/OrderPage"
import OrdersPage from "./pages/OrdersPage"
import EditProductPage from "./pages/product/EditProductPage"
import NewProductPage from "./pages/product/NewProductPage"
import ProductPage from "./pages/product/ProductPage"
import ProductsPage from "./pages/product/ProductsPage"
import UserPage from "./pages/UserPage"
import UsersPage from "./pages/UsersPage"
import { AsyncAction } from "./redux/middleware/asyncMiddleware"
import { fetchProfile } from "./redux/modules/auth"
import { StoreState } from "./redux/reducer"

import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import AuctionItemPage from "./pages/auction/AuctionItemPage"
import EditAuctionItemPage from "./pages/auction/EditAuctionItemPage"
import CampaignsPage from "./pages/campaign/CampaignsPage"
import CampaignPage from "./pages/campaign/CampaignPage"

interface StoreProps {
  user: User | null
}

interface DispatchProps {
  fetchProfile: () => Promise<AsyncAction>
}

interface AppProps extends StoreProps, DispatchProps {}

const App: React.FC<AppProps> = ({ fetchProfile }) => {
  useEffect(() => {
    fetchProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="App">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <Header></Header>
          <div>
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/users" component={UsersPage} />
              <Route exact path="/user/:id" component={UserPage} />
              <Route exact path="/biographies" component={BiographiesPage} />
              <Route exact path="/biography/new" component={NewBiographyPage} />
              <Route exact path="/biography/:id" component={BiographyPage} />
              <Route
                exact
                path="/biography/:id/edit"
                component={EditBiographyPage}
              />
              <Route exact path="/products" component={ProductsPage} />
              <Route exact path="/product/new" component={NewProductPage} />
              <Route exact path="/product/:id" component={ProductPage} />
              <Route
                exact
                path="/product/:id/edit"
                component={EditProductPage}
              />
              <Route exact path="/orders" component={OrdersPage} />
              <Route exact path="/order/:id" component={OrderPage} />
              <Route exact path="/auctions" component={AuctionsPage} />
              <Route exact path="/auction/new" component={NewAuctionPage} />
              <Route exact path="/auction/:id" component={AuctionPage} />
              <Route
                exact
                path="/auction/:id/edit"
                component={EditAuctionPage}
              />
              <Route
                exact
                path="/auction/:auctionId/item/new"
                component={NewAuctionItemPage}
              />
              <Route
                exact
                path="/auction/:auctionId/item/:id"
                component={AuctionItemPage}
              />
              <Route
                exact
                path="/auction/:auctionId/item/:id/edit"
                component={EditAuctionItemPage}
              />
              <Route exact path="/campaigns" component={CampaignsPage} />
              <Route exact path="/campaign/:id" component={CampaignPage} />
            </Switch>
          </div>

          <Toasts />
        </Router>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default compose<React.FC & AppProps>(
  connect(
    (state: StoreState) => ({
      user: state.auth.user,
    }),
    {
      fetchProfile,
    }
  )
)(App)
