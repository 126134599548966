import { Button, Dialog } from "@material-ui/core"
import React from "react"
import styled from "styled-components"

import Location from "../../core/models/Location"
import Product, {
  Dimensions,
  LimitedEditionConf,
} from "../../core/models/Product"
import Translations from "../../core/Translations"
import { dimensionToString } from "../../utils/StringUtils"
import Grid from "../Grid"
import HistoryLink from "../HistoryLink"
import HoovusPaper, { PaperHeader } from "../HoovusPaper"
import PropertyItem from "../PropertyItem"

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 1400px;
    width: 100%;
    background: rgb(247, 249, 252);
    padding: 20px;
  }
`

const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
`

const ProductImage = styled.div`
  img {
    width: 100%;
    margin: 0;
  }
`

interface ProductModalProps {
  product: Product
  onClose: () => void
}

const ProductModal: React.FC<ProductModalProps> = ({ product, onClose }) => {
  const {
    title,
    description,
    year,
    nsfw,
    price,
    style,
    status,
    medium,
    surface,
    images,
    dimensions = {} as Dimensions,
    hasFrame,
    tags,
    isLimitedEdition,
    limitedEditionConf = {} as LimitedEditionConf,
    signatures = [],
    location = {} as Location,
  } = product

  const { width, height, thickness, weight } = dimensions
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    country,
    phone,
    instructions,
  } = location

  const {
    copies,
    copiesForSale,
    copiesOutOfTrade,
    artistProofs,
  } = limitedEditionConf

  return (
    <StyledDialog open onClose={onClose}>
      <div>
        <Button onClick={onClose}>Close</Button>
      </div>

      <Grid columns="2fr 1fr" gap="24px">
        <HoovusPaper>
          <PaperHeader>General</PaperHeader>
          <Grid columns="1fr 1fr">
            <PropertyItem label="Title">{title}</PropertyItem>
            <PropertyItem label="Description">{description}</PropertyItem>
            <PropertyItem label="Style">
              {style && Translations.Enum.ArtStyle[style]}
            </PropertyItem>
            <PropertyItem label="Keywords">{tags.join(", ")}</PropertyItem>
            <PropertyItem label="Year">{year}</PropertyItem>
            <PropertyItem label="Has frame">
              {hasFrame ? "Yes" : "No"}
            </PropertyItem>
          </Grid>
        </HoovusPaper>

        <HoovusPaper>
          <PaperHeader>Artist</PaperHeader>
          {product.userId ? (
            <Button component={HistoryLink} to={`/user/${product.userId}`}>
              GO TO USER PROFILE
            </Button>
          ) : (
            <div>No artist found...</div>
          )}
        </HoovusPaper>
      </Grid>

      <HoovusPaper>
        <PaperHeader>Images</PaperHeader>
        <ProductsWrapper>
          {images.map(image => (
            <ProductImage key={image.id}>
              <img src={image.thumbnail?.url} alt="" />
            </ProductImage>
          ))}
        </ProductsWrapper>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Visuals</PaperHeader>
        <PropertyItem label="Not suitable for children and general public">
          {nsfw ? "Not safe for work" : "Safe for work"}
        </PropertyItem>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Classification</PaperHeader>
        <Grid columns="1fr 1fr">
          <PropertyItem label="Medium">
            {medium && Translations.Enum.ArtMedium[medium]}
          </PropertyItem>
          <PropertyItem label="Support or surface">
            {surface ? Translations.Enum.Surface[surface] : undefined}
          </PropertyItem>
          <PropertyItem label="Artwork type">
            {isLimitedEdition ? "Limited edition" : "One of a kind"}
          </PropertyItem>
          {isLimitedEdition && (
            <>
              <PropertyItem label="Copies">{copies}</PropertyItem>
              <PropertyItem label="Copies for sale">
                {copiesForSale}
              </PropertyItem>
              <PropertyItem label="Copies out of trade">
                {copiesOutOfTrade}
              </PropertyItem>
              <PropertyItem label="Artist proofs">{artistProofs}</PropertyItem>
            </>
          )}
          <PropertyItem label="Signature">{signatures.join(", ")}</PropertyItem>
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Dimensions</PaperHeader>
        <Grid columns="1fr 1fr">
          <PropertyItem label="Width">
            {dimensionToString(width, "cm")}
          </PropertyItem>
          <PropertyItem label="Height">
            {dimensionToString(height, "cm")}
          </PropertyItem>
          <PropertyItem label="Thickness">
            {dimensionToString(thickness, "cm")}
          </PropertyItem>
          <PropertyItem label="Weight">
            {dimensionToString(weight, "kg")}
          </PropertyItem>
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Sales</PaperHeader>
        <Grid columns="1fr 1fr">
          <PropertyItem label="Status">
            {Translations.Enum.ProductStatus[status]}
          </PropertyItem>
          <PropertyItem label="Price">€{price}</PropertyItem>
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Location</PaperHeader>
        <Grid columns="1fr 1fr">
          <PropertyItem label="Address line 1">{addressLine1}</PropertyItem>
          <PropertyItem label="Address line 2">{addressLine2}</PropertyItem>
          <PropertyItem label="City">{city}</PropertyItem>
          <PropertyItem label="State">{state}</PropertyItem>
          <PropertyItem label="Postal code">{postalCode}</PropertyItem>
          <PropertyItem label="Country">{country}</PropertyItem>
          <PropertyItem label="Phone">{phone}</PropertyItem>
          <PropertyItem label="Instructions">{instructions}</PropertyItem>
        </Grid>
      </HoovusPaper>
    </StyledDialog>
  )
}

export default ProductModal
