import { Button } from "@material-ui/core"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { compose } from "redux"

import ActionBar from "../../components/ActionBar"
import Grid from "../../components/Grid"
import { HistoryLocation } from "../../components/HistoryLink"
import HoovusPaper, { PaperHeader } from "../../components/HoovusPaper"
import { PageWrapper } from "../../components/Layout"
import PropertyItem from "../../components/PropertyItem"
import privateRoute from "../../hoc/privateRoute"
import { AsyncAction } from "../../redux/middleware/asyncMiddleware"
import { fetchCampaign } from "../../redux/modules/campaign"
import { StoreState } from "../../redux/reducer"
import { formatDateString } from "../../utils/DateUtils"
import RentalInquiry from "../../core/models/RentalInquiry"

interface StoreProps {
  activeCampaign: RentalInquiry
}

interface DispatchProps {
  fetchCampaign: (campaignId: string) => Promise<AsyncAction>
}

interface CampaignPageProps extends StoreProps, DispatchProps {}

const CampaignPage: React.FC<CampaignPageProps> = ({
  activeCampaign,
  fetchCampaign,
}) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()

  useEffect(() => {
    fetchCampaign(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push("/campaigns")
  }

  if (!activeCampaign) return null

  const {
    firstName,
    lastName,
    organisation,
    phone,
    email,
    artworkQuantity,
    hasFastenings,
    additionalInformation,
    updatedAt,
    createdAt,
  } = activeCampaign

  return (
    <PageWrapper>
      <ActionBar>
        <Button onClick={goBack}>Back</Button>
      </ActionBar>

      <Grid columns="2fr 1fr" gap="24px">
        <HoovusPaper>
          <PaperHeader>General</PaperHeader>
          <Grid columns="1fr 1fr">
            <PropertyItem label="Name">
              {firstName} {lastName}
            </PropertyItem>
            <PropertyItem label="Organisation">{organisation}</PropertyItem>
            <PropertyItem label="Phone">{phone}</PropertyItem>
            <PropertyItem label="Email">{email}</PropertyItem>
            <PropertyItem label="Artworks requested">
              {artworkQuantity}
            </PropertyItem>
            <PropertyItem label="Have their own fastening">
              {hasFastenings ? "Yes" : "No"}
            </PropertyItem>
            <PropertyItem label="Additional information">
              {additionalInformation}
            </PropertyItem>

            <PropertyItem label="Updated at">
              {formatDateString(updatedAt, "dd.MM.Y HH:mm")}
            </PropertyItem>
            <PropertyItem label="Created at">
              {formatDateString(createdAt, "dd.MM.Y HH:mm")}
            </PropertyItem>
          </Grid>
        </HoovusPaper>
      </Grid>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      activeCampaign: state.campaign.activeCampaign,
    }),
    {
      fetchCampaign,
    }
  )
)(CampaignPage)
