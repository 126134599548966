import { Button } from "@material-ui/core"
import React, { useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { compose } from "redux"

import ActionBar from "../../components/ActionBar"
import { PrimaryButton } from "../../components/Buttons"
import AuctionItemForm from "../../components/Forms/AuctionItemForm"
import Grid from "../../components/Grid"
import { HistoryLocation } from "../../components/HistoryLink"
import HoovusPaper, { PaperHeader } from "../../components/HoovusPaper"
import { PageWrapper } from "../../components/Layout"
import ProductGridItem from "../../components/ProductGridItem"
import ProductSearchInput from "../../components/ProductSearchInput"
import Status from "../../core/enums/Status"
import FormErrors from "../../core/errors/FormError"
import { NewAuctionItem } from "../../core/models/AuctionItem"
import { ProductSearch } from "../../core/models/Search"
import privateRoute from "../../hoc/privateRoute"
import { AsyncResult } from "../../redux/middleware/asyncMiddleware"
import { createAuctionItem } from "../../redux/modules/auction"
import { dispatchToast, ToastType } from "../../redux/modules/toasts"
import { StoreState } from "../../redux/reducer"

interface StoreProps {
  activeAuctionItemStatus: Status
}

interface DispatchProps {
  createAuctionItem: (newAuctionItem: NewAuctionItem) => Promise<AsyncResult>
  dispatchToast: (message: string, type: ToastType, timeout?: number) => string
}

interface NewAuctionItemPageProps extends StoreProps, DispatchProps {}

const NewAuctionItemPage: React.FC<NewAuctionItemPageProps> = ({
  activeAuctionItemStatus,
  createAuctionItem,
  dispatchToast,
}) => {
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()
  const { auctionId } = useParams<{ auctionId: string }>()

  const [errors, setErrors] = useState<FormErrors>({})
  const [newAuctionItem, setNewAuctionItem] = useState<NewAuctionItem>({
    auctionId,
  })

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push(`/auction/${auctionId}`)
  }

  const onCreate = () => {
    if (newAuctionItem) {
      createAuctionItem(newAuctionItem)
        .then(() => {
          goBack()
        })
        .catch(errorResponse => {
          if (errorResponse?.body?.name === "ValidationError") {
            dispatchToast(
              "Errors found in your form. Please make sure you have filled all mandatory fields.",
              ToastType.Error,
              8000
            )
            setErrors(errorResponse?.body?.errors)
          } else {
            dispatchToast(
              "Whoops, something went wrong. Please try again.",
              ToastType.Error,
              8000
            )
          }
        })
    }
  }

  const onAuctionItemChange = (newAuctionItemData: NewAuctionItem) =>
    setNewAuctionItem(newAuctionItemData)

  const onSelectProduct = (productSearch: ProductSearch) =>
    setNewAuctionItem({
      ...newAuctionItem,
      productId: productSearch.id,
      product: productSearch,
    })

  return (
    <PageWrapper>
      <ActionBar>
        <Button onClick={goBack}>Back</Button>
        <PrimaryButton
          onClick={onCreate}
          disabled={activeAuctionItemStatus === Status.LOADING}
        >
          Create
        </PrimaryButton>
      </ActionBar>

      <Grid columns="1fr 400px" gap="32px">
        <AuctionItemForm
          auctionItem={newAuctionItem || {}}
          errors={errors}
          onChange={onAuctionItemChange}
        />
        <HoovusPaper>
          <PaperHeader>Artwork</PaperHeader>
          <ProductSearchInput onSelect={onSelectProduct} />
          {newAuctionItem.product && (
            <ProductGridItem
              title={newAuctionItem.product.title}
              image={newAuctionItem.product.images[0]?.thumbnail?.url}
            />
          )}
        </HoovusPaper>
      </Grid>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      activeAuctionStatus: state.auction.activeAuctionStatus,
    }),
    {
      createAuctionItem,
      dispatchToast,
    }
  )
)(NewAuctionItemPage)
