import React from "react"
import ImageUpload, {
  ImageUploadPreview,
  ImageUploadBtn,
  ImageUploadContent,
  ImageUploadInfo,
  UploadedImage,
  ImageUploadBtnWrapper,
} from "./ImageUpload"

import AddIcon from "@material-ui/icons/Add"

interface BiographyImageUploadProps {
  uploadedImage?: UploadedImage
  onAddImages: (uploadedImages: Array<UploadedImage>) => void
}

const BiographyImageUpload: React.FC<BiographyImageUploadProps> = ({
  uploadedImage,
  onAddImages,
}) => {
  return (
    <ImageUpload onChange={onAddImages}>
      {uploadedImage ? (
        <ImageUploadPreview>
          <img src={uploadedImage?.preview} alt="" />
        </ImageUploadPreview>
      ) : (
        <ImageUploadBtnWrapper>
          <ImageUploadBtn>
            <ImageUploadContent>
              <AddIcon />
              <span>Upload image</span>
              <ImageUploadInfo>
                Images should be at least 800px wide or high and smaller than
                10MB.
              </ImageUploadInfo>
            </ImageUploadContent>
          </ImageUploadBtn>
        </ImageUploadBtnWrapper>
      )}
    </ImageUpload>
  )
}
export default BiographyImageUpload
