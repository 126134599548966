enum Signature {
  Front = "Front",
  Back = "Back",
  Certificate = "Certificate",
  Numbered = "Numbered",
}

export const SignatureTranslations = {
  [Signature.Front]: "Signed by artist on the front",
  [Signature.Back]: "Signed by artist on the back",
  [Signature.Certificate]: "Signed certificate of authenticity",
  [Signature.Numbered]: "Numbered by hand",
}

export default Signature
