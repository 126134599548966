import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link as RouterLink } from "react-router-dom"
import { compose } from "redux"

import HoovusPaper from "../components/HoovusPaper"
import { PageWrapper } from "../components/Layout"
import User from "../core/models/User"
import privateRoute from "../hoc/privateRoute"
import { AsyncAction } from "../redux/middleware/asyncMiddleware"
import { fetchUsers } from "../redux/modules/users"
import { StoreState } from "../redux/reducer"
import { formatDateString } from "../utils/DateUtils"

interface StoreProps {
  users: Array<User>
}

interface DispatchProps {
  fetchUsers: () => Promise<AsyncAction>
}

interface UsersPageProps extends StoreProps, DispatchProps {}

const UsersPage: React.FC<UsersPageProps> = ({ users, fetchUsers }) => {
  useEffect(() => {
    fetchUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWrapper>
      <HoovusPaper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Veriff</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell width="115" align="right">
                Updated
              </TableCell>
              <TableCell width="115" align="right">
                Created
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user.id}>
                <TableCell>
                  {user.firstName} {user.lastName}
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.veriffStatus}</TableCell>
                <TableCell>
                  {user.roles.map(role => (
                    <Chip key={role} size="small" label={role} />
                  ))}
                </TableCell>
                <TableCell>
                  {formatDateString(user.updatedAt, "dd.MM.Y HH:mm")}
                </TableCell>
                <TableCell>
                  {formatDateString(user.createdAt, "dd.MM.Y HH:mm")}
                </TableCell>
                <TableCell align="right">
                  <Button component={RouterLink} to={`/user/${user.id}`}>
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </HoovusPaper>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      users: state.users.users,
    }),
    {
      fetchUsers,
    }
  )
)(UsersPage)
