import { Button, MenuItem } from "@material-ui/core"
import set from "lodash/fp/set"
import React, { ChangeEvent } from "react"
import styled from "styled-components"

import ArtMedium from "../../core/enums/ArtMedium"
import ArtStyle from "../../core/enums/ArtStyle"
import ProductStatus from "../../core/enums/ProductStatus"
import Signature from "../../core/enums/Signature"
import Surface from "../../core/enums/Surface"
import FormErrors from "../../core/errors/FormError"
import Location from "../../core/models/Location"
import Product, {
  Dimensions,
  LimitedEditionConf,
  NewProduct,
} from "../../core/models/Product"
import Translations from "../../core/Translations"
import { FlexCol, FlexRow } from "../Flexbox"
import Grid from "../Grid"
import HoovusPaper, { PaperHeader } from "../HoovusPaper"
import Checkbox from "../Input/Checkbox"
import FormGroup from "../Input/FormGroup"
import RadioButton from "../Input/RadioButton"
import RadioButtons from "../Input/RadioButtons"
import StyledInput from "../Input/StyledInput"
import PropertyItem from "../PropertyItem"
import { Paragraph } from "../Typography"
import LocationForm from "./LocationForm"

const ProductStatusOptions = Object.keys(ProductStatus).map(key => ({
  key,
  label: Translations.Enum.ProductStatus[key as ProductStatus],
}))

const SignatureCheckbox = styled(Checkbox)`
  width: 100%;
`

const PriceCalculator = styled.div`
  min-width: 240px;
  margin-left: 20px;
  margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: 8px;
`

const PriceCalculatorLabel = styled.div`
  font-size: 14px;
`

const PriceCalculatorPrice = styled.div`
  font-size: 18px;
  font-weight: 500;
`

const LocationBtn = styled(Button)`
  &.MuiButton-root {
    font-size: 14px;
  }
`

interface ProductFormProps {
  product: Product | NewProduct
  defaultLocation?: Location
  errors?: FormErrors
  onChange: (updatedProduct: NewProduct) => void
}

const ProductForm: React.FC<ProductFormProps> = ({
  product,
  defaultLocation,
  errors = {},
  onChange,
}) => {
  const onStringChange = (path: string) => ({ target: { value = "" } }) => {
    onChange(set(path, value || undefined, product))
  }

  const onNumberChange = (path: string) => ({ target: { value = "" } }) => {
    onChange(set(path, value ? Number(value) : undefined, product))
  }

  const onSelectChange = (path: string, type: any) => ({
    target: { value = "" },
  }) => {
    onChange(set(path, value ? (value as typeof type) : undefined, product))
  }

  const onRadioButtonsChange = (path: string, type: any) => (value: string) => {
    onChange(set(path, value ? (value as typeof type) : undefined, product))
  }

  const onCheckboxChange = (path: string) => (checked: boolean) => {
    onChange(set(path, checked, product))
  }

  const onTagsChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...product,
      tags: e.target.value.split(" "),
    })
  }

  const onLimitedEditionChange = (newValue: string) => {
    const isLimitedEdition = newValue === "true"

    onChange({
      ...product,
      isLimitedEdition,
      limitedEditionConf: isLimitedEdition
        ? product.limitedEditionConf
        : undefined,
    })
  }

  const onSignatureChange = (signature: Signature) => (checked: boolean) => {
    const newSignatures = [...(product.signatures || [])]

    const indexOfSignature = newSignatures.indexOf(signature)

    if (indexOfSignature >= 0 && !checked) {
      newSignatures.splice(indexOfSignature, 1)
    } else if (checked && indexOfSignature < 0) {
      newSignatures.push(signature)
    }

    onChange({
      ...product,
      signatures: newSignatures,
    })
  }

  const onLocationChange = (newLocation: Location) => {
    onChange({
      ...product,
      location: newLocation,
    })
  }

  const toggleLocationSetting = () =>
    onChange({
      ...product,
      location: !product.location ? ({} as Location) : undefined,
    })

  const hasDimensionError = (specificDimensionError: boolean) =>
    typeof errors.dimensions === "string" || specificDimensionError

  const getDimensionError = (
    specificDimensionError: string,
    translation: string
  ) => {
    if (specificDimensionError) {
      return [specificDimensionError, translation].join(" ")
    } else if (typeof errors.dimensions === "string") {
      return [errors.dimensions, translation].join(" ")
    } else {
      return translation
    }
  }
  const {
    title = "",
    description = "",
    year = "",
    style = "",
    medium = "",
    tags,
    price = "",
    dimensions = {} as Dimensions,
    nsfw,
    hasFrame,
    surface = "",
    isLimitedEdition,
    limitedEditionConf = {} as LimitedEditionConf,
    signatures = [],
    status,
    location,
  } = product

  const {
    copies = "",
    copiesForSale = "",
    copiesOutOfTrade = "",
    artistProofs = "",
  } = limitedEditionConf

  const { width = "", height = "", thickness = "", weight = "" } = dimensions

  return (
    <FlexCol>
      <HoovusPaper>
        <PaperHeader>General</PaperHeader>
        <StyledInput
          label="Title *"
          variant="outlined"
          value={title}
          onChange={onStringChange("title")}
          error={!!errors.title}
          helperText={errors.title}
        />
        <StyledInput
          label="Description *"
          variant="outlined"
          multiline
          rows={4}
          value={description}
          onChange={onStringChange("description")}
          error={!!errors.description}
          helperText={[
            errors.description,
            Translations.Form.Product.description,
          ].join(" ")}
        />

        <StyledInput
          select
          label="Style *"
          value={style}
          onChange={onSelectChange("style", ArtStyle)}
          variant="outlined"
          error={!!errors.style}
          helperText={errors.style}
        >
          <MenuItem value={undefined}>
            <em>None</em>
          </MenuItem>
          {Object.entries(Translations.Enum.ArtStyle).map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </StyledInput>

        <StyledInput
          label="Keywords"
          variant="outlined"
          value={tags.join(" ")}
          onChange={onTagsChange}
          error={!!errors.tags}
          helperText={[errors.tags, Translations.Form.Product.keywords].join(
            " "
          )}
        />

        <StyledInput
          label="Year of creation *"
          variant="outlined"
          type="number"
          value={year}
          onChange={onNumberChange("year")}
          error={!!errors.year}
          helperText={errors.year}
        />

        <Checkbox
          label="Has frame"
          checked={hasFrame}
          onChange={onCheckboxChange("hasFrame")}
        />
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Visuals</PaperHeader>
        <Checkbox
          label="This image is not suitable for children and general public"
          checked={nsfw}
          onChange={onCheckboxChange("nsfw")}
        />
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Classification</PaperHeader>
        <StyledInput
          select
          label="Medium *"
          value={medium}
          onChange={onSelectChange("medium", ArtMedium)}
          variant="outlined"
          error={!!errors.medium}
          helperText={errors.medium}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          {Object.keys(ArtMedium).map(key => (
            <MenuItem key={key} value={key}>
              {Translations.Enum.ArtMedium[key as ArtMedium]}
            </MenuItem>
          ))}
        </StyledInput>

        <StyledInput
          select
          label="Support or surface"
          value={surface}
          onChange={onSelectChange("surface", Surface)}
          variant="outlined"
          error={!!errors.surface}
          helperText={errors.surface}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Object.keys(Surface).map(key => (
            <MenuItem key={key} value={key}>
              {Translations.Enum.Surface[key as Surface]}
            </MenuItem>
          ))}
        </StyledInput>

        <RadioButtons
          label="Artwork type"
          value={isLimitedEdition}
          onChange={onLimitedEditionChange}
        >
          <Grid columns="1fr 1fr">
            <RadioButton value={false} label="One of a kind" />
            <RadioButton value={true} label="Limited edition" />
          </Grid>
        </RadioButtons>

        {isLimitedEdition && (
          <Grid columns="1fr 1fr" gap="0 16px">
            <StyledInput
              label="Number of copies"
              variant="outlined"
              type="number"
              value={copies}
              onChange={onNumberChange("limitedEditionConf.copies")}
            />
            <StyledInput
              label="Number of copies for sale"
              variant="outlined"
              type="number"
              value={copiesForSale}
              onChange={onNumberChange("limitedEditionConf.copiesForSale")}
            />
            <StyledInput
              label="Number of copies out of trade"
              variant="outlined"
              type="number"
              value={copiesOutOfTrade}
              onChange={onNumberChange("limitedEditionConf.copiesOutOfTrade")}
            />
            <StyledInput
              label="Number of artist proofs"
              variant="outlined"
              type="number"
              value={artistProofs}
              onChange={onNumberChange("limitedEditionConf.artistProofs")}
            />
          </Grid>
        )}

        <FormGroup label="Signature">
          <Grid columns="1fr 1fr" gap="0 16px">
            {Object.keys(Signature).map(key => (
              <SignatureCheckbox
                key={key}
                label={Translations.Enum.Signature[key as Signature]}
                checked={signatures.includes(key as Signature)}
                onChange={onSignatureChange(key as Signature)}
              />
            ))}
          </Grid>
        </FormGroup>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Dimensions</PaperHeader>
        <Grid columns="1fr 1fr" gap="0 16px">
          <StyledInput
            label="Width *"
            variant="outlined"
            type="number"
            value={width}
            onChange={onNumberChange("dimensions.width")}
            error={hasDimensionError(!!errors.dimensions?.width)}
            helperText={getDimensionError(
              errors.dimensions?.width,
              Translations.Form.Product.Dimensions.width
            )}
          />
          <StyledInput
            label="Height *"
            variant="outlined"
            type="number"
            value={height}
            onChange={onNumberChange("dimensions.height")}
            error={hasDimensionError(!!errors.dimensions?.height)}
            helperText={getDimensionError(
              errors.dimensions?.height,
              Translations.Form.Product.Dimensions.height
            )}
          />
          <StyledInput
            label="Thickness"
            variant="outlined"
            type="number"
            value={thickness}
            onChange={onNumberChange("dimensions.thickness")}
            error={!!errors.dimensions?.thickness}
            helperText={[
              !!errors.dimensions?.thickness,
              Translations.Form.Product.Dimensions.thickness,
            ].join(" ")}
          />
          <StyledInput
            label="Weight *"
            variant="outlined"
            type="number"
            value={weight}
            onChange={onNumberChange("dimensions.weight")}
            error={hasDimensionError(!!errors.dimensions?.weight)}
            helperText={getDimensionError(
              errors.dimensions?.weight,
              Translations.Form.Product.Dimensions.weight
            )}
          />
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Sales</PaperHeader>

        <RadioButtons
          label="Status"
          value={status}
          onChange={onRadioButtonsChange("status", ProductStatus)}
        >
          <FlexRow>
            {ProductStatusOptions.map(({ key, label }) => (
              <RadioButton key={key} value={key} label={label} />
            ))}
          </FlexRow>
        </RadioButtons>

        <FlexRow align="start">
          <StyledInput
            label="Price *"
            variant="outlined"
            type="number"
            value={price}
            onChange={onNumberChange("price")}
            error={!!errors.price}
            helperText={[errors.price, Translations.Form.Product.price].join(
              " "
            )}
          />
          <PriceCalculator>
            <PriceCalculatorLabel>You will recieve:</PriceCalculatorLabel>
            <PriceCalculatorPrice>{`€${Math.floor(
              ((price as number) || 0) * 0.75
            )}`}</PriceCalculatorPrice>
            <PriceCalculatorLabel>Hoovus will recieve:</PriceCalculatorLabel>
            <PriceCalculatorPrice>{`€${Math.floor(
              ((price as number) || 0) * 0.25
            )}`}</PriceCalculatorPrice>
          </PriceCalculator>
        </FlexRow>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>
          <div>Artwork location</div>
          {defaultLocation && (
            <LocationBtn onClick={toggleLocationSetting}>
              {location ? "Use default location" : "Change location"}
            </LocationBtn>
          )}
        </PaperHeader>
        {location || !defaultLocation ? (
          <>
            <Paragraph>
              The location will be used to calculate the delivery cost.
            </Paragraph>
            <LocationForm
              location={location || ({} as Location)}
              errors={errors}
              onChange={onLocationChange}
            />
          </>
        ) : (
          <>
            <Paragraph>
              The location will be used to calculate the delivery cost.
              <br />
              Artwork is currently marked at your default location (location
              that you marked down in your profile settings).
            </Paragraph>
            <FlexRow>
              <PropertyItem label="Address line 1">
                {defaultLocation?.addressLine1}
              </PropertyItem>
              <PropertyItem label="Address line 2">
                {defaultLocation?.addressLine2}
              </PropertyItem>
            </FlexRow>
            <FlexRow>
              <PropertyItem label="City">{defaultLocation?.city}</PropertyItem>
              <PropertyItem label="State">
                {defaultLocation?.state}
              </PropertyItem>
            </FlexRow>
            <FlexRow>
              <PropertyItem label="Postal code">
                {defaultLocation?.postalCode}
              </PropertyItem>
              <PropertyItem label="Country">
                {defaultLocation?.country
                  ? Translations.Enum.Country[defaultLocation?.country]
                  : undefined}
              </PropertyItem>
            </FlexRow>
            <PropertyItem label="Phone">{defaultLocation?.phone}</PropertyItem>
            <PropertyItem label="Courier instructions">
              {defaultLocation?.instructions}
            </PropertyItem>
          </>
        )}
      </HoovusPaper>
    </FlexCol>
  )
}

export default ProductForm
