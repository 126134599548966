enum TransactionStatus {
  Created = "CREATED",
  Pending = "PENDING",
  Cancelled = "CANCELLED",
  Expired = "EXPIRED",
  Approved = "APPROVED",
  Completed = "COMPLETED",
  PartRefunded = "PART_REFUNDED",
  Refunded = "REFUNDED",
}

export const TransactionStatusTranslations = {
  [TransactionStatus.Created]: "Created",
  [TransactionStatus.Pending]: "Pending",
  [TransactionStatus.Cancelled]: "Cancelled",
  [TransactionStatus.Expired]: "Expired",
  [TransactionStatus.Approved]: "Approved",
  [TransactionStatus.Completed]: "Completed",
  [TransactionStatus.PartRefunded]: "Part refunded",
  [TransactionStatus.Refunded]: "Refunded",
}

export default TransactionStatus
