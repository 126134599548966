import { MenuItem } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import set from "lodash/fp/set"
import React, { ChangeEvent } from "react"
import styled from "styled-components"
import Country from "../../core/enums/Country"
import FormErrors from "../../core/errors/FormError"
import {
  EducationItem,
  Exhibition,
  Biography,
  NewBiography,
} from "../../core/models/Biography"
import Translations from "../../core/Translations"
import Grid from "../Grid"
import HoovusPaper, { PaperHeader } from "../HoovusPaper"
import StyledInput from "../Input/StyledInput"

const AddIconWrapper = styled.div`
  padding: 4px;
  margin-left: 8px;
  cursor: pointer;

  > svg {
    display: block;
  }
`

const ListItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-right: 40px;
`

const DeleteListItem = styled(DeleteIcon)`
  position: absolute;
  right: 0;
  cursor: pointer;
`

interface ListItemProps {
  value: any
  onChange: (newValue: any) => void
}

interface FormListProps {
  value: Array<any>
  itemInitialValue: any
  ItemComponent: React.FC<ListItemProps>
  onChange: (newValue: Array<any>) => void
}

const FormList: React.FC<FormListProps> = ({
  value,
  itemInitialValue,
  ItemComponent,
  onChange,
}) => {
  const onItemChange = (index: number) => (newValue: any) => {
    onChange(set([index], newValue, value))
  }

  const onDeleteItem = (index: number) => () => {
    const newValue = [...value]
    newValue.splice(index, 1)
    onChange(newValue)
  }

  const onAddNewValue = () => {
    if (value.length > 0) {
      onChange([...value, itemInitialValue])
    } else {
      onChange([itemInitialValue, itemInitialValue])
    }
  }

  const valuesToMap = value.length > 0 ? value : [itemInitialValue]

  return (
    <>
      {valuesToMap.map((itemValue, index) => (
        <ListItemRow key={index}>
          <ItemComponent value={itemValue} onChange={onItemChange(index)} />
          <DeleteListItem onClick={onDeleteItem(index)} />
        </ListItemRow>
      ))}
      <AddIconWrapper>
        <AddIcon onClick={onAddNewValue} />
      </AddIconWrapper>
    </>
  )
}

interface EducationInputProps {
  value: EducationItem
  onChange: (updatedEducationItem: EducationItem) => void
}

const EducationInput: React.FC<EducationInputProps> = ({ value, onChange }) => {
  const { startYear = "", endYear = "", school = "", fieldOfStudy = "" } = value

  const onStartYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      startYear: e.target.value ? Number(e.target.value) : undefined,
    })
  }

  const onEndYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      endYear: e.target.value ? Number(e.target.value) : undefined,
    })
  }

  const onSchoolChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      school: e.target.value || undefined,
    })
  }

  const onFieldOfStudyChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      fieldOfStudy: e.target.value || undefined,
    })
  }

  return (
    <Grid columns="140px 140px 1fr 1fr" gap="0 16px">
      <StyledInput
        label="Start year"
        variant="outlined"
        type="number"
        value={startYear}
        onChange={onStartYearChange}
      />
      <StyledInput
        label="End year"
        variant="outlined"
        type="number"
        value={endYear}
        onChange={onEndYearChange}
      />
      <StyledInput
        label="School"
        variant="outlined"
        value={school}
        onChange={onSchoolChange}
      />
      <StyledInput
        label="Field of study"
        variant="outlined"
        value={fieldOfStudy}
        onChange={onFieldOfStudyChange}
      />
    </Grid>
  )
}

interface ExhibitionInputProps {
  value: Exhibition
  onChange: (updatedExhibition: Exhibition) => void
}

const ExhibitionInput: React.FC<ExhibitionInputProps> = ({
  value,
  onChange,
}) => {
  const onYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      year: e.target.value ? Number(e.target.value) : undefined,
    })
  }

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      title: e.target.value || undefined,
    })
  }

  const { year = "", title = "" } = value

  return (
    <Grid columns="140px 1fr" gap="0 16px">
      <StyledInput
        label="Year"
        variant="outlined"
        type="number"
        value={year}
        onChange={onYearChange}
      />
      <StyledInput
        label="Exhibition"
        variant="outlined"
        value={title}
        onChange={onTitleChange}
      />
    </Grid>
  )
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

interface BiographyFormProps {
  biography: Biography | NewBiography
  errors?: FormErrors
  onChange: (updatedBiography: NewBiography) => void
}

const BiographyForm: React.FC<BiographyFormProps> = ({
  biography,
  errors = {},
  onChange,
}) => {
  const onEducationChange = (newEducation: Array<EducationItem>) => {
    onChange({
      ...biography,
      education: newEducation,
    })
  }

  const onSoloExhibitionsChange = (newSoloExhibitions: Array<Exhibition>) => {
    onChange({
      ...biography,
      soloExhibitions: newSoloExhibitions,
    })
  }

  const onGroupExhibitionsChange = (newGroupExhibitions: Array<Exhibition>) => {
    onChange({
      ...biography,
      groupExhibitions: newGroupExhibitions,
    })
  }

  const onStringChange = (path: string, nullable = false) => ({
    target: { value = "" },
  }) => {
    onChange(set(path, value || (nullable ? null : undefined), biography))
  }

  const onNumberChange = (path: string) => ({ target: { value = "" } }) => {
    onChange(set(path, value ? Number(value) : undefined, biography))
  }

  const onSelectChange = (path: string, type: any) => ({
    target: { value = "" },
  }) => {
    onChange(set(path, value ? (value as typeof type) : undefined, biography))
  }

  const {
    pseudonym,
    realName,
    description,
    birthYear,
    birthCountry,
    birthCity,
    education = [],
    groupExhibitions = [],
    soloExhibitions = [],
  } = biography

  return (
    <FormWrapper>
      <HoovusPaper>
        <PaperHeader>General</PaperHeader>
        <StyledInput
          label="Personal name *"
          variant="outlined"
          value={realName || ""}
          onChange={onStringChange("realName", true)}
          error={!!errors.realName}
          helperText={errors.realName || Translations.Form.Biography.pseudonym}
        />

        <StyledInput
          label="Pseudonym *"
          variant="outlined"
          value={pseudonym || ""}
          onChange={onStringChange("pseudonym", true)}
          error={!!errors.pseudonym}
          helperText={errors.pseudonym || Translations.Form.Biography.pseudonym}
        />

        <StyledInput
          label="Description"
          variant="outlined"
          multiline
          rows={4}
          value={description || ""}
          onChange={onStringChange("description")}
          error={!!errors.description}
          helperText={
            errors.description || Translations.Form.Biography.description
          }
        />

        <Grid columns="140px 1fr 1fr" gap="0px 16px">
          <StyledInput
            label="Birth year"
            variant="outlined"
            type="number"
            value={birthYear || ""}
            onChange={onNumberChange("birthYear")}
            error={!!errors.birthYear}
            helperText={errors.birthYear}
          />
          <StyledInput
            label="Birth city"
            variant="outlined"
            value={birthCity || ""}
            onChange={onStringChange("birthCity")}
            error={!!errors.birthCity}
            helperText={errors.birthCity}
          />
          <StyledInput
            select
            label="Birth country *"
            value={birthCountry}
            onChange={onSelectChange("birthCountry", Country)}
            variant="outlined"
            error={!!errors.birthCountry}
            helperText={errors.birthCountry}
          >
            <MenuItem value={undefined}>
              <em>None</em>
            </MenuItem>
            {Object.entries(Translations.Enum.Country).map(([key, label]) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Education</PaperHeader>

        <FormList
          value={education}
          itemInitialValue={{}}
          onChange={onEducationChange}
          ItemComponent={EducationInput}
        />
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Solo exhibitions</PaperHeader>
        <FormList
          value={soloExhibitions}
          itemInitialValue={{}}
          onChange={onSoloExhibitionsChange}
          ItemComponent={ExhibitionInput}
        />
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Group exhibitions</PaperHeader>
        <FormList
          value={groupExhibitions}
          itemInitialValue={{}}
          onChange={onGroupExhibitionsChange}
          ItemComponent={ExhibitionInput}
        />
      </HoovusPaper>
    </FormWrapper>
  )
}

export default BiographyForm
