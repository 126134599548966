import React from "react"
import styled from "styled-components"

const Wrapper = styled.div<{ row?: string; col?: string }>`
  padding: 12px 0;

  ${({ row }) => row && `grid-row: ${row};`}
  ${({ col }) => col && `grid-column: ${col};`}
`

const Label = styled.div`
  font-size: 14px;
  color: #8c8c8c;
  padding-bottom: 4px;
`

const PropertyValue = styled.div``

interface PropertyItemProps {
  label: string
  row?: string
  col?: string
}

const PropertyItem: React.FC<PropertyItemProps> = ({
  label,
  children,
  row,
  col,
}) => {
  return (
    <Wrapper row={row} col={col}>
      <Label>{label}</Label>
      <PropertyValue>{children || "-"}</PropertyValue>
    </Wrapper>
  )
}

export default PropertyItem
