enum Role {
  Customer = "Customer",
  Artist = "Artist",
  Gallery = "Gallery",
  Admin = "Admin",
}

export const ALL_ROLES = [Role.Customer, Role.Artist, Role.Gallery, Role.Admin]

export default Role
