import { ArtMediumTranslations } from "./enums/ArtMedium"
import { ArtStyleTranslations } from "./enums/ArtStyle"
import { CountryTranslations } from "./enums/Country"
import { ProductStatusTranslations } from "./enums/ProductStatus"
import { SignatureTranslations } from "./enums/Signature"
import { SurfaceTranslations } from "./enums/Surface"
import { TransactionStatusTranslations } from "./enums/TransactionStatus"

const Translations = {
  Enum: {
    ArtMedium: ArtMediumTranslations,
    ArtStyle: ArtStyleTranslations,
    ProductStatus: ProductStatusTranslations,
    Signature: SignatureTranslations,
    Surface: SurfaceTranslations,
    Country: CountryTranslations,
    TransactionStatus: TransactionStatusTranslations,
  },
  Form: {
    Biography: {
      realName: `Personal name or pseudonym is required.`,
      pseudonym: `Personal name or pseudonym is required.`,
      description: `Describe yourself and your journey in the art world. What is the intent and purpose of your art? What does it say about you as an artist? What keeps you motivated every day? What is your artist's story? What questions are you often asked about your work?`,
    },
    Product: {
      description: `Describe the atmosphere/mood of your artwork as well as the visual elements. Try to create an emotional connection between your artwork and the person looking at your art piece. You could bring out aspects like tones, color, composition, texture, atmosphere, lighting, viewpoint, style etc`,
      keywords: `Keywords will be separated by space. Try to bring out key elements that are emphasised and in the spotlight in the art piece (E.g sea village mountains people).`,
      Dimensions: {
        width: `Represented in centimeters (cm).`,
        height: `Represented in centimeters (cm).`,
        thickness: `Represented in centimeters (cm).`,
        weight: `Represented in kilograms (kg).`,
      },
      price: `Add your price for the art in euros (€). The platform will automatically take 25% cut from the final price.`,
    },
  },
}

export default Object.freeze(Translations)
