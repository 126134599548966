import React, { ChangeEvent } from "react"
import styled from "styled-components"

export const ImageUploadPreview = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
  }
`
export const ImageUploadBtnWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  position: relative;
`

export const ImageUploadBtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;

  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
`

export const ImageUploadContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const ImageUploadInfo = styled.span`
  font-size: 14px;
  font-weight: 400;
  margin: 20px 30px -20px;
  text-align: center;
  text-transform: none;
  line-height: 1.66;
`

const StyledLabel = styled.label`
  cursor: pointer;
`

export interface UploadedImage {
  file: File
  fileSize: number
  preview: string
}

interface ImageUploadProps {
  options?: {
    multiple?: boolean
  }
  onChange: (uploads: Array<UploadedImage>) => void
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  children,
  options = {},
  onChange,
}) => {
  const toBase64 = (file: File): Promise<UploadedImage> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const fileSize = Number((file.size / 1024 / 1024).toFixed(4))
        resolve({ file, fileSize, preview: reader.result as string })
      }
      reader.onerror = error => reject(error)
    })

  const onImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    Promise.all(
      Array.from(e.target.files || []).map(file => toBase64(file))
    ).then((loadedImages: Array<UploadedImage>) => onChange(loadedImages))
  }

  const { multiple } = options

  return (
    <>
      <input
        accept=".png,.jpg,.jpeg"
        style={{ display: "none" }}
        id="image-upload"
        multiple={multiple}
        type="file"
        onChange={onImageUpload}
      />
      <StyledLabel htmlFor="image-upload">{children}</StyledLabel>
    </>
  )
}

export default ImageUpload
