import styled from "styled-components"

const HoovusPaper = styled.div`
  background: #fff;
  padding: 16px 16px 24px;
  box-shadow: rgba(50, 50, 93, 0.025) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.05) 0px 1px 3px -1px;
  border-radius: 6px;
  margin-bottom: 24px;
`

export const PaperHeader = styled.div<{ padding?: string }>`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ padding }) => padding && `padding: ${padding};`}
`

export default HoovusPaper
