import React from "react"
import { connect } from "react-redux"
import { Redirect, useLocation } from "react-router-dom"
import { compose } from "redux"

import Status from "../core/enums/Status"
import { StoreState } from "../redux/reducer"

interface PrivateRouteProps {
  loggedIn: boolean
  authStatus: Status
  logoutStatus: Status
}

const privateRoute = (ChildComponent: React.FC) => ({
  loggedIn,
  authStatus,
  logoutStatus,
  ...props
}: PrivateRouteProps) => {
  const location = useLocation()

  if (authStatus === Status.SUCCESS && loggedIn) {
    return <ChildComponent {...props} />
  } else if (
    ([Status.SUCCESS, Status.FAIL].includes(authStatus) && !loggedIn) ||
    logoutStatus === Status.SUCCESS
  ) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: {
            ...(location.pathname !== "/" && { redirectTo: location.pathname }),
          },
        }}
      />
    )
  } else {
    return null
  }
}

export default compose(
  connect((state: StoreState) => ({
    loggedIn: !!state.auth.user,
    logoutStatus: state.auth.logoutStatus,
    authStatus: state.auth.authStatus,
  })),
  privateRoute
)
