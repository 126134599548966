import { Button } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link as RouterLink, useParams } from "react-router-dom"
import { compose } from "redux"
import styled from "styled-components"

import Grid from "../components/Grid"
import HoovusPaper, { PaperHeader } from "../components/HoovusPaper"
import { PageWrapper } from "../components/Layout"
import ProductModal from "../components/Modals/ProductModal"
import ProductGridItem from "../components/ProductGridItem"
import PropertyItem from "../components/PropertyItem"
import Order, {
  calculateShippingTotal,
  calculateSubTotal,
  OrderProduct,
} from "../core/models/Order"
import Product from "../core/models/Product"
import Location from "../core/models/Location"
import { AsyncAction } from "../redux/middleware/asyncMiddleware"
import { fetchOrder } from "../redux/modules/order"
import { StoreState } from "../redux/reducer"
import { formatDateString } from "../utils/DateUtils"
import ShippingRoute from "../core/models/Order"
import privateRoute from "../hoc/privateRoute"

interface MappedShippingRoute {
  location?: Location
  products: Array<Product>
  calculatedPrice: number
}

const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
`

const ShippingRouteHeading = styled.div`
  font-weight: 500;
  font-size: 14px;
  padding: 24px 0 16px;

  span {
    color: #8c8c8c;
    padding-top: 8px;
    font-weight: 400;
    display: block;

    b {
      color: initial;
    }
  }
`

const StyledShippingRoute = styled.div`
  border-bottom: 1px solid #c3c3c3;
  margin: 20px 0;
  padding-bottom: 40px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

interface ShippingRouteItemProps {
  index: number
  shippingRoute: MappedShippingRoute
  openProduct: (product: Product) => () => void
}

const ShippingRouteItem: React.FC<ShippingRouteItemProps> = ({
  index,
  shippingRoute,
  openProduct,
}) => {
  const { location, products, calculatedPrice } = shippingRoute

  return (
    <StyledShippingRoute>
      <ShippingRouteHeading>
        Shipping route #{index}{" "}
        <span>
          Calculated shipping price: <b>€{calculatedPrice}</b>
        </span>
      </ShippingRouteHeading>

      {location ? (
        <Grid columns="1fr 1fr 1fr">
          <PropertyItem label="Address line 1">
            {location.addressLine1}
          </PropertyItem>
          <PropertyItem label="Address line 2">
            {location.addressLine2}
          </PropertyItem>
          <PropertyItem label="City">{location.city}</PropertyItem>
          <PropertyItem label="State">{location.state}</PropertyItem>
          <PropertyItem label="Postal code">{location.postalCode}</PropertyItem>
          <PropertyItem label="Country">{location.country}</PropertyItem>
          <PropertyItem label="Phone">{location.phone}</PropertyItem>
        </Grid>
      ) : (
        <ShippingRouteHeading>
          No location set for product or artist
        </ShippingRouteHeading>
      )}

      <ShippingRouteHeading>Products from this location:</ShippingRouteHeading>

      <ImagesWrapper>
        {products.map(product => (
          <ProductGridItem
            key={product.id}
            title={product.title}
            price={product.price}
            image={product.images[0]?.thumbnail?.url}
            onClick={openProduct(product)}
          />
        ))}
      </ImagesWrapper>
    </StyledShippingRoute>
  )
}

interface StoreProps {
  order: Order
}

interface DispatchProps {
  fetchOrder: (orderId: string) => Promise<AsyncAction>
}

interface OrderPageProps extends StoreProps, DispatchProps {}

const OrderPage: React.FC<OrderPageProps> = ({ order, fetchOrder }) => {
  const { id } = useParams<{ id: string }>()

  const [activeProduct, setActiveProduct] = useState<Product | null>(null)

  const closeProductModal = () => setActiveProduct(null)

  const openProduct = (product: Product) => () => setActiveProduct(product)

  useEffect(() => {
    fetchOrder(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!order) return null

  const {
    orderProducts,
    shipping,
    shippingRoutes,
    transactionStatus,
    transactionId,
    createdAt,
    updatedAt,
  } = order

  const {
    firstName,
    lastName,
    email,
    company,
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    country,
    phone,
    instructions,
  } = shipping

  const subTotal = calculateSubTotal(orderProducts)
  const shippingTotal = calculateShippingTotal(shippingRoutes)

  const mapShippingRouteProducts = (orderProducts: Array<OrderProduct>) => (
    shippingRoute: ShippingRoute
  ): MappedShippingRoute => {
    return {
      ...shippingRoute,
      products: shippingRoute.products.map(shippingRouteProduct => {
        const orderProduct = orderProducts.find(
          orderProduct => orderProduct.product.id === shippingRouteProduct.id
        )
        return orderProduct?.product
      }),
    } as MappedShippingRoute
  }

  const shippingRoutesWithLocation = shippingRoutes
    .filter(shippingRoute => !!shippingRoute.location)
    .map(mapShippingRouteProducts(orderProducts))

  const shippingRoutesWithoutLocation = shippingRoutes
    .filter(shippingRoute => !shippingRoute.location)
    .map(mapShippingRouteProducts(orderProducts))

  return (
    <PageWrapper>
      {activeProduct && (
        <ProductModal product={activeProduct} onClose={closeProductModal} />
      )}

      <Button component={RouterLink} to="/orders">
        Back
      </Button>

      <HoovusPaper>
        <PaperHeader>General</PaperHeader>
        <Grid columns="1fr 1fr">
          <PropertyItem label="Sub total">€{subTotal.toFixed(2)}</PropertyItem>
          <PropertyItem label="Shipping price">
            €{shippingTotal.toFixed(2)}
          </PropertyItem>
          <PropertyItem label="Total price">
            €{(subTotal + shippingTotal).toFixed(2)}
          </PropertyItem>
          <PropertyItem label="Transaction status">
            {transactionStatus}
          </PropertyItem>
          <PropertyItem label="Transaction ID">{transactionId}</PropertyItem>
          <PropertyItem label="Updated at">
            {formatDateString(updatedAt, "dd.MM.Y HH:mm")}
          </PropertyItem>
          <PropertyItem label="Created at">
            {formatDateString(createdAt, "dd.MM.Y HH:mm")}
          </PropertyItem>
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Shipping</PaperHeader>
        <Grid columns="1fr 1fr 1fr">
          <PropertyItem label="First name">{firstName}</PropertyItem>
          <PropertyItem label="Last name">{lastName}</PropertyItem>
          <PropertyItem label="Email">{email}</PropertyItem>
          <PropertyItem label="Company">{company}</PropertyItem>
          <PropertyItem label="Address line 1">{addressLine1}</PropertyItem>
          <PropertyItem label="Address line 2">{addressLine2}</PropertyItem>
          <PropertyItem label="City">{city}</PropertyItem>
          <PropertyItem label="State">{state}</PropertyItem>
          <PropertyItem label="Postal code">{postalCode}</PropertyItem>
          <PropertyItem label="Country">{country}</PropertyItem>
          <PropertyItem label="Phone">{phone}</PropertyItem>
          <PropertyItem label="Instructions" col="1 / span 3">
            {instructions}
          </PropertyItem>
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Shipping routes</PaperHeader>
        {shippingRoutesWithLocation.map((shippingRoute, index) => (
          <ShippingRouteItem
            key={index}
            index={index + 1}
            shippingRoute={shippingRoute}
            openProduct={openProduct}
          />
        ))}
        {shippingRoutesWithoutLocation.map((shippingRoute, index) => (
          <ShippingRouteItem
            key={index}
            index={shippingRoutesWithLocation.length + index + 1}
            shippingRoute={shippingRoute}
            openProduct={openProduct}
          />
        ))}
      </HoovusPaper>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      order: state.order.activeOrder,
    }),
    {
      fetchOrder,
    }
  )
)(OrderPage)
