import ApiClient from "../../core/ApiClient"
import Config from "../../core/Config"
import Status from "../../core/enums/Status"
import {
  BiographySearch,
  ProductSearch,
  UserSearch,
} from "../../core/models/Search"
import { AsyncAction, asyncType } from "../middleware/asyncMiddleware"

export interface SearchStore {
  biographySearch: Array<BiographySearch>
  biographySearchStatus: Status

  userSearch: Array<UserSearch>
  userSearchStatus: Status

  productSearch: Array<ProductSearch>
  productSearchStatus: Status
}

const initialState = {
  biographySearch: [] as Array<BiographySearch>,
  biographySearchStatus: Status.INITIAL,

  userSearch: [] as Array<UserSearch>,
  userSearchStatus: Status.INITIAL,

  productSearch: [] as Array<ProductSearch>,
  productSearchStatus: Status.INITIAL,
}

const SEARCH_BIOGRAPHIES = asyncType("redux.search.SEARCH_BIOGRAPHIES")
const SEARCH_USERS = asyncType("redux.search.SEARCH_USERS")
const SEARCH_PRODUCTS = asyncType("redux.search.SEARCH_PRODUCTS")

const CLEAR_BIOGRAPHY_SEARCH = "redux.search.CLEAR_BIOGRAPHY_SEARCH"
const CLEAR_USER_SEARCH = "redux.search.CLEAR_USER_SEARCH"
const CLEAR_PRODUCT_SEARCH = "redux.search.CLEAR_PRODUCT_SEARCH"

export default function reducer(
  state = initialState,
  action: AsyncAction
): SearchStore {
  switch (action.type) {
    case SEARCH_BIOGRAPHIES.INITIAL: {
      return {
        ...state,
        biographySearchStatus: Status.LOADING,
      }
    }

    case SEARCH_BIOGRAPHIES.SUCCESS: {
      return {
        ...state,
        biographySearch: action.result?.body as Array<BiographySearch>,
        biographySearchStatus: Status.SUCCESS,
      }
    }

    case SEARCH_BIOGRAPHIES.FAIL: {
      return {
        ...state,
        biographySearchStatus: Status.FAIL,
      }
    }

    case SEARCH_USERS.INITIAL: {
      return {
        ...state,
        userSearchStatus: Status.LOADING,
      }
    }

    case SEARCH_USERS.SUCCESS: {
      return {
        ...state,
        userSearch: action.result?.body as Array<UserSearch>,
        userSearchStatus: Status.SUCCESS,
      }
    }

    case SEARCH_USERS.FAIL: {
      return {
        ...state,
        userSearchStatus: Status.FAIL,
      }
    }

    case SEARCH_PRODUCTS.INITIAL: {
      return {
        ...state,
        productSearchStatus: Status.LOADING,
      }
    }

    case SEARCH_PRODUCTS.SUCCESS: {
      return {
        ...state,
        productSearch: action.result?.body as Array<ProductSearch>,
        productSearchStatus: Status.SUCCESS,
      }
    }

    case SEARCH_PRODUCTS.FAIL: {
      return {
        ...state,
        productSearchStatus: Status.FAIL,
      }
    }

    case CLEAR_BIOGRAPHY_SEARCH: {
      return {
        ...state,
        biographySearch: [],
        biographySearchStatus: Status.INITIAL,
      }
    }

    case CLEAR_USER_SEARCH: {
      return {
        ...state,
        userSearch: [],
        userSearchStatus: Status.INITIAL,
      }
    }

    case CLEAR_PRODUCT_SEARCH: {
      return {
        ...state,
        productSearch: [],
        productSearchStatus: Status.INITIAL,
      }
    }

    default:
      return state
  }
}

export function searchBiography(search: string) {
  return {
    types: SEARCH_BIOGRAPHIES,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/biography/search?q=${search}`),
  }
}

export function searchUser(search: string) {
  return {
    types: SEARCH_USERS,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/users/search?q=${search}`),
  }
}

export function searchProduct(search: string) {
  return {
    types: SEARCH_PRODUCTS,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/product/search?q=${search}`),
  }
}

export function clearBiographySearch() {
  return {
    type: CLEAR_BIOGRAPHY_SEARCH,
  }
}

export function clearUserSearch() {
  return {
    type: CLEAR_USER_SEARCH,
  }
}

export function clearProductSearch() {
  return {
    type: CLEAR_PRODUCT_SEARCH,
  }
}
