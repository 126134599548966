import { Action } from "redux"
import { StoreState } from "../reducer"

export enum ToastType {
  Success = "Success",
  Error = "Error",
}

export interface Toast {
  id: string
  type: ToastType
  message: string
  action?: Function
  timeout?: number
}

export interface ToastAction extends Action {
  type: string
  toast: Toast
}
export interface ToastStore {
  toasts: Array<Toast>
}

const initialState = {
  toasts: [] as Array<Toast>,
}

const DISPATCH_TOAST = "redux.toasts.DISPATCH_TOAST"
const REMOVE_TOAST = "redux.toasts.REMOVE_TOAST"

export default function reducer(
  state = initialState,
  action: ToastAction
): ToastStore {
  switch (action.type) {
    case DISPATCH_TOAST: {
      return {
        ...state,
        toasts: [...state.toasts, action.toast],
      }
    }

    case REMOVE_TOAST: {
      const newToasts = [...state.toasts] as Array<Toast>

      const toastIndex = state.toasts.findIndex(
        toast => toast.id === action.toast.id
      )

      if (toastIndex >= 0) {
        newToasts.splice(toastIndex, 1)

        return {
          ...state,
          toasts: newToasts,
        }
      }

      return state
    }

    default:
      return state
  }
}

export function dispatchToast(
  message: String,
  type: ToastType,
  timeout?: number,
  action?: Function
) {
  return (dispatch: Function, getState: () => StoreState) => {
    const id = Date.now().toString()

    const existingToast = getState().toast.toasts.find(
      toast => toast.message === message
    )

    if (existingToast) dispatch(removeToast(existingToast.id))

    dispatch({
      type: DISPATCH_TOAST,
      toast: {
        id,
        message,
        type,
        timeout,
        action,
      } as Toast,
    })

    if (timeout) {
      setTimeout(() => {
        dispatch(removeToast(id))
      }, timeout)
    }

    return id
  }
}

export function removeToast(id: string) {
  return {
    type: REMOVE_TOAST,
    toast: { id } as Toast,
  }
}
