import { Breadcrumbs, Button, IconButton } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
`

const Total = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
`

const PaginationButton = styled(Button)`
  &.MuiButton-text {
    padding: 0;
  }

  &.MuiButton-root {
    min-width: 20px;
    color: rgba(0, 0, 0, 0.4);
  }

  &:disabled {
    &.MuiButton-root.Mui-disabled {
      color: black;
    }
  }
`

const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  &.MuiTypography-root {
    margin: 0 12px;
  }
`
interface PaginationProps {
  activePage: number
  pageSize: number
  count: number
  onPageChange: (newPage: number) => void
  onPageSizeChange: (newPageSize: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
  activePage,
  pageSize,
  count,
  onPageChange,
  onPageSizeChange,
}) => {
  const onPageClick = (page: number) => () => onPageChange(page)

  const pagesCount = Math.ceil(count / pageSize)

  const getPages = () =>
    new Array(pagesCount).fill(0).map((val, index) => index + 1)

  return (
    <PaginationWrapper>
      <Total>
        Showing{" "}
        <b>
          {(activePage - 1) * pageSize + 1} -{" "}
          {Math.min(activePage * pageSize, count)}
        </b>{" "}
        of <b>{count}</b>
      </Total>

      <NavigationWrapper>
        <IconButton
          disabled={activePage === 1}
          onClick={onPageClick(activePage - 1)}
        >
          <ChevronLeftIcon />
        </IconButton>
        <StyledBreadcrumbs maxItems={30}>
          {getPages().map(page => (
            <PaginationButton
              key={page}
              onClick={onPageClick(page)}
              disabled={page === activePage}
            >
              {page}
            </PaginationButton>
          ))}
        </StyledBreadcrumbs>
        <IconButton
          disabled={activePage === pagesCount}
          onClick={onPageClick(activePage + 1)}
        >
          <ChevronRightIcon />
        </IconButton>
      </NavigationWrapper>
    </PaginationWrapper>
  )
}

export default Pagination
