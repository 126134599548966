import { Chip } from "@material-ui/core"
import React from "react"

import ProductStatus from "../core/enums/ProductStatus"
import TransactionStatus from "../core/enums/TransactionStatus"
import Translations from "../core/Translations"

interface ChipProps {
  value: any
}

export const ProductStatusChip: React.FC<ChipProps> = ({ value }) => {
  switch (value) {
    case ProductStatus.ForSale:
      return (
        <Chip label={Translations.Enum.ProductStatus[ProductStatus.ForSale]} />
      )
    case ProductStatus.NotForSale:
      return (
        <Chip
          label={Translations.Enum.ProductStatus[ProductStatus.NotForSale]}
          color="secondary"
        />
      )
    case ProductStatus.Sold:
      return (
        <Chip
          label={Translations.Enum.ProductStatus[ProductStatus.Sold]}
          color="primary"
        />
      )
    default:
      return null
  }
}

export const OrderStatusChip: React.FC<ChipProps> = ({ value }) => {
  switch (value) {
    case TransactionStatus.Completed:
      return (
        <Chip
          label={
            Translations.Enum.TransactionStatus[TransactionStatus.Completed]
          }
          color="primary"
        />
      )
    case TransactionStatus.Cancelled:
    case TransactionStatus.PartRefunded:
    case TransactionStatus.Refunded:
      return (
        <Chip
          label={
            Translations.Enum.TransactionStatus[value as TransactionStatus]
          }
          color="secondary"
        />
      )
    default:
      return (
        <Chip
          label={
            Translations.Enum.TransactionStatus[value as TransactionStatus]
          }
        />
      )
  }
}
