import { Button } from "@material-ui/core"
import React, { useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { compose } from "redux"

import ActionBar from "../../components/ActionBar"
import { PrimaryButton } from "../../components/Buttons"
import ProductForm from "../../components/Forms/ProductForm"
import Grid from "../../components/Grid"
import { HistoryLocation } from "../../components/HistoryLink"
import HoovusPaper from "../../components/HoovusPaper"
import { PageWrapper } from "../../components/Layout"
import TypeSearch, { SearchType } from "../../components/TypeSearch"
import { Paragraph } from "../../components/Typography"
import ProductStatus from "../../core/enums/ProductStatus"
import Status from "../../core/enums/Status"
import FormErrors from "../../core/errors/FormError"
import Product, { Dimensions, NewProduct } from "../../core/models/Product"
import privateRoute from "../../hoc/privateRoute"
import { AsyncResult } from "../../redux/middleware/asyncMiddleware"
import { createProduct } from "../../redux/modules/product"
import { dispatchToast, ToastType } from "../../redux/modules/toasts"
import { StoreState } from "../../redux/reducer"

interface StoreProps {
  productStatus: Status
}

interface DispatchProps {
  createProduct: (newProduct: NewProduct) => Promise<AsyncResult>
  dispatchToast: (message: string, type: ToastType, timeout?: number) => string
}

interface NewProductPageProps extends StoreProps, DispatchProps {}

const NewProductPage: React.FC<NewProductPageProps> = ({
  productStatus,
  createProduct,
  dispatchToast,
}) => {
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()

  const [errors, setErrors] = useState<FormErrors>({})
  const [newProduct, setNewProduct] = useState<NewProduct>({
    title: "",
    description: "",
    hasFrame: false,
    status: ProductStatus.ForSale,
    tags: [],
    isLimitedEdition: false,
    nsfw: false,
    signatures: [],
    dimensions: {} as Dimensions,
  })

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push("/products")
  }

  const onCreate = () => {
    createProduct(newProduct)
      .then(productResponse => {
        // if (uploadedImage) {
        //   uploadProfilePicture(productResponse.body.id, uploadedImage.file)
        //     .then(goBack)
        //     .catch(() => {
        //       dispatchToast(
        //         "Whoops, failed to upload images. Please try again.",
        //         ToastType.Error,
        //         8000
        //       )
        //     })
        // } else {
        goBack()
        // }
      })
      .catch(errorResponse => {
        if (errorResponse?.body?.name === "ValidationError") {
          dispatchToast(
            "Errors found in your form. Please make sure you have filled all mandatory fields.",
            ToastType.Error,
            8000
          )
          setErrors(errorResponse?.body?.errors)
        } else {
          dispatchToast(
            "Whoops, something went wrong. Please try again.",
            ToastType.Error,
            8000
          )
        }
      })
  }

  const onProductChange = (updatedProduct: NewProduct) =>
    setNewProduct(updatedProduct as Product)

  const onOwnerSelected = (type: SearchType, id: string) => {
    const { userId, biographyId, ...productData } = newProduct

    if (type === SearchType.User) {
      setNewProduct({
        ...productData,
        userId: id,
      })
    } else if (type === SearchType.Biography) {
      setNewProduct({
        ...productData,
        biographyId: id,
      })
    }
  }

  const { userId, biographyId } = newProduct

  return (
    <PageWrapper>
      <ActionBar>
        <Button onClick={goBack}>Back</Button>
        <PrimaryButton
          onClick={onCreate}
          disabled={productStatus === Status.LOADING}
        >
          Create
        </PrimaryButton>
      </ActionBar>

      <Grid columns="1fr 300px" gap="32px" align="start">
        <ProductForm
          product={newProduct}
          errors={errors}
          onChange={onProductChange}
        />
        <HoovusPaper>
          {userId && <Paragraph>Selected user: {userId}</Paragraph>}
          {biographyId && (
            <Paragraph>Selected biography: {biographyId}</Paragraph>
          )}
          <TypeSearch onSelect={onOwnerSelected} />
        </HoovusPaper>
      </Grid>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      productStatus: state.product.activeProductStatus,
    }),
    {
      createProduct,
      dispatchToast,
    }
  )
)(NewProductPage)
