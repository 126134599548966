import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import React from "react"
import styled from "styled-components"

import Config from "../core/Config"
import Image from "../core/models/Image"
import { dispatchToast, ToastType } from "../redux/modules/toasts"
import Grid from "./Grid"
import ImageUpload, {
  ImageUploadBtn,
  ImageUploadInfo,
  UploadedImage,
} from "./ImageUpload"

const ImagesWrapper = styled.div`
  margin: 24px 0;
`

const GridItem = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  position: relative;
`

const GridItemWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;

  &.grid-image-item {
    &:hover {
      .grid-image-item-remove {
        opacity: 1;
      }
    }
  }
`

const PreviewImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
`

const RemoveImage = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  transition: all 250ms cubic-bezier(0.55, 0, 0.1, 1);
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    color: white;
    font-size: 36px;
  }
`

interface GridImageItemProps {
  src: string
  onRemove: () => void
}

export const GridImageItem: React.FC<GridImageItemProps> = ({
  src,
  onRemove,
}) => (
  <GridItem>
    <GridItemWrapper className="grid-image-item">
      <PreviewImage src={src} />
      <RemoveImage className="grid-image-item-remove" onClick={onRemove}>
        <DeleteIcon />
      </RemoveImage>
    </GridItemWrapper>
  </GridItem>
)

interface ProductImageUploadProps {
  productImages: Array<Image>
  images: Array<UploadedImage>
  onProductImagesChange: (newProductImages: Array<Image>) => void
  onNewImagesChange: (newImages: Array<UploadedImage>) => void
}

const ProductImageUpload: React.FC<ProductImageUploadProps> = ({
  productImages,
  images,
  onProductImagesChange,
  onNewImagesChange,
}) => {
  function onAddImages(imagesToUpload: Array<UploadedImage>) {
    const correctSizeImages = imagesToUpload.filter(
      img => img.fileSize < Config.uploads.maxImgSizeMB
    )

    if (correctSizeImages.length !== imagesToUpload.length) {
      dispatchToast(
        "Whoops, some of the selected images are too big! Try to compress your images below 10MBs.",
        ToastType.Error,
        8000
      )
    }

    onNewImagesChange([...correctSizeImages, ...images])
  }

  const onRemoveProductImage = (imageId: string) => () =>
    onProductImagesChange(productImages.filter(({ id }) => id !== imageId))

  const onRemoveUploadedImage = (index: number) => () => {
    const newImages = [...images]
    newImages.splice(index, 1)
    onNewImagesChange(newImages)
  }

  return (
    <ImagesWrapper>
      <Grid columns="repeat(2, 1fr)" gap="16px">
        <GridItem>
          <GridItemWrapper>
            <ImageUpload onChange={onAddImages} options={{ multiple: true }}>
              <ImageUploadBtn>
                <AddIcon />
                <span>Upload image</span>
                <ImageUploadInfo>
                  Images should be at least 800px wide or high and smaller than
                  10MB.
                </ImageUploadInfo>
              </ImageUploadBtn>
            </ImageUpload>
          </GridItemWrapper>
        </GridItem>

        {images.map(({ preview }, index) => (
          <GridImageItem
            key={index}
            src={preview}
            onRemove={onRemoveUploadedImage(index)}
          />
        ))}

        {productImages.map(({ id, large, thumbnail }) => (
          <GridImageItem
            key={id}
            src={thumbnail.url || large.url}
            onRemove={onRemoveProductImage(id)}
          />
        ))}
      </Grid>
    </ImagesWrapper>
  )
}

export default ProductImageUpload
