import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link as RouterLink } from "react-router-dom"
import { compose } from "redux"
import styled from "styled-components"
import ActionBar from "../../components/ActionBar"

import { ProductStatusChip } from "../../components/EnumChip"
import HistoryLink from "../../components/HistoryLink"
import HoovusPaper from "../../components/HoovusPaper"
import { PageWrapper } from "../../components/Layout"
import Pagination from "../../components/Pagination"
import ProductStatus from "../../core/enums/ProductStatus"
import { PaginatedData } from "../../core/models/PaginatedData"
import Product from "../../core/models/Product"
import ProductFilter from "../../core/models/ProductFilter"
import Translations from "../../core/Translations"
import privateRoute from "../../hoc/privateRoute"
import { AsyncAction } from "../../redux/middleware/asyncMiddleware"
import { fetchProducts, resetProducts } from "../../redux/modules/product"
import { StoreState } from "../../redux/reducer"
import { formatDateString } from "../../utils/DateUtils"

const TableWrapper = styled.div`
  padding: 24px 0;
`

const ProductTitle = styled.div`
  font-weight: 600;
`
const ImageCell = styled(TableCell)`
  width: 60px;
`

const ProductImageCell = styled(TableCell)`
  padding: 4px;
`

const ProductImage = styled.div<{ src: string }>`
  height: 60px;
  width: 60px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  border-radius: 4px;
`

const StyledLink = styled(HistoryLink)`
  text-decoration: none;
  color: initial;
`

interface StoreProps {
  products: PaginatedData<Product>
}

interface DispatchProps {
  fetchProducts: (filter: ProductFilter) => Promise<AsyncAction>
  resetProducts: () => void
}

interface ProductsPageProps extends StoreProps, DispatchProps {}

const ProductsPage: React.FC<ProductsPageProps> = ({
  products,
  fetchProducts,
  resetProducts,
}) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  useEffect(() => {
    onFetchData(page, pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFetchData = (newPage: number, pageSize: number) => {
    fetchProducts({ page: newPage, limit: pageSize } as ProductFilter)
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage)
    onFetchData(newPage, pageSize)
  }

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize)
    resetProducts()
    onFetchData(page, newPageSize)
  }

  return (
    <PageWrapper>
      <ActionBar>
        <Button component={RouterLink} to="/product/new">
          ADD NEW
        </Button>
      </ActionBar>

      <HoovusPaper>
        <Pagination
          activePage={page}
          pageSize={pageSize}
          count={products.total}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <ImageCell>Image</ImageCell>
                <TableCell>Title / Author</TableCell>
                <TableCell>Medium</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell width="60" align="center">
                  Price
                </TableCell>
                <TableCell width="115" align="right">
                  Updated
                </TableCell>
                <TableCell width="115" align="right">
                  Created
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.getPageData(page).map(product => (
                <TableRow key={product.id}>
                  <ProductImageCell>
                    <ProductImage src={product.images[0]?.thumbnail?.url} />
                  </ProductImageCell>
                  <TableCell component="th" scope="row">
                    <ProductTitle>{product.title}</ProductTitle>
                    {product.userId && (
                      <StyledLink to={`/user/${product.userId}`}>
                        {product.author?.realName}
                        {product.author?.pseudonym &&
                          ` (${product.author?.pseudonym})`}
                      </StyledLink>
                    )}
                    {product.biographyId && (
                      <StyledLink to={`/biography/${product.biographyId}`}>
                        {product.author?.realName}
                        {product.author?.pseudonym &&
                          ` (${product.author?.pseudonym})`}
                      </StyledLink>
                    )}
                  </TableCell>
                  <TableCell>
                    {product.medium
                      ? Translations.Enum.ArtMedium[product.medium]
                      : ""}
                  </TableCell>
                  <TableCell align="center">
                    <ProductStatusChip value={product.status} />
                  </TableCell>
                  <TableCell align="center">
                    {product.status === ProductStatus.ForSale
                      ? `€${product.price}`
                      : ""}
                  </TableCell>
                  <TableCell align="right">
                    {formatDateString(product.updatedAt, "dd.MM.Y HH:mm")}
                  </TableCell>
                  <TableCell align="right">
                    {formatDateString(product.createdAt, "dd.MM.Y HH:mm")}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`/product/${product.id}`}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
        <Pagination
          activePage={page}
          pageSize={pageSize}
          count={products.total}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      </HoovusPaper>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      products: state.product.products,
    }),
    {
      fetchProducts,
      resetProducts,
    }
  )
)(ProductsPage)
