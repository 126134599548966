import React from "react"
import styled from "styled-components"

const Wrapper = styled.div<{ onClick?: () => void }>`
  text-decoration: none;
  color: initial;
  ${({ onClick }) => !!onClick && "cursor: pointer;"}
`

const Meta = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-top: 12px;
  text-decoration: none;
`

const Image = styled.div<{ src?: string }>`
  height: 0;
  padding-bottom: 80%;
  background-image: url(${({ src }) => src || "/image-placeholder.png"});
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
    rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
`

interface ProductGridItemProps {
  title: string
  price?: number
  image?: string
  onClick?: () => void
}

const ProductGridItem: React.FC<ProductGridItemProps> = ({
  title,
  price,
  image,
  onClick,
}) => {
  return (
    <Wrapper onClick={onClick}>
      <Image src={image} />
      <Meta>
        <div>{title}</div>
        {price && <div>€{price}</div>}
      </Meta>
    </Wrapper>
  )
}

export default ProductGridItem
