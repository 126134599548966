import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { compose } from "redux"
import styled from "styled-components"

import ActionBar from "../../components/ActionBar"
import {
  PrimaryButton,
  PrimaryLink,
  SecondaryLink,
} from "../../components/Buttons"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import Grid from "../../components/Grid"
import HistoryLink, { HistoryLocation } from "../../components/HistoryLink"
import HoovusPaper, { PaperHeader } from "../../components/HoovusPaper"
import { PageWrapper } from "../../components/Layout"
import PropertyItem from "../../components/PropertyItem"
import Auction from "../../core/models/Auction"
import AuctionBid from "../../core/models/AuctionBid"
import AuctionItem from "../../core/models/AuctionItem"
import privateRoute from "../../hoc/privateRoute"
import { AsyncResult } from "../../redux/middleware/asyncMiddleware"
import {
  deleteAuction,
  fetchAuction,
  fetchAuctionItems,
} from "../../redux/modules/auction"
import { dispatchToast, ToastType } from "../../redux/modules/toasts"
import { StoreState } from "../../redux/reducer"
import { formatDateString } from "../../utils/DateUtils"

const StyledLink = styled(HistoryLink)`
  text-decoration: none;
  color: initial;
`

const TitleLink = styled(HistoryLink)`
  display: block;
  font-weight: 600;
  text-decoration: none;
  color: initial;
`
const ImageCell = styled(TableCell)`
  width: 60px;
`

const ProductImageCell = styled(TableCell)`
  padding: 4px;
`

const ProductImage = styled.div<{ src: string }>`
  height: 60px;
  width: 60px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  border-radius: 4px;
`

interface AuctionItemRowProps {
  auctionItem: AuctionItem
}

const AuctionItemRow: React.FC<AuctionItemRowProps> = ({ auctionItem }) => {
  const {
    id,
    auctionId,
    productId,
    product,
    startsAt,
    endsAt,
    startingPrice,
    maxBid,
    bidStep,
    updatedAt,
  } = auctionItem
  const { title, images, author } = product
  const { id: authorId, realName, pseudonym } = author || {}

  return (
    <TableRow>
      <ProductImageCell>
        <ProductImage src={images[0]?.thumbnail?.url || ""} />
      </ProductImageCell>
      <TableCell component="th" scope="row">
        <TitleLink to={`/product/${productId}`}>{title}</TitleLink>
        {authorId && (
          <StyledLink to={`/biography/${authorId}`}>
            {realName}
            {pseudonym && ` (${pseudonym})`}
          </StyledLink>
        )}
      </TableCell>
      <TableCell align="center">{`€${startingPrice}`}</TableCell>
      <TableCell align="center">{!!maxBid ? `€${maxBid.bid}` : "-"}</TableCell>
      <TableCell align="center">{`€${bidStep}`}</TableCell>
      <TableCell align="right">
        {startsAt && formatDateString(startsAt, "dd.MM.Y HH:mm")}
      </TableCell>
      <TableCell align="right">
        {endsAt && formatDateString(endsAt, "dd.MM.Y HH:mm")}
      </TableCell>
      <TableCell align="right">
        {formatDateString(updatedAt, "dd.MM.Y HH:mm")}
      </TableCell>
      <TableCell align="right">
        <SecondaryLink to={`/auction/${auctionId}/item/${id}`}>
          View
        </SecondaryLink>
      </TableCell>
    </TableRow>
  )
}

interface StoreProps {
  activeAuction: Auction
  auctionItems: Array<AuctionItem>
}

interface DispatchProps {
  fetchAuction: (id: string) => Promise<AsyncResult>
  fetchAuctionItems: (id: string) => Promise<AsyncResult>
  deleteAuction: (id: string) => Promise<AsyncResult>
  dispatchToast: (message: string, type: ToastType, timeout?: number) => string
}

interface AuctionPageProps extends StoreProps, DispatchProps {}

const AuctionPage: React.FC<AuctionPageProps> = ({
  activeAuction,
  auctionItems,
  fetchAuction,
  fetchAuctionItems,
  deleteAuction,
  dispatchToast,
}) => {
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()
  const { id } = useParams<{ id: string }>()

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  useEffect(() => {
    fetchAuction(id)
    fetchAuctionItems(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push("/auctions")
  }

  if (!activeAuction) return null

  const openDeleteConfirmation = () => setShowDeleteConfirmation(true)
  const closeDeleteConfirmation = () => setShowDeleteConfirmation(false)

  const onDeleteAuction = () =>
    deleteAuction(id)
      .then(() => {
        dispatchToast("Auction successfully deleted.", ToastType.Success, 8000)
        history.push("/auctions")
      })
      .catch(() => {
        dispatchToast(
          "Failed to delete auction. Please try again.",
          ToastType.Error,
          8000
        )
      })

  const { title, slug, description, createdAt, updatedAt } = activeAuction

  const mappedBids = auctionItems
    .reduce((allBids, auctionItem) => {
      const { bids, ...auctionItemData } = auctionItem
      return [
        ...allBids,
        ...(bids || []).map(bid => ({ ...bid, auctionItem: auctionItemData })),
      ]
    }, [] as Array<AuctionBid>)
    .sort((a, b) => (new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1))

  return (
    <PageWrapper>
      {showDeleteConfirmation && (
        <ConfirmationDialog
          title="Delete auction"
          description="Are you sure you want to delete this auction?"
          onSubmit={onDeleteAuction}
          onCancel={closeDeleteConfirmation}
        />
      )}

      <ActionBar>
        <Button onClick={goBack}>Back</Button>
        <PrimaryLink to={`/auction/${activeAuction.id}/edit`}>Edit</PrimaryLink>
        <PrimaryButton onClick={openDeleteConfirmation}>Delete</PrimaryButton>
      </ActionBar>

      <HoovusPaper>
        <PaperHeader>General</PaperHeader>
        <Grid columns="1fr 1fr" gap="0 20px">
          <PropertyItem label="Title">{title}</PropertyItem>
          <PropertyItem label="Slug">{slug}</PropertyItem>
        </Grid>
        <PropertyItem label="Description">{description}</PropertyItem>

        <Grid columns="1fr 1fr" gap="0 20px">
          <PropertyItem label="Updated at">
            {formatDateString(updatedAt, "dd.MM.Y HH:mm")}
          </PropertyItem>
          <PropertyItem label="Created at">
            {formatDateString(createdAt, "dd.MM.Y HH:mm")}
          </PropertyItem>
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>
          <span>Artworks</span>
          <PrimaryLink
            to={`/auction/${activeAuction.id}/item/new`}
            size="small"
          >
            Add new
          </PrimaryLink>
        </PaperHeader>

        <Table>
          <TableHead>
            <TableRow>
              <ImageCell>Image</ImageCell>
              <TableCell>Title / Author</TableCell>
              <TableCell width="90" align="center">
                Starting price
              </TableCell>
              <TableCell width="90" align="center">
                Max bid
              </TableCell>
              <TableCell width="90" align="center">
                Bid step
              </TableCell>
              <TableCell width="115" align="right">
                Starts at
              </TableCell>
              <TableCell width="115" align="right">
                Ends at
              </TableCell>
              <TableCell width="115" align="right">
                Updated
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auctionItems.map(auctionItem => (
              <AuctionItemRow key={auctionItem.id} auctionItem={auctionItem} />
            ))}
          </TableBody>
        </Table>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Bids</PaperHeader>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Artwork</TableCell>
              <TableCell>Bid</TableCell>
              <TableCell>Bidder</TableCell>
              <TableCell>Created at</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedBids.map(bid => (
              <TableRow key={bid.id}>
                <TableCell>{bid.auctionItem?.product.title}</TableCell>
                <TableCell>{bid.bid}</TableCell>
                <TableCell>
                  {bid.user && (
                    <SecondaryLink to={`/user/${bid.userId}`}>
                      {bid.user.firstName} {bid.user.lastName}
                    </SecondaryLink>
                  )}
                </TableCell>
                <TableCell>
                  {formatDateString(bid.createdAt, "dd.MM.Y HH:mm")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </HoovusPaper>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      activeAuction: state.auction.activeAuction,
      auctionItems: state.auction.auctionItems,
    }),
    {
      fetchAuction,
      fetchAuctionItems,
      deleteAuction,
      dispatchToast,
    }
  )
)(AuctionPage)
