import { TextField } from "@material-ui/core"
import styled from "styled-components"

const StyledInput = styled(TextField)`
  width: 100%;
  margin: 8px 0 !important;

  > label {
    transform: translate(12px, 18px) scale(1);
    font-size: 16px;

    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.8);
    }
  }

  .MuiInputBase-root {
    font-family: "Montserrat", sans-serif;
  }

  .MuiInputBase-input {
    font-size: 16px;
    padding: 16px 12px;
    line-height: 19px;
  }

  .MuiInputBase-root {
    padding: 0;
  }
`
export default StyledInput
