import React from "react"
import { DateTimePicker } from "@material-ui/pickers"

import styled from "styled-components"

const StyledDateTimePicker = styled(DateTimePicker)`
  width: 100%;
  margin: 8px 0 !important;

  > label {
    transform: translate(12px, 18px) scale(1);
    font-size: 16px;

    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.8);
    }
  }

  .MuiInputBase-root {
    font-family: "Montserrat", sans-serif;
  }

  .MuiInputBase-input {
    font-size: 16px;
    padding: 16px 12px;
    line-height: 19px;
  }

  .MuiInputBase-root {
    padding: 0;
  }
`
interface DateTimeProps {
  label: string
  value?: string
  error?: boolean
  helperText?: string
  minDate?: string
  onChange: (newDate: Date | null) => void
}

const DateTime: React.FC<DateTimeProps> = ({
  label,
  value,
  error,
  helperText,
  minDate,
  onChange,
}) => (
  <StyledDateTimePicker
    clearable
    label={label}
    minDate={minDate}
    inputVariant="outlined"
    format="dd.MM.Y HH:mm"
    ampm={false}
    value={value || null}
    onChange={onChange}
    error={error}
    helperText={helperText}
  />
)

export default DateTime
