import ApiClient from "../../core/ApiClient"
import Config from "../../core/Config"
import Status from "../../core/enums/Status"
import { Biography } from "../../core/models/Biography"
import User from "../../core/models/User"
import { AsyncAction, asyncType } from "../middleware/asyncMiddleware"

export interface UsersStore {
  users: Array<User>
  usersStatus: Status
  user?: User
  userStatus: Status
  userBiography?: Biography
  userBiographyStatus: Status
}

const initialState = {
  users: [] as Array<User>,
  usersStatus: Status.INITIAL,
  user: undefined,
  userStatus: Status.INITIAL,
  userBiography: undefined,
  userBiographyStatus: Status.INITIAL,
}

const FETCH_USERS = asyncType("redux.users.FETCH_USERS")
const FETCH_USER = asyncType("redux.users.FETCH_USER")
const FETCH_USER_BIOGRAPHY = asyncType("redux.users.FETCH_USER_BIOGRAPHY")

export default function reducer(
  state = initialState,
  action: AsyncAction
): UsersStore {
  switch (action.type) {
    case FETCH_USERS.INITIAL: {
      return {
        ...state,
        usersStatus: Status.LOADING,
      }
    }

    case FETCH_USERS.SUCCESS: {
      return {
        ...state,
        users: action.result?.body as Array<User>,
        usersStatus: Status.SUCCESS,
      }
    }

    case FETCH_USERS.FAIL: {
      return {
        ...state,
        usersStatus: Status.FAIL,
      }
    }

    case FETCH_USER.INITIAL: {
      return {
        ...state,
        userStatus: Status.LOADING,
      }
    }

    case FETCH_USER.SUCCESS: {
      return {
        ...state,
        user: action.result?.body as User,
        userStatus: Status.SUCCESS,
      }
    }

    case FETCH_USER.FAIL: {
      return {
        ...state,
        userStatus: Status.FAIL,
      }
    }

    case FETCH_USER_BIOGRAPHY.INITIAL: {
      return {
        ...state,
        userBiographyStatus: Status.LOADING,
      }
    }

    case FETCH_USER_BIOGRAPHY.SUCCESS: {
      return {
        ...state,
        userBiography: action.result?.body as Biography,
        userBiographyStatus: Status.SUCCESS,
      }
    }

    case FETCH_USER_BIOGRAPHY.FAIL: {
      return {
        ...state,
        userBiographyStatus: Status.FAIL,
      }
    }

    default:
      return state
  }
}

export function fetchUsers() {
  return {
    types: FETCH_USERS,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/users`),
  }
}

export function fetchUser(userId: string) {
  return {
    types: FETCH_USER,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/users/${userId}`),
  }
}

export function fetchUserBiography(userId: string) {
  return {
    types: FETCH_USER_BIOGRAPHY,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/biography/user/${userId}`),
  }
}
