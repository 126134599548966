import { Button } from "@material-ui/core"
import React, { useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { compose } from "redux"

import ActionBar from "../../components/ActionBar"
import BiographyImageUpload from "../../components/BiographyImageUpload"
import { PrimaryButton } from "../../components/Buttons"
import BiographyForm from "../../components/Forms/BiographyForm"
import Grid from "../../components/Grid"
import { HistoryLocation } from "../../components/HistoryLink"
import { UploadedImage } from "../../components/ImageUpload"
import { PageWrapper } from "../../components/Layout"
import Config from "../../core/Config"
import Status from "../../core/enums/Status"
import FormErrors from "../../core/errors/FormError"
import { Biography, NewBiography } from "../../core/models/Biography"
import privateRoute from "../../hoc/privateRoute"
import { AsyncResult } from "../../redux/middleware/asyncMiddleware"
import {
  createBiography,
  uploadProfilePicture,
} from "../../redux/modules/biography"
import { dispatchToast, ToastType } from "../../redux/modules/toasts"
import { StoreState } from "../../redux/reducer"

interface StoreProps {
  biographyStatus: Status
}

interface DispatchProps {
  createBiography: (newBiography: NewBiography) => Promise<AsyncResult>
  uploadProfilePicture: (
    biographyId: string,
    uploadedFile: File
  ) => Promise<AsyncResult>
  dispatchToast: (message: string, type: ToastType, timeout?: number) => string
}

interface NewBiographyPageProps extends StoreProps, DispatchProps {}

const NewBiographyPage: React.FC<NewBiographyPageProps> = ({
  biographyStatus,
  createBiography,
  uploadProfilePicture,
  dispatchToast,
}) => {
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()

  const [uploadedImage, setUploadedImage] = useState<UploadedImage>()
  const [errors, setErrors] = useState<FormErrors>({})
  const [newBiography, setNewBiography] = useState<NewBiography>({})

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push("/biographies")
  }

  const onCreate = () => {
    createBiography(newBiography)
      .then(biographyResponse => {
        if (uploadedImage) {
          uploadProfilePicture(biographyResponse.body.id, uploadedImage.file)
            .then(goBack)
            .catch(() => {
              dispatchToast(
                "Whoops, failed to upload images. Please try again.",
                ToastType.Error,
                8000
              )
            })
        } else {
          goBack()
        }
      })
      .catch(errorResponse => {
        if (errorResponse?.body?.name === "ValidationError") {
          dispatchToast(
            "Errors found in your form. Please make sure you have filled all mandatory fields.",
            ToastType.Error,
            8000
          )
          setErrors(errorResponse?.body?.errors)
        } else {
          dispatchToast(
            "Whoops, something went wrong. Please try again.",
            ToastType.Error,
            8000
          )
        }
      })
  }

  function onAddImages(uploadedImages: Array<UploadedImage>) {
    const [imageToUpload] = uploadedImages

    if (imageToUpload.fileSize >= Config.uploads.maxImgSizeMB) {
      dispatchToast(
        "Whoops, selected image is too big! Try to compress your image below 10MBs.",
        ToastType.Error,
        8000
      )
    } else {
      setUploadedImage(imageToUpload)
    }
  }

  const onBiographyChange = (updatedBiography: NewBiography) =>
    setNewBiography(updatedBiography as Biography)

  return (
    <PageWrapper>
      <ActionBar>
        <Button onClick={goBack}>Back</Button>
        <PrimaryButton
          onClick={onCreate}
          disabled={biographyStatus === Status.LOADING}
        >
          Create
        </PrimaryButton>
      </ActionBar>

      <Grid columns="1fr 300px" gap="32px" align="start">
        <BiographyForm
          biography={newBiography}
          errors={errors}
          onChange={onBiographyChange}
        />
        <BiographyImageUpload
          uploadedImage={uploadedImage}
          onAddImages={onAddImages}
        />
      </Grid>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      biographyStatus: state.biography.biographyStatus,
    }),
    {
      createBiography,
      dispatchToast,
      uploadProfilePicture,
    }
  )
)(NewBiographyPage)
