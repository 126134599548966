import { applyMiddleware, createStore as _createStore } from "redux"

import ApiClient from "../core/ApiClient"
import asyncMiddleware from "./middleware/asyncMiddleware"
import reducer from "./reducer"

export default function createStore(apiClient: ApiClient, data = {}) {
  const middlewares = [asyncMiddleware(apiClient)]
  return applyMiddleware(...middlewares)(_createStore)(reducer, data)
}
