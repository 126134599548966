export class PaginatedData<T> {
  pages = new Map<number, Array<T>>()
  total: number = 0

  constructor(pages: Map<number, Array<T>>, total: number) {
    this.pages = pages
    this.total = total
  }

  getPageData(page: number) {
    return this.pages.get(page) || []
  }

  getTotalPages(pageSize: number) {
    return Math.ceil(this.total / pageSize)
  }
}
