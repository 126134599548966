import ApiClient from "../../core/ApiClient"
import Config from "../../core/Config"
import Status from "../../core/enums/Status"
import { PaginatedData } from "../../core/models/PaginatedData"
import Product, { NewProduct } from "../../core/models/Product"
import ProductFilter from "../../core/models/ProductFilter"
import { buildFilterQueryParams } from "../../utils/ApiUtils"
import { AsyncAction, asyncType } from "../middleware/asyncMiddleware"

export interface ProductStore {
  activeProduct: Product | null
  activeProductStatus: Status

  products: PaginatedData<Product>
  productsStatus: Status

  userProducts: Array<Product>
  userProductsStatus: Status

  biographyProducts: Array<Product>
  biographyProductsStatus: Status

  imageUploadStatus: Status
}

const initialState = {
  activeProduct: null,
  activeProductStatus: Status.INITIAL,

  products: new PaginatedData<Product>(new Map(), 0),
  productsStatus: Status.INITIAL,

  userProducts: [] as Array<Product>,
  userProductsStatus: Status.INITIAL,

  biographyProducts: [] as Array<Product>,
  biographyProductsStatus: Status.INITIAL,

  imageUploadStatus: Status.INITIAL,
}

const FETCH_PRODUCT = asyncType("redux.product.FETCH_PRODUCT")
const FETCH_PRODUCTS = asyncType("redux.product.FETCH_PRODUCTS")
const FETCH_USER_PRODUCTS = asyncType("redux.product.FETCH_USER_PRODUCTS")
const FETCH_BIOGRAPHY_PRODUCTS = asyncType(
  "redux.product.FETCH_BIOGRAPHY_PRODUCTS"
)
const RESET_PRODUCTS_PAGINATION = "redux.product.RESET_PRODUCTS_PAGINATION"
const CREATE_PRODUCT = asyncType("redux.product.CREATE_PRODUCT")
const UPDATE_PRODUCT = asyncType("redux.product.UPDATE_PRODUCT")
const DELETE_PRODUCT = asyncType("redux.product.DELETE_PRODUCT")
const UPLOAD_PRODUCT_PHOTOS = asyncType("redux.product.UPLOAD_PRODUCT_PHOTOS")

const CLEAR_USER_PRODUCTS = "redux.product.CLEAR_USER_PRODUCTS"
const CLEAR_BIOGRAPHY_PRODUCTS = "redux.product.CLEAR_BIOGRAPHY_PRODUCTS"

export default function reducer(
  state = initialState,
  action: AsyncAction
): ProductStore {
  switch (action.type) {
    case FETCH_PRODUCT.INITIAL: {
      return {
        ...state,
        activeProductStatus: Status.LOADING,
      }
    }

    case FETCH_PRODUCT.SUCCESS: {
      return {
        ...state,
        activeProduct: action.result?.body as Product,
        activeProductStatus: Status.SUCCESS,
      }
    }

    case FETCH_PRODUCT.FAIL: {
      return {
        ...state,
        activeProductStatus: Status.FAIL,
      }
    }

    case FETCH_PRODUCTS.INITIAL: {
      return {
        ...state,
        productsStatus: Status.LOADING,
      }
    }

    case FETCH_PRODUCTS.SUCCESS: {
      const { total, data } = action.result?.body

      const newPagesMap = new Map(state.products.pages || null)
      newPagesMap.set(action.page, data)

      return {
        ...state,
        products: new PaginatedData<Product>(newPagesMap, total),
        productsStatus: Status.SUCCESS,
      }
    }

    case FETCH_PRODUCTS.FAIL: {
      return {
        ...state,
        productsStatus: Status.FAIL,
      }
    }

    case RESET_PRODUCTS_PAGINATION: {
      return {
        ...state,
        products: new PaginatedData<Product>(new Map(), 0),
        productsStatus: Status.INITIAL,
      }
    }

    case FETCH_USER_PRODUCTS.INITIAL: {
      return {
        ...state,
        userProducts: [],
        userProductsStatus: Status.LOADING,
      }
    }

    case FETCH_USER_PRODUCTS.SUCCESS: {
      return {
        ...state,
        userProducts: action.result?.body as Array<Product>,
        userProductsStatus: Status.SUCCESS,
      }
    }

    case FETCH_USER_PRODUCTS.FAIL: {
      return {
        ...state,
        userProductsStatus: Status.FAIL,
      }
    }

    case FETCH_BIOGRAPHY_PRODUCTS.INITIAL: {
      return {
        ...state,
        biographyProducts: [],
        biographyProductsStatus: Status.LOADING,
      }
    }

    case FETCH_BIOGRAPHY_PRODUCTS.SUCCESS: {
      return {
        ...state,
        biographyProducts: action.result?.body as Array<Product>,
        biographyProductsStatus: Status.SUCCESS,
      }
    }

    case FETCH_BIOGRAPHY_PRODUCTS.FAIL: {
      return {
        ...state,
        biographyProductsStatus: Status.FAIL,
      }
    }

    case UPDATE_PRODUCT.INITIAL:
    case CREATE_PRODUCT.INITIAL: {
      return {
        ...state,
        activeProductStatus: Status.LOADING,
      }
    }

    case UPDATE_PRODUCT.SUCCESS:
    case CREATE_PRODUCT.SUCCESS: {
      return {
        ...state,
        activeProduct: action.result?.body as Product,
        activeProductStatus: Status.SUCCESS,
      }
    }

    case UPDATE_PRODUCT.FAIL:
    case CREATE_PRODUCT.FAIL: {
      return {
        ...state,
        activeProductStatus: Status.FAIL,
      }
    }

    case DELETE_PRODUCT.INITIAL: {
      return {
        ...state,
        activeProductStatus: Status.LOADING,
      }
    }

    case DELETE_PRODUCT.SUCCESS: {
      return {
        ...state,
        activeProductStatus: Status.SUCCESS,
        activeProduct: null,
      }
    }

    case DELETE_PRODUCT.FAIL: {
      return {
        ...state,
        activeProductStatus: Status.FAIL,
      }
    }

    case CLEAR_USER_PRODUCTS: {
      return {
        ...state,
        userProducts: [] as Array<Product>,
        userProductsStatus: Status.INITIAL,
      }
    }

    case CLEAR_BIOGRAPHY_PRODUCTS: {
      return {
        ...state,
        biographyProducts: [] as Array<Product>,
        biographyProductsStatus: Status.INITIAL,
      }
    }

    case UPLOAD_PRODUCT_PHOTOS.INITIAL: {
      return {
        ...state,
        imageUploadStatus: Status.INITIAL,
      }
    }

    case UPLOAD_PRODUCT_PHOTOS.SUCCESS: {
      return {
        ...state,
        imageUploadStatus: Status.SUCCESS,
      }
    }

    case UPLOAD_PRODUCT_PHOTOS.FAIL: {
      return {
        ...state,
        imageUploadStatus: Status.FAIL,
      }
    }

    default:
      return state
  }
}

export function fetchProduct(id: string) {
  return {
    types: FETCH_PRODUCT,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/product/${id}`),
  }
}

export function fetchProducts(filter: ProductFilter) {
  const query = buildFilterQueryParams(filter)

  return {
    types: FETCH_PRODUCTS,
    promise: (client: ApiClient) =>
      client.get(
        `${Config.app.apiUrl}/admin/product/filtered?${query.join("&")}`
      ),
    page: filter.page,
  }
}

export function fetchUserProducts(userId: string) {
  return {
    types: FETCH_USER_PRODUCTS,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/product/user/${userId}`),
  }
}

export function fetchBiographyProducts(biographyId: string) {
  return {
    types: FETCH_BIOGRAPHY_PRODUCTS,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/product/biography/${biographyId}`),
  }
}

export function resetProducts() {
  return {
    type: RESET_PRODUCTS_PAGINATION,
  }
}

export function createProduct(newProduct: NewProduct) {
  return {
    types: CREATE_PRODUCT,
    promise: (client: ApiClient) =>
      client.post(`${Config.app.apiUrl}/admin/product`, {
        body: JSON.stringify(newProduct),
      }),
  }
}

export function updateProduct(productToUpdate: Product) {
  return {
    types: UPDATE_PRODUCT,
    promise: (client: ApiClient) =>
      client.put(`${Config.app.apiUrl}/admin/product`, {
        body: JSON.stringify(productToUpdate),
      }),
  }
}

export function deleteProduct(id: string) {
  return {
    types: DELETE_PRODUCT,
    promise: (client: ApiClient) =>
      client.del(`${Config.app.apiUrl}/admin/product/${id}`),
  }
}

export function clearUserProducts() {
  return {
    type: CLEAR_USER_PRODUCTS,
  }
}

export function clearBiographyProducts() {
  return {
    type: CLEAR_BIOGRAPHY_PRODUCTS,
  }
}

export function uploadProductPhotos(productId: string, files: Array<File>) {
  return {
    types: UPLOAD_PRODUCT_PHOTOS,
    promise: (client: ApiClient) =>
      client.put(
        `${Config.app.apiUrl}/admin/product/${productId}/upload/images`,
        {
          headers: {},
          body: files.reduce((formData, file) => {
            formData.append(`files`, file)
            return formData
          }, new FormData()),
        }
      ),
  }
}
