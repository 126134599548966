import set from "lodash/fp/set"
import React from "react"
import styled from "styled-components"

import FormErrors from "../../core/errors/FormError"
import Auction, { NewAuction } from "../../core/models/Auction"
import Grid from "../Grid"
import HoovusPaper, { PaperHeader } from "../HoovusPaper"
import StyledInput from "../Input/StyledInput"

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

interface AuctionFormProps {
  auction: Auction | NewAuction
  errors?: FormErrors
  onChange: (updatedAuction: NewAuction) => void
}

const AuctionForm: React.FC<AuctionFormProps> = ({
  auction,
  errors = {},
  onChange,
}) => {
  const onStringChange = (path: string, nullable = false) => ({
    target: { value = "" },
  }) => {
    onChange(set(path, value || (nullable ? null : undefined), auction))
  }

  const { title, description, slug } = auction

  return (
    <FormWrapper>
      <HoovusPaper>
        <PaperHeader>General</PaperHeader>

        <Grid columns="1fr 1fr" gap="20px">
          <StyledInput
            label="Title *"
            variant="outlined"
            value={title || ""}
            onChange={onStringChange("title", true)}
            error={!!errors.title}
            helperText={errors.title}
          />

          <StyledInput
            label="Slug *"
            variant="outlined"
            value={slug || ""}
            onChange={onStringChange("slug", true)}
            error={!!errors.slug}
            helperText={errors.slug}
          />
        </Grid>

        <StyledInput
          label="Description *"
          variant="outlined"
          multiline
          rows={4}
          value={description || ""}
          onChange={onStringChange("description")}
          error={!!errors.description}
          helperText={errors.description}
        />
      </HoovusPaper>
    </FormWrapper>
  )
}

export default AuctionForm
