import React from "react"
import { FormControlLabel, Radio } from "@material-ui/core"
import styled from "styled-components"

const StyledRadioButton = styled(FormControlLabel)`
  .MuiTypography-root {
    display: block;
    font-family: "Montserrat";
    font-size: 16px;
  }
`

const StyledRadio = styled(Radio)`
  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }
`
interface RadioButtonProps {
  label: string
  value: any
}

const RadioButton: React.FC<RadioButtonProps> = ({ label, value }) => {
  return (
    <StyledRadioButton value={value} control={<StyledRadio />} label={label} />
  )
}

export default RadioButton
