import "./layout.css"

import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import App from "./App"
import ApiClient from "./core/ApiClient"
import createStore from "./redux/createStore"

const client = new ApiClient()
const store = createStore(client, {})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)
