import React from "react"
import { Link, useLocation } from "react-router-dom"

export interface HistoryLocation {
  prevPath?: string
}

interface HistoryLinkProps {
  to: string
}

const HistoryLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithRef<React.FC<HistoryLinkProps>>
>(({ to, children, ...props }, ref) => {
  const location = useLocation()

  return (
    <Link
      ref={ref}
      to={{
        pathname: to,
        state: { prevPath: location.pathname },
      }}
      {...props}
    >
      {children}
    </Link>
  )
})

export default HistoryLink
