import { Button, ButtonGroup, ListItem, ListItemText } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import styled from "styled-components"

import Status from "../core/enums/Status"
import { BiographySearch, UserSearch } from "../core/models/Search"
import privateRoute from "../hoc/privateRoute"
import useThrottle from "../hooks/useThrottle"
import { AsyncResult } from "../redux/middleware/asyncMiddleware"
import {
  clearBiographySearch,
  clearUserSearch,
  searchBiography,
  searchUser,
} from "../redux/modules/search"
import { StoreState } from "../redux/reducer"
import StyledInput from "./Input/StyledInput"

const SearchResult = styled(ListItem)<any>`
  &.MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiListItemText-primary {
    font-size: 14px;
  }

  .MuiListItemText-secondary {
    font-size: 12px;
  }
`

const StyledTypeSelect = styled(ButtonGroup)`
  &.MuiButtonGroup-root {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const SearchResults = styled.div`
  max-height: 500px;
  overflow-y: auto;
`

export enum SearchType {
  User,
  Biography,
}

interface StoreProps {
  biographySearch: Array<BiographySearch>
  biographySearchStatus: Status
  userSearch: Array<UserSearch>
  userSearchStatus: Status
}

interface DispatchProps {
  searchBiography: (search: string) => Promise<AsyncResult>
  searchUser: (search: string) => Promise<AsyncResult>
  clearBiographySearch: () => void
  clearUserSearch: () => void
}

interface OwnProps {
  onSelect: (type: SearchType, id: string) => void
}

interface TypeSearchProps extends OwnProps, StoreProps, DispatchProps {}

const TypeSearch: React.FC<TypeSearchProps> = ({
  biographySearch,
  userSearch,
  onSelect,
  searchBiography,
  searchUser,
  clearBiographySearch,
  clearUserSearch,
  ...props
}) => {
  const [searchType, setSearchType] = useState<SearchType>(SearchType.User)
  const [search, setSearch] = useState("")

  const onSearchThrottledCB = useThrottle(
    (searchString: string, searchType: SearchType) => {
      switch (searchType) {
        case SearchType.User:
          return searchUser(searchString)
        case SearchType.Biography:
          return searchBiography(searchString)
      }
    },
    1000
  )

  const onSearchChange = (e: any) => {
    const { value: searchString } = e.target
    setSearch(searchString)
    onSearchThrottledCB(searchString, searchType)
  }

  useEffect(() => {
    return clearSearches
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearSearches = () => {
    clearBiographySearch()
    clearUserSearch()
  }

  const onClickSearchType = (newSearchType: SearchType) => () => {
    setSearchType(newSearchType)
    onSearchThrottledCB(search, newSearchType)
  }

  const onSelectUser = (userId: string) => () =>
    onSelect(SearchType.User, userId)

  const onSelectBiography = (biographyId: string) => () =>
    onSelect(SearchType.Biography, biographyId)

  return (
    <div>
      <StyledTypeSelect disableElevation variant="contained">
        <Button
          onClick={onClickSearchType(SearchType.User)}
          color={searchType === SearchType.User ? "primary" : "default"}
        >
          User
        </Button>
        <Button
          onClick={onClickSearchType(SearchType.Biography)}
          color={searchType === SearchType.Biography ? "primary" : "default"}
        >
          Biography
        </Button>
      </StyledTypeSelect>

      <StyledInput
        value={search}
        onChange={onSearchChange}
        placeholder="Search"
      />

      <SearchResults>
        {searchType === SearchType.User &&
          userSearch.map(user => (
            <SearchResult key={user.id} button onClick={onSelectUser(user.id)}>
              <ListItemText
                primary={`${user.fullName} (${user.roles.join(", ")})`}
                secondary={user.email}
              />
            </SearchResult>
          ))}

        {searchType === SearchType.Biography &&
          biographySearch.map(biography => (
            <SearchResult
              key={biography.id}
              button
              onClick={onSelectBiography(biography.id)}
            >
              <ListItemText
                primary={biography.realName || "-"}
                secondary={biography.pseudonym || "-"}
              />
            </SearchResult>
          ))}
      </SearchResults>
    </div>
  )
}

export default compose<React.FC<OwnProps> & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      biographySearch: state.search.biographySearch,
      biographySearchStatus: state.search.biographySearchStatus,
      userSearch: state.search.userSearch,
      userSearchStatus: state.search.userSearchStatus,
    }),
    {
      searchBiography,
      clearBiographySearch,
      searchUser,
      clearUserSearch,
    }
  )
)(TypeSearch)
