import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { compose } from "redux"

import ActionBar from "../../components/ActionBar"
import {
  PrimaryButton,
  PrimaryLink,
  SecondaryLink,
} from "../../components/Buttons"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import Grid from "../../components/Grid"
import { HistoryLocation } from "../../components/HistoryLink"
import HoovusPaper, { PaperHeader } from "../../components/HoovusPaper"
import { PageWrapper } from "../../components/Layout"
import ProductGridItem from "../../components/ProductGridItem"
import PropertyItem from "../../components/PropertyItem"
import AuctionItem from "../../core/models/AuctionItem"
import privateRoute from "../../hoc/privateRoute"
import { AsyncResult } from "../../redux/middleware/asyncMiddleware"
import {
  deleteAuctionItem,
  fetchAuctionItem,
} from "../../redux/modules/auction"
import { dispatchToast, ToastType } from "../../redux/modules/toasts"
import { StoreState } from "../../redux/reducer"
import { formatDateString } from "../../utils/DateUtils"

interface StoreProps {
  activeAuctionItem: AuctionItem
}

interface DispatchProps {
  fetchAuctionItem: (id: string) => Promise<AsyncResult>
  deleteAuctionItem: (id: string) => Promise<AsyncResult>
  dispatchToast: (message: string, type: ToastType, timeout?: number) => string
}

interface NewAuctionItemPageProps extends StoreProps, DispatchProps {}

const NewAuctionItemPage: React.FC<NewAuctionItemPageProps> = ({
  activeAuctionItem,
  fetchAuctionItem,
  deleteAuctionItem,
  dispatchToast,
}) => {
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()
  const { auctionId, id } = useParams<{ auctionId: string; id: string }>()

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  useEffect(() => {
    fetchAuctionItem(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openDeleteConfirmation = () => setShowDeleteConfirmation(true)
  const closeDeleteConfirmation = () => setShowDeleteConfirmation(false)

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push(`/auction/${auctionId}`)
  }

  const onDeleteAuctionItem = () =>
    deleteAuctionItem(id)
      .then(() => {
        dispatchToast(
          "Auction item successfully deleted.",
          ToastType.Success,
          8000
        )
        history.push(`/auction/${auctionId}`)
      })
      .catch(() => {
        dispatchToast(
          "Failed to delete auction item. Please try again.",
          ToastType.Error,
          8000
        )
      })

  if (!activeAuctionItem) return null

  const {
    startingPrice,
    bidStep,
    startsAt,
    endsAt,
    createdAt,
    updatedAt,
    product,
    bids = [],
  } = activeAuctionItem

  const [maxBid] = bids

  return (
    <PageWrapper>
      {showDeleteConfirmation && (
        <ConfirmationDialog
          title="Delete auction item"
          description="Are you sure you want to delete this auction item?"
          onSubmit={onDeleteAuctionItem}
          onCancel={closeDeleteConfirmation}
        />
      )}

      <ActionBar>
        <Button onClick={goBack}>Back</Button>
        <PrimaryLink to={`/auction/${auctionId}/item/${id}/edit`}>
          Edit
        </PrimaryLink>
        <PrimaryButton onClick={openDeleteConfirmation}>Delete</PrimaryButton>
      </ActionBar>

      <Grid columns="1fr 400px" gap="32px">
        <HoovusPaper>
          <PaperHeader>General</PaperHeader>
          <Grid columns="1fr 1fr" gap="20px">
            <PropertyItem label="Starting price">€{startingPrice}</PropertyItem>
            <PropertyItem label="Bid step">€{bidStep}</PropertyItem>
            <PropertyItem label="Starts at">
              {startsAt && formatDateString(startsAt, "dd.MM.Y HH:mm")}
            </PropertyItem>
            <PropertyItem label="Ends at">
              {endsAt && formatDateString(endsAt, "dd.MM.Y HH:mm")}
            </PropertyItem>
            {maxBid && (
              <>
                <PropertyItem label="Max bid">{maxBid.bid}</PropertyItem>
                <PropertyItem label="Max bid owner">
                  <SecondaryLink to={`/user/${maxBid.userId}`}>
                    {maxBid.user?.firstName} {maxBid.user?.lastName}
                  </SecondaryLink>
                </PropertyItem>
              </>
            )}
            <PropertyItem label="Created at">
              {formatDateString(createdAt, "dd.MM.Y HH:mm")}
            </PropertyItem>
            <PropertyItem label="Updated at">
              {formatDateString(updatedAt, "dd.MM.Y HH:mm")}
            </PropertyItem>
          </Grid>
        </HoovusPaper>
        <HoovusPaper>
          <PaperHeader>Artwork</PaperHeader>
          {product && (
            <ProductGridItem
              title={product.title}
              image={product.images[0]?.thumbnail?.url}
            />
          )}
        </HoovusPaper>
      </Grid>

      <HoovusPaper>
        <PaperHeader>Bids</PaperHeader>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Bid</TableCell>
              <TableCell>Bidder</TableCell>
              <TableCell>Created at</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bids.map(bid => (
              <TableRow key={bid.id}>
                <TableCell>{bid.bid}</TableCell>
                <TableCell>
                  {bid.user && (
                    <SecondaryLink to={`/user/${bid.userId}`}>
                      {bid.user?.firstName} {bid.user?.lastName}
                    </SecondaryLink>
                  )}
                </TableCell>
                <TableCell>
                  {formatDateString(bid.createdAt, "dd.MM.Y HH:mm")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </HoovusPaper>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      activeAuctionItem: state.auction.activeAuctionItem,
    }),
    {
      fetchAuctionItem,
      deleteAuctionItem,
      dispatchToast,
    }
  )
)(NewAuctionItemPage)
