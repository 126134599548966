import { MenuItem } from "@material-ui/core"
import { set } from "lodash/fp"
import React from "react"
import Translations from "../../core/Translations"
import Location from "../../core/models/Location"
import FormErrors from "../../core/errors/FormError"
import Grid from "../Grid"
import StyledInput from "../Input/StyledInput"
import Country from "../../core/enums/Country"

const COUNTRY_SELECTION = Object.entries(Translations.Enum.Country)

interface LocationFormProps {
  location: Location
  errors: FormErrors
  onChange: (newLocation: Location) => void
}

const LocationForm: React.FC<LocationFormProps> = ({
  location,
  errors,
  onChange,
}) => {
  const onStringChange = (path: string) => ({ target: { value = "" } }) => {
    onChange(set(path, value || undefined, location))
  }

  const onSelectChange = (path: string, type: any) => ({
    target: { value = "" },
  }) => {
    onChange(set(path, value ? (value as typeof type) : undefined, location))
  }

  const {
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    country,
    phone,
    instructions,
  } = location || {}

  return (
    <>
      <Grid columns="1fr 1fr" gap="0 16px">
        <StyledInput
          variant="outlined"
          label="Address line 1 *"
          value={addressLine1 || ""}
          onChange={onStringChange("addressLine1")}
          error={!!errors.addressLine1}
          helperText={errors.addressLine1}
        />
        <StyledInput
          variant="outlined"
          label="Address line 2"
          value={addressLine2 || ""}
          onChange={onStringChange("addressLine2")}
          error={!!errors.addressLine2}
          helperText={errors.addressLine2}
        />
        <StyledInput
          variant="outlined"
          label="City *"
          value={city || ""}
          onChange={onStringChange("city")}
          error={!!errors.city}
          helperText={errors.city}
        />
        <StyledInput
          variant="outlined"
          label="State"
          value={state || ""}
          onChange={onStringChange("state")}
          error={!!errors.state}
          helperText={errors.state}
        />
        <StyledInput
          variant="outlined"
          label="Postcode/zipcode *"
          value={postalCode || ""}
          onChange={onStringChange("postalCode")}
          error={!!errors.postalCode}
          helperText={errors.postalCode}
        />
        <StyledInput
          select
          variant="outlined"
          label="Country *"
          value={country || ""}
          onChange={onSelectChange("country", Country)}
          error={!!errors.country}
          helperText={errors.country}
        >
          <MenuItem value={""}>
            <em>None</em>
          </MenuItem>
          {COUNTRY_SELECTION.map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </StyledInput>
      </Grid>

      <StyledInput
        variant="outlined"
        label="Phone *"
        value={phone || ""}
        onChange={onStringChange("phone")}
        error={!!errors.phone}
        helperText={errors.phone}
      />

      <StyledInput
        variant="outlined"
        label="Additional information"
        multiline
        rows={4}
        value={instructions || ""}
        onChange={onStringChange("instructions")}
      />
    </>
  )
}

export default LocationForm
