enum Country {
  AF = "AF",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  CV = "CV",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CG = "CG",
  CD = "CD",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  VA = "VA",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  MK = "MK",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SZ = "SZ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  US = "US",
  UM = "UM",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW",
  AX = "AX",
  BQ = "BQ",
  CW = "CW",
  GG = "GG",
  IM = "IM",
  JE = "JE",
  ME = "ME",
  BL = "BL",
  MF = "MF",
  RS = "RS",
  SX = "SX",
  SS = "SS",
  XK = "XK",
}

export default Country

export const CountryTranslations = {
  [Country.AF]: "Afghanistan",
  [Country.AL]: "Albania",
  [Country.DZ]: "Algeria",
  [Country.AS]: "American Samoa",
  [Country.AD]: "Andorra",
  [Country.AO]: "Angola",
  [Country.AI]: "Anguilla",
  [Country.AQ]: "Antarctica",
  [Country.AG]: "Antigua and Barbuda",
  [Country.AR]: "Argentina",
  [Country.AM]: "Armenia",
  [Country.AW]: "Aruba",
  [Country.AU]: "Australia",
  [Country.AT]: "Austria",
  [Country.AZ]: "Azerbaijan",
  [Country.BS]: "Bahamas",
  [Country.BH]: "Bahrain",
  [Country.BD]: "Bangladesh",
  [Country.BB]: "Barbados",
  [Country.BY]: "Belarus",
  [Country.BE]: "Belgium",
  [Country.BZ]: "Belize",
  [Country.BJ]: "Benin",
  [Country.BM]: "Bermuda",
  [Country.BT]: "Bhutan",
  [Country.BO]: "Bolivia",
  [Country.BA]: "Bosnia and Herzegovina",
  [Country.BW]: "Botswana",
  [Country.BV]: "Bouvet Island",
  [Country.BR]: "Brazil",
  [Country.IO]: "British Indian Ocean Territory",
  [Country.BN]: "Brunei Darussalam",
  [Country.BG]: "Bulgaria",
  [Country.BF]: "Burkina Faso",
  [Country.BI]: "Burundi",
  [Country.KH]: "Cambodia",
  [Country.CM]: "Cameroon",
  [Country.CA]: "Canada",
  [Country.CV]: "Cape Verde",
  [Country.KY]: "Cayman Islands",
  [Country.CF]: "Central African Republic",
  [Country.TD]: "Chad",
  [Country.CL]: "Chile",
  [Country.CN]: "China",
  [Country.CX]: "Christmas Island",
  [Country.CC]: "Cocos (Keeling) Islands",
  [Country.CO]: "Colombia",
  [Country.KM]: "Comoros",
  [Country.CG]: "Congo",
  [Country.CD]: "Congo, the Democratic Republic of the",
  [Country.CK]: "Cook Islands",
  [Country.CR]: "Costa Rica",
  [Country.CI]: "Cote D'Ivoire",
  [Country.HR]: "Croatia",
  [Country.CU]: "Cuba",
  [Country.CY]: "Cyprus",
  [Country.CZ]: "Czech Republic",
  [Country.DK]: "Denmark",
  [Country.DJ]: "Djibouti",
  [Country.DM]: "Dominica",
  [Country.DO]: "Dominican Republic",
  [Country.EC]: "Ecuador",
  [Country.EG]: "Egypt",
  [Country.SV]: "El Salvador",
  [Country.GQ]: "Equatorial Guinea",
  [Country.ER]: "Eritrea",
  [Country.EE]: "Estonia",
  [Country.ET]: "Ethiopia",
  [Country.FK]: "Falkland Islands (Malvinas)",
  [Country.FO]: "Faroe Islands",
  [Country.FJ]: "Fiji",
  [Country.FI]: "Finland",
  [Country.FR]: "France",
  [Country.GF]: "French Guiana",
  [Country.PF]: "French Polynesia",
  [Country.TF]: "French Southern Territories",
  [Country.GA]: "Gabon",
  [Country.GM]: "Gambia",
  [Country.GE]: "Georgia",
  [Country.DE]: "Germany",
  [Country.GH]: "Ghana",
  [Country.GI]: "Gibraltar",
  [Country.GR]: "Greece",
  [Country.GL]: "Greenland",
  [Country.GD]: "Grenada",
  [Country.GP]: "Guadeloupe",
  [Country.GU]: "Guam",
  [Country.GT]: "Guatemala",
  [Country.GN]: "Guinea",
  [Country.GW]: "Guinea-Bissau",
  [Country.GY]: "Guyana",
  [Country.HT]: "Haiti",
  [Country.HM]: "Heard Island and McDonald Islands",
  [Country.VA]: "Holy See (Vatican City State)",
  [Country.HN]: "Honduras",
  [Country.HK]: "Hong Kong",
  [Country.HU]: "Hungary",
  [Country.IS]: "Iceland",
  [Country.IN]: "India",
  [Country.ID]: "Indonesia",
  [Country.IR]: "Iran, Islamic Republic of",
  [Country.IQ]: "Iraq",
  [Country.IE]: "Ireland",
  [Country.IL]: "Israel",
  [Country.IT]: "Italy",
  [Country.JM]: "Jamaica",
  [Country.JP]: "Japan",
  [Country.JO]: "Jordan",
  [Country.KZ]: "Kazakhstan",
  [Country.KE]: "Kenya",
  [Country.KI]: "Kiribati",
  [Country.KP]: "North Korea",
  [Country.KR]: "South Korea",
  [Country.KW]: "Kuwait",
  [Country.KG]: "Kyrgyzstan",
  [Country.LA]: "Lao People's Democratic Republic",
  [Country.LV]: "Latvia",
  [Country.LB]: "Lebanon",
  [Country.LS]: "Lesotho",
  [Country.LR]: "Liberia",
  [Country.LY]: "Libya",
  [Country.LI]: "Liechtenstein",
  [Country.LT]: "Lithuania",
  [Country.LU]: "Luxembourg",
  [Country.MO]: "Macao",
  [Country.MG]: "Madagascar",
  [Country.MW]: "Malawi",
  [Country.MY]: "Malaysia",
  [Country.MV]: "Maldives",
  [Country.ML]: "Mali",
  [Country.MT]: "Malta",
  [Country.MH]: "Marshall Islands",
  [Country.MQ]: "Martinique",
  [Country.MR]: "Mauritania",
  [Country.MU]: "Mauritius",
  [Country.YT]: "Mayotte",
  [Country.MX]: "Mexico",
  [Country.FM]: "Micronesia, Federated States of",
  [Country.MD]: "Moldova, Republic of",
  [Country.MC]: "Monaco",
  [Country.MN]: "Mongolia",
  [Country.MS]: "Montserrat",
  [Country.MA]: "Morocco",
  [Country.MZ]: "Mozambique",
  [Country.MM]: "Myanmar",
  [Country.NA]: "Namibia",
  [Country.NR]: "Nauru",
  [Country.NP]: "Nepal",
  [Country.NL]: "Netherlands",
  [Country.NC]: "New Caledonia",
  [Country.NZ]: "New Zealand",
  [Country.NI]: "Nicaragua",
  [Country.NE]: "Niger",
  [Country.NG]: "Nigeria",
  [Country.NU]: "Niue",
  [Country.NF]: "Norfolk Island",
  [Country.MK]: "North Macedonia, Republic of",
  [Country.MP]: "Northern Mariana Islands",
  [Country.NO]: "Norway",
  [Country.OM]: "Oman",
  [Country.PK]: "Pakistan",
  [Country.PW]: "Palau",
  [Country.PS]: "Palestinian Territory, Occupied",
  [Country.PA]: "Panama",
  [Country.PG]: "Papua New Guinea",
  [Country.PY]: "Paraguay",
  [Country.PE]: "Peru",
  [Country.PH]: "Philippines",
  [Country.PN]: "Pitcairn",
  [Country.PL]: "Poland",
  [Country.PT]: "Portugal",
  [Country.PR]: "Puerto Rico",
  [Country.QA]: "Qatar",
  [Country.RE]: "Reunion",
  [Country.RO]: "Romania",
  [Country.RU]: "Russia",
  [Country.RW]: "Rwanda",
  [Country.SH]: "Saint Helena",
  [Country.KN]: "Saint Kitts and Nevis",
  [Country.LC]: "Saint Lucia",
  [Country.PM]: "Saint Pierre and Miquelon",
  [Country.VC]: "Saint Vincent and the Grenadines",
  [Country.WS]: "Samoa",
  [Country.SM]: "San Marino",
  [Country.ST]: "Sao Tome and Principe",
  [Country.SA]: "Saudi Arabia",
  [Country.SN]: "Senegal",
  [Country.SC]: "Seychelles",
  [Country.SL]: "Sierra Leone",
  [Country.SG]: "Singapore",
  [Country.SK]: "Slovakia",
  [Country.SI]: "Slovenia",
  [Country.SB]: "Solomon Islands",
  [Country.SO]: "Somalia",
  [Country.ZA]: "South Africa",
  [Country.GS]: "South Georgia and the South Sandwich Islands",
  [Country.ES]: "Spain",
  [Country.LK]: "Sri Lanka",
  [Country.SD]: "Sudan",
  [Country.SR]: "Suriname",
  [Country.SJ]: "Svalbard and Jan Mayen",
  [Country.SZ]: "Eswatini",
  [Country.SE]: "Sweden",
  [Country.CH]: "Switzerland",
  [Country.SY]: "Syrian Arab Republic",
  [Country.TW]: "Taiwan",
  [Country.TJ]: "Tajikistan",
  [Country.TZ]: "Tanzania, United Republic of",
  [Country.TH]: "Thailand",
  [Country.TL]: "Timor-Leste",
  [Country.TG]: "Togo",
  [Country.TK]: "Tokelau",
  [Country.TO]: "Tonga",
  [Country.TT]: "Trinidad and Tobago",
  [Country.TN]: "Tunisia",
  [Country.TR]: "Turkey",
  [Country.TM]: "Turkmenistan",
  [Country.TC]: "Turks and Caicos Islands",
  [Country.TV]: "Tuvalu",
  [Country.UG]: "Uganda",
  [Country.UA]: "Ukraine",
  [Country.AE]: "United Arab Emirates",
  [Country.GB]: "United Kingdom",
  [Country.US]: "United States of America",
  [Country.UM]: "United States Minor Outlying Islands",
  [Country.UY]: "Uruguay",
  [Country.UZ]: "Uzbekistan",
  [Country.VU]: "Vanuatu",
  [Country.VE]: "Venezuela",
  [Country.VN]: "Vietnam",
  [Country.VG]: "Virgin Islands, British",
  [Country.VI]: "Virgin Islands, U.S.",
  [Country.WF]: "Wallis and Futuna",
  [Country.EH]: "Western Sahara",
  [Country.YE]: "Yemen",
  [Country.ZM]: "Zambia",
  [Country.ZW]: "Zimbabwe",
  [Country.AX]: "Åland Islands",
  [Country.BQ]: "Bonaire, Sint Eustatius and Saba",
  [Country.CW]: "Curaçao",
  [Country.GG]: "Guernsey",
  [Country.IM]: "Isle of Man",
  [Country.JE]: "Jersey",
  [Country.ME]: "Montenegro",
  [Country.BL]: "Saint Barthélemy",
  [Country.MF]: "Saint Martin (French part)",
  [Country.RS]: "Serbia",
  [Country.SX]: "Sint Maarten (Dutch part)",
  [Country.SS]: "South Sudan",
  [Country.XK]: "Kosovo",
}
