import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom"
import { compose } from "redux"

import ActionBar from "../../components/ActionBar"
import Avatar from "../../components/Avatar"
import { PrimaryButton } from "../../components/Buttons"
import ExhibitionBlock from "../../components/ExhibitionBlock"
import Grid from "../../components/Grid"
import HistoryLink, { HistoryLocation } from "../../components/HistoryLink"
import HoovusPaper, { PaperHeader } from "../../components/HoovusPaper"
import { PageWrapper } from "../../components/Layout"
import PropertyItem from "../../components/PropertyItem"
import Status from "../../core/enums/Status copy"
import { Biography } from "../../core/models/Biography"
import privateRoute from "../../hoc/privateRoute"
import { AsyncResult } from "../../redux/middleware/asyncMiddleware"
import { fetchBiography, deleteBiography } from "../../redux/modules/biography"
import {
  clearBiographyProducts,
  clearUserProducts,
  fetchBiographyProducts,
  fetchUserProducts,
} from "../../redux/modules/product"
import { StoreState } from "../../redux/reducer"
import { formatDateString } from "../../utils/DateUtils"
import { dispatchToast, ToastType } from "../../redux/modules/toasts"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import Product from "../../core/models/Product"
import ProductGridItem from "../../components/ProductGridItem"
import { Paragraph } from "../../components/Typography"

interface StoreProps {
  biography: Biography
  biographyStatus: Status
  biographyProducts: Array<Product>
  biographyProductsStatus: Status
  userProducts: Array<Product>
  userProductsStatus: Status
}

interface DispatchProps {
  fetchBiography: (id: string) => Promise<AsyncResult>
  fetchBiographyProducts: (id: string) => Promise<AsyncResult>
  fetchUserProducts: (id: string) => Promise<AsyncResult>
  deleteBiography: (id: string) => Promise<AsyncResult>
  dispatchToast: (message: string, type: ToastType, timeout?: number) => string
  clearBiographyProducts: () => void
  clearUserProducts: () => void
}

interface BiographyPageProps extends StoreProps, DispatchProps {}

const BiographyPage: React.FC<BiographyPageProps> = ({
  biography,
  biographyStatus,
  biographyProducts,
  biographyProductsStatus,
  userProducts,
  userProductsStatus,
  fetchBiographyProducts,
  fetchUserProducts,
  fetchBiography,
  deleteBiography,
  dispatchToast,
  clearBiographyProducts,
  clearUserProducts,
}) => {
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()
  const { id } = useParams<{ id: string }>()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  useEffect(() => {
    fetchBiography(id).then(biographyResponse => {
      const { userId } = biographyResponse.body
      fetchBiographyProducts(id)
      if (userId) fetchUserProducts(userId)
    })

    return () => {
      clearUserProducts()
      clearBiographyProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push("/biographies")
  }

  if (!biography || biographyStatus === Status.LOADING) return null

  const {
    realName,
    userId,
    pseudonym,
    description,
    birthYear,
    birthCountry,
    birthCity,
    image,
    education,
    soloExhibitions,
    groupExhibitions,
    createdAt,
    updatedAt,
  } = biography

  const openDeleteConfirmation = () => setShowDeleteConfirmation(true)
  const closeDeleteConfirmation = () => setShowDeleteConfirmation(false)

  const onDeleteBiography = () =>
    deleteBiography(id)
      .then(() => {
        dispatchToast(
          "Biography successfully deleted.",
          ToastType.Success,
          8000
        )
        history.push("/biographies")
      })
      .catch(() => {
        dispatchToast(
          "Failed to delete biography. Please try again.",
          ToastType.Error,
          8000
        )
      })

  return (
    <PageWrapper>
      {showDeleteConfirmation && (
        <ConfirmationDialog
          title="Delete biography"
          description="Are you sure you want to delete this biography?"
          onSubmit={onDeleteBiography}
          onCancel={closeDeleteConfirmation}
        />
      )}

      <ActionBar>
        <Button onClick={goBack}>Back</Button>
        <PrimaryButton
          component={RouterLink}
          to={`/biography/${biography.id}/edit`}
        >
          Edit
        </PrimaryButton>
        <PrimaryButton onClick={openDeleteConfirmation}>Delete</PrimaryButton>
      </ActionBar>

      <HoovusPaper>
        <PaperHeader>General</PaperHeader>
        <Grid columns="4fr 1fr" gap="0 60px">
          <Grid columns="1fr 1fr">
            <PropertyItem label="Real name">{realName}</PropertyItem>
            <PropertyItem label="Pseudonym">{pseudonym}</PropertyItem>
            <PropertyItem label="Description" col="1 / span 2">
              {description}
            </PropertyItem>
            <PropertyItem label="Birth year">{birthYear}</PropertyItem>
            <PropertyItem label="Birth country">{birthCountry}</PropertyItem>
            <PropertyItem label="Birth city">{birthCity}</PropertyItem>
            <PropertyItem label="Type">
              {!!userId ? "Created by user" : "Created by admin"}
            </PropertyItem>

            <PropertyItem label="Updated at">
              {formatDateString(updatedAt, "dd.MM.Y HH:mm")}
            </PropertyItem>
            <PropertyItem label="Created at">
              {formatDateString(createdAt, "dd.MM.Y HH:mm")}
            </PropertyItem>
          </Grid>
          <Avatar src={image?.thumbnail?.url} size={200} />
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader padding="40px 0 0">Education</PaperHeader>
        {(education || []).length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Start year</TableCell>
                <TableCell>End year</TableCell>
                <TableCell>School</TableCell>
                <TableCell>Field of study</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(education || []).map((educationItem, index) => (
                <TableRow key={index}>
                  <TableCell>{educationItem.startYear}</TableCell>
                  <TableCell>{educationItem.endYear}</TableCell>
                  <TableCell>{educationItem.school}</TableCell>
                  <TableCell>{educationItem.fieldOfStudy}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          "Education is missing"
        )}
      </HoovusPaper>

      <HoovusPaper>
        <ExhibitionBlock
          title="Solo exhibitions"
          exhibitions={soloExhibitions || []}
        />
      </HoovusPaper>

      <HoovusPaper>
        <ExhibitionBlock
          title="Group exhibitions"
          exhibitions={groupExhibitions || []}
        />
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Artworks (Biography)</PaperHeader>
        <Grid columns="repeat(4, 1fr)" gap="16px">
          {(biographyProducts || []).map(product => (
            <HistoryLink key={product.id} to={`/product/${product.id}`}>
              <ProductGridItem
                title={product.title}
                price={product.price}
                image={product.images[0]?.thumbnail?.url}
              />
            </HistoryLink>
          ))}
        </Grid>

        {biographyProductsStatus === Status.SUCCESS &&
          biographyProducts.length === 0 && (
            <Paragraph>No artworks attached to biography.</Paragraph>
          )}
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Artworks (User)</PaperHeader>
        <Grid columns="repeat(4, 1fr)" gap="16px">
          {(userProducts || []).map(product => (
            <HistoryLink key={product.id} to={`/product/${product.id}`}>
              <ProductGridItem
                title={product.title}
                price={product.price}
                image={product.images[0]?.thumbnail?.url}
              />
            </HistoryLink>
          ))}
        </Grid>

        {(!userId ||
          (userProductsStatus === Status.SUCCESS &&
            userProducts.length === 0)) && (
          <Paragraph>No artworks attached to user.</Paragraph>
        )}
      </HoovusPaper>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      biography: state.biography.biography,
      biographyStatus: state.biography.biographyStatus,
      biographyProducts: state.product.biographyProducts,
      biographyProductsStatus: state.product.biographyProductsStatus,
      userProducts: state.product.userProducts,
      userProductsStatus: state.product.userProductsStatus,
    }),
    {
      fetchBiography,
      fetchBiographyProducts,
      fetchUserProducts,
      deleteBiography,
      dispatchToast,
      clearUserProducts,
      clearBiographyProducts,
    }
  )
)(BiographyPage)
