import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link as RouterLink } from "react-router-dom"
import { compose } from "redux"
import styled from "styled-components"

import HoovusPaper from "../../components/HoovusPaper"
import { PageWrapper } from "../../components/Layout"
import Pagination from "../../components/Pagination"
import CampaignType from "../../core/enums/CampaignType"
import CampaignFilter from "../../core/models/CampaignFilter"
import { PaginatedData } from "../../core/models/PaginatedData"
import RentalInquiry from "../../core/models/RentalInquiry"
import privateRoute from "../../hoc/privateRoute"
import { AsyncAction } from "../../redux/middleware/asyncMiddleware"
import { fetchCampaigns, resetCampaigns } from "../../redux/modules/campaign"
import { StoreState } from "../../redux/reducer"
import { formatDateString } from "../../utils/DateUtils"

const TableWrapper = styled.div`
  padding: 24px 0;
`

interface StoreProps {
  campaigns: PaginatedData<RentalInquiry>
}

interface DispatchProps {
  fetchCampaigns: (filter: CampaignFilter) => Promise<AsyncAction>
  resetCampaigns: () => void
}

interface CampaignsPageProps extends StoreProps, DispatchProps {}

const CampaignsPage: React.FC<CampaignsPageProps> = ({
  campaigns,
  fetchCampaigns,
  resetCampaigns,
}) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  useEffect(() => {
    onFetchData(page, pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFetchData = (newPage: number, pageSize: number) => {
    fetchCampaigns({
      page: newPage,
      limit: pageSize,
      type: CampaignType.Rental,
    } as CampaignFilter)
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage)
    onFetchData(newPage, pageSize)
  }

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize)
    resetCampaigns()
    onFetchData(page, newPageSize)
  }

  return (
    <PageWrapper>
      <HoovusPaper>
        <Pagination
          activePage={page}
          pageSize={pageSize}
          count={campaigns.total}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Organisation</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell width="115" align="right">
                  Created
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaigns.getPageData(page).map(campaign => (
                <TableRow key={campaign.id}>
                  <TableCell>{`${campaign.firstName} ${campaign.lastName}`}</TableCell>
                  <TableCell>{campaign.organisation}</TableCell>
                  <TableCell>{campaign.email}</TableCell>
                  <TableCell>{campaign.phone}</TableCell>
                  <TableCell align="right">
                    {formatDateString(campaign.createdAt, "dd.MM.Y HH:mm")}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`/campaign/${campaign.id}`}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
        <Pagination
          activePage={page}
          pageSize={pageSize}
          count={campaigns.total}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      </HoovusPaper>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      campaigns: state.campaign.campaigns,
    }),
    {
      fetchCampaigns,
      resetCampaigns,
    }
  )
)(CampaignsPage)
