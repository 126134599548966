import { connect } from "react-redux"
import { compose } from "redux"
import styled from "styled-components"
import User from "../core/models/User"
import privateRoute from "../hoc/privateRoute"
import { StoreState } from "../redux/reducer"

const DashboardContent = styled.div`
  margin-top: 100px;
  text-align: center;
`
interface DashboardProps {
  user: User
}

const Dashboard: React.FC<DashboardProps> = ({ user }) => {
  return <DashboardContent>Dashboard placeholder</DashboardContent>
}

export default compose<React.FC & DashboardProps>(
  privateRoute,
  connect((state: StoreState) => ({
    user: state.auth.user,
  }))
)(Dashboard)
