import TransactionStatus from "../enums/TransactionStatus"
import Product, { Dimensions } from "./Product"
import Shipping from "./Shipping"
import Location from "./Location"

export interface ProductWithDimensions {
  id: string
  dimensions: Dimensions
}

export default interface ShippingRoute {
  location?: Location
  products: Array<ProductWithDimensions>
  calculatedPrice: number
}

export interface OrderProduct {
  quantity: number
  product: Product
}

export default interface Order {
  id: string
  orderProducts: Array<OrderProduct>
  shipping: Shipping
  shippingRoutes: Array<ShippingRoute>
  transactionStatus?: TransactionStatus
  transactionId?: string
  reference?: string
  updatedAt: string
  createdAt: string
}

export function calculateSubTotal(orderProducts: Array<OrderProduct>) {
  return orderProducts.reduce(
    (total, { product, quantity }) => total + quantity * (product.price || 0),
    0
  )
}

export function calculateShippingTotal(shippingRoutes: Array<ShippingRoute>) {
  return (
    shippingRoutes.reduce(
      (total, shippingRoute) => total + shippingRoute.calculatedPrice,
      0
    ) * 1.2
  )
}
