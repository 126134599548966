import ApiClient from "../../core/ApiClient"
import Config from "../../core/Config"
import Status from "../../core/enums/Status"
import User from "../../core/models/User"
import { asyncType } from "../middleware/asyncMiddleware"

export interface LoginData {
  email: string
  password: string
}

export interface SignupData extends LoginData {
  passwordConfirmation: string
  firstName: string
  lastName: string
  termsAndConditions: boolean
}

export interface AuthStore {
  user: User | null
  authStatus: Status
  logoutStatus: Status
  signupStatus: Status
  passRecoveryStatus: Status
}

const initialState = {
  user: null,
  authStatus: Status.INITIAL,
  logoutStatus: Status.INITIAL,
  signupStatus: Status.INITIAL,
  passRecoveryStatus: Status.INITIAL,
}

const LOG_IN = asyncType("redux.auth.LOG_IN")
const SIGN_UP = asyncType("redux.auth.SIGN_UP")
const FETCH_PROFILE = asyncType("redux.auth.FETCH_PROFILE")
const CREATE_PASS_RECOVERY = asyncType("redux.auth.CREATE_PASS_RECOVERY")
const FINISH_PASSWORD_RECOVERY = asyncType(
  "redux.auth.FINISH_PASSWORD_RECOVERY"
)

export const LOG_OUT = asyncType("redux.auth.LOG_OUT")

export default function reducer(state = initialState, action: any): AuthStore {
  switch (action.type) {
    case LOG_IN.INITIAL: {
      return {
        ...state,
        authStatus: Status.LOADING,
      }
    }

    case LOG_IN.SUCCESS: {
      return {
        ...state,
        user: action.result?.body,
        authStatus: Status.SUCCESS,
      }
    }

    case LOG_IN.FAIL: {
      return {
        ...state,
        authStatus: Status.FAIL,
      }
    }

    case FETCH_PROFILE.INITIAL: {
      return {
        ...state,
        authStatus: Status.LOADING,
      }
    }

    case FETCH_PROFILE.SUCCESS: {
      return {
        ...state,
        user: action.result?.body as User,
        authStatus: Status.SUCCESS,
      }
    }

    case FETCH_PROFILE.FAIL: {
      return {
        ...state,
        authStatus: Status.FAIL,
      }
    }

    case LOG_OUT.INITIAL: {
      return {
        ...state,
        logoutStatus: Status.LOADING,
      }
    }

    case LOG_OUT.SUCCESS: {
      return {
        ...initialState,
        logoutStatus: Status.SUCCESS,
        user: null,
      }
    }

    case LOG_OUT.FAIL: {
      return {
        ...state,
        logoutStatus: Status.FAIL,
        user: null,
      }
    }

    case SIGN_UP.INITIAL: {
      return {
        ...state,
        signupStatus: Status.LOADING,
      }
    }

    case SIGN_UP.SUCCESS: {
      return {
        ...state,
        user: action.result?.body as User,
        signupStatus: Status.SUCCESS,
      }
    }

    case SIGN_UP.FAIL: {
      return {
        ...state,
        signupStatus: Status.FAIL,
      }
    }

    case CREATE_PASS_RECOVERY.INITIAL:
    case FINISH_PASSWORD_RECOVERY.INITIAL: {
      return {
        ...state,
        passRecoveryStatus: Status.LOADING,
      }
    }

    case CREATE_PASS_RECOVERY.SUCCESS:
    case FINISH_PASSWORD_RECOVERY.SUCCESS: {
      return {
        ...state,
        passRecoveryStatus: Status.SUCCESS,
      }
    }

    case CREATE_PASS_RECOVERY.FAIL:
    case FINISH_PASSWORD_RECOVERY.FAIL: {
      return {
        ...state,
        passRecoveryStatus: Status.FAIL,
      }
    }

    default:
      return state
  }
}

export function login(loginData: LoginData) {
  return {
    types: LOG_IN,
    promise: (client: ApiClient) =>
      client.post(`${Config.app.apiUrl}/auth/login`, {
        body: JSON.stringify(loginData),
      }),
  }
}

export function logOut() {
  return {
    types: LOG_OUT,
    promise: (client: ApiClient) =>
      client.post(`${Config.app.apiUrl}/auth/logout`),
  }
}

export function signup(signupData: SignupData) {
  return {
    types: SIGN_UP,
    promise: (client: ApiClient) =>
      client.post(`${Config.app.apiUrl}/auth/signup`, {
        body: JSON.stringify(signupData),
      }),
  }
}

export function fetchProfile() {
  return {
    types: FETCH_PROFILE,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/user/profile`),
  }
}

export function createPasswordRecovery(email: string) {
  return {
    types: CREATE_PASS_RECOVERY,
    promise: (client: ApiClient) =>
      client.post(`${Config.app.apiUrl}/auth/password-recovery`, {
        body: JSON.stringify({ email }),
      }),
  }
}

export function finishPasswordRecovery(
  id: string,
  password: string,
  passwordConfirmation: string
) {
  return {
    types: FINISH_PASSWORD_RECOVERY,
    promise: (client: ApiClient) =>
      client.put(`${Config.app.apiUrl}/auth/password-recovery`, {
        body: JSON.stringify({
          id,
          password,
          passwordConfirmation,
        }),
      }),
  }
}
