const Config = {
  app: {
    apiUrl: process.env.REACT_APP_API_URL,
  },
  uploads: {
    maxImgSizeMB: 10,
  },
}

export default Config
