import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core"
import React from "react"
import { Exhibition } from "../core/models/Biography"
import { PaperHeader } from "./HoovusPaper"

interface ExhibitionBlockProps {
  title: string
  exhibitions?: Array<Exhibition>
}

const ExhibitionBlock: React.FC<ExhibitionBlockProps> = ({
  title,
  exhibitions = [],
}) => (
  <>
    <PaperHeader padding="40px 0 0">{title}</PaperHeader>
    {exhibitions.length > 0 ? (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Title</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exhibitions.map((exhibition, index) => (
            <TableRow key={index}>
              <TableCell>{exhibition.year}</TableCell>
              <TableCell>{exhibition.title}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : (
      <p>{title} are missing.</p>
    )}
  </>
)

export default ExhibitionBlock
