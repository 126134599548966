import ApiClient from "../../core/ApiClient"
import Config from "../../core/Config"
import Status from "../../core/enums/Status"
import { PaginatedData } from "../../core/models/PaginatedData"
import ProductFilter from "../../core/models/ProductFilter"
import RentalInquiry from "../../core/models/RentalInquiry"
import { buildFilterQueryParams } from "../../utils/ApiUtils"
import { AsyncAction, asyncType } from "../middleware/asyncMiddleware"

export interface CampaignStore {
  activeCampaign: RentalInquiry | null
  activeCampaignStatus: Status

  campaigns: PaginatedData<RentalInquiry>
  campaignsStatus: Status
}

const initialState = {
  activeCampaign: null,
  activeCampaignStatus: Status.INITIAL,

  campaigns: new PaginatedData<RentalInquiry>(new Map(), 0),
  campaignsStatus: Status.INITIAL,
}

const FETCH_CAMPAIGN = asyncType("redux.campaign.FETCH_CAMPAIGN")
const FETCH_CAMPAIGNS = asyncType("redux.campaign.FETCH_CAMPAIGNS")
const RESET_CAMPAIGNS_PAGINATION = "redux.campaign.RESET_CAMPAIGNS_PAGINATION"

export default function reducer(
  state = initialState,
  action: AsyncAction
): CampaignStore {
  switch (action.type) {
    case FETCH_CAMPAIGN.INITIAL: {
      return {
        ...state,
        activeCampaignStatus: Status.LOADING,
      }
    }

    case FETCH_CAMPAIGN.SUCCESS: {
      return {
        ...state,
        activeCampaign: action.result?.body as RentalInquiry,
        activeCampaignStatus: Status.SUCCESS,
      }
    }

    case FETCH_CAMPAIGN.FAIL: {
      return {
        ...state,
        activeCampaignStatus: Status.FAIL,
      }
    }

    case FETCH_CAMPAIGNS.INITIAL: {
      return {
        ...state,
        campaignsStatus: Status.LOADING,
      }
    }

    case FETCH_CAMPAIGNS.SUCCESS: {
      const { total, data } = action.result?.body

      const newPagesMap = new Map(state.campaigns.pages || null)
      newPagesMap.set(action.page, data)

      return {
        ...state,
        campaigns: new PaginatedData<RentalInquiry>(newPagesMap, total),
        campaignsStatus: Status.SUCCESS,
      }
    }

    case FETCH_CAMPAIGNS.FAIL: {
      return {
        ...state,
        campaignsStatus: Status.FAIL,
      }
    }

    case RESET_CAMPAIGNS_PAGINATION: {
      return {
        ...state,
        campaigns: new PaginatedData<RentalInquiry>(new Map(), 0),
        campaignsStatus: Status.INITIAL,
      }
    }

    default:
      return state
  }
}

export function fetchCampaign(id: string) {
  return {
    types: FETCH_CAMPAIGN,
    promise: (client: ApiClient) =>
      client.get(`${Config.app.apiUrl}/admin/campaign/${id}`),
  }
}

export function fetchCampaigns(filter: ProductFilter) {
  const query = buildFilterQueryParams(filter)

  return {
    types: FETCH_CAMPAIGNS,
    promise: (client: ApiClient) =>
      client.get(
        `${Config.app.apiUrl}/admin/campaign/filtered?${query.join("&")}`
      ),
    page: filter.page,
  }
}

export function resetCampaigns() {
  return {
    type: RESET_CAMPAIGNS_PAGINATION,
  }
}
