import { Button } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { compose } from "redux"

import ActionBar from "../../components/ActionBar"
import { PrimaryButton } from "../../components/Buttons"
import ProductForm from "../../components/Forms/ProductForm"
import Grid from "../../components/Grid"
import { HistoryLocation } from "../../components/HistoryLink"
import HoovusPaper from "../../components/HoovusPaper"
import { UploadedImage } from "../../components/ImageUpload"
import { PageWrapper } from "../../components/Layout"
import ProductImageUpload from "../../components/ProductImageUpload"
import TypeSearch, { SearchType } from "../../components/TypeSearch"
import { Paragraph } from "../../components/Typography"
import Status from "../../core/enums/Status"
import FormErrors from "../../core/errors/FormError"
import Image from "../../core/models/Image"
import Product, { NewProduct } from "../../core/models/Product"
import privateRoute from "../../hoc/privateRoute"
import { AsyncResult } from "../../redux/middleware/asyncMiddleware"
import {
  fetchProduct,
  updateProduct,
  uploadProductPhotos,
} from "../../redux/modules/product"
import { dispatchToast, ToastType } from "../../redux/modules/toasts"
import { StoreState } from "../../redux/reducer"

interface StoreProps {
  activeProduct: Product
  activeProductStatus: Status
}

interface DispatchProps {
  fetchProduct: (id: string) => Promise<AsyncResult>
  updateProduct: (updatedProduct: Product) => Promise<AsyncResult>
  dispatchToast: (message: string, type: ToastType, timeout?: number) => string
  uploadProductPhotos: (
    productId: string,
    images: Array<File>
  ) => Promise<AsyncResult>
}

interface EditProductPageProps extends StoreProps, DispatchProps {}

const EditProductPage: React.FC<EditProductPageProps> = ({
  activeProduct,
  activeProductStatus,
  updateProduct,
  fetchProduct,
  dispatchToast,
  uploadProductPhotos,
}) => {
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()
  const { id } = useParams<{ id: string }>()

  const [errors, setErrors] = useState<FormErrors>({})
  const [images, setImages] = useState<Array<UploadedImage>>([])
  const [productToUpdate, setProductToUpdate] = useState<Product>(activeProduct)

  useEffect(() => {
    fetchProduct(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setProductToUpdate(activeProduct)
  }, [activeProduct])

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push(`/product/${id}`)
  }

  const onSave = () => {
    if (productToUpdate) {
      updateProduct(productToUpdate)
        .then(() => {
          if (images.length > 0) {
            uploadProductPhotos(
              productToUpdate.id,
              images.map(({ file }) => file)
            )
              .then(goBack)
              .catch(() => {
                dispatchToast(
                  "Whoops, failed to upload images. Please try again.",
                  ToastType.Error,
                  8000
                )
              })
          } else {
            goBack()
          }
        })
        .catch(errorResponse => {
          if (errorResponse?.body?.name === "ValidationError") {
            dispatchToast(
              "Errors found in your form. Please make sure you have filled all mandatory fields.",
              ToastType.Error,
              8000
            )
            setErrors(errorResponse?.body?.errors)
          } else {
            dispatchToast(
              "Whoops, something went wrong. Please try again.",
              ToastType.Error,
              8000
            )
          }
        })
    }
  }

  const onProductChange = (updatedProduct: NewProduct) =>
    setProductToUpdate(updatedProduct as Product)

  const onProductImagesChange = (newProductImages: Array<Image>) => {
    console.log("ON PRODUCT IMAGES CHANGE", newProductImages)
    setProductToUpdate({
      ...productToUpdate,
      images: newProductImages,
    })
  }

  const onNewImagesChange = (newImages: Array<UploadedImage>) =>
    setImages(newImages)

  const onOwnerSelected = (type: SearchType, id: string) => {
    const { userId, biographyId, ...productData } = productToUpdate

    if (type === SearchType.User) {
      setProductToUpdate({
        ...productData,
        userId: id,
      })
    } else if (type === SearchType.Biography) {
      setProductToUpdate({
        ...productData,
        biographyId: id,
      })
    }
  }

  const { userId, biographyId } = productToUpdate || {}
  return (
    <PageWrapper>
      <ActionBar>
        <Button onClick={goBack}>Back</Button>
        <PrimaryButton onClick={onSave}>Save</PrimaryButton>
      </ActionBar>
      {productToUpdate && (
        <Grid columns="1fr 1fr" gap="32px" align="start">
          <ProductForm
            product={productToUpdate}
            errors={errors}
            onChange={onProductChange}
          />

          <div>
            <HoovusPaper>
              {userId && <Paragraph>Selected user: {userId}</Paragraph>}
              {biographyId && (
                <Paragraph>Selected biography: {biographyId}</Paragraph>
              )}
              <TypeSearch onSelect={onOwnerSelected} />
            </HoovusPaper>

            <ProductImageUpload
              productImages={productToUpdate.images || []}
              images={images}
              onProductImagesChange={onProductImagesChange}
              onNewImagesChange={onNewImagesChange}
            />
          </div>
        </Grid>
      )}
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      activeProduct: state.product.activeProduct,
      activeProductStatus: state.product.activeProductStatus,
    }),
    {
      fetchProduct,
      updateProduct,
      dispatchToast,
      uploadProductPhotos,
    }
  )
)(EditProductPage)
