import styled from "styled-components"
import React, { forwardRef } from "react"
import Button from "@material-ui/core/Button"
import HistoryLink from "./HistoryLink"

export const PrimaryButton = styled(Button)<any>`
  &.MuiButton-root {
    color: white;
    background-color: black;

    &.MuiButton-root:hover {
      background-color: black;
    }
  }
`

interface LinkBtnProps {
  to: string
  size?: "small" | "medium" | "large" | undefined
}

export const PrimaryLink: React.FC<LinkBtnProps> = forwardRef(
  ({ to, size, children }, ref) => (
    <PrimaryButton component={HistoryLink} ref={ref} to={to} size={size}>
      {children}
    </PrimaryButton>
  )
)

export const SecondaryLink: React.FC<LinkBtnProps> = ({
  to,
  size,
  children,
}) => (
  <Button component={HistoryLink} to={to} size={size}>
    {children}
  </Button>
)
