import { FormControl, FormLabel } from "@material-ui/core"
import React from "react"
import styled from "styled-components"

const StyledFormGroup = styled(FormControl)`
  width: 100%;

  &.MuiFormControl-root {
    margin: 8px 0;
  }
`

const StyledFormLabel = styled(FormLabel)`
  &.MuiFormLabel-root {
    font-size: 16px;
    font-family: "Montserrat";
    color: hsla(0, 0%, 0%, 0.54);
  }
`

interface FormGroupProps {
  label: string
}

const FormGroup: React.FC<FormGroupProps> = ({ label, children }) => {
  return (
    <StyledFormGroup>
      <StyledFormLabel>{label}</StyledFormLabel>
      {children}
    </StyledFormGroup>
  )
}

export default FormGroup
