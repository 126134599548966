import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link as RouterLink } from "react-router-dom"
import { compose } from "redux"
import ActionBar from "../../components/ActionBar"

import HoovusPaper from "../../components/HoovusPaper"
import { PageWrapper } from "../../components/Layout"
import { Biography } from "../../core/models/Biography"
import privateRoute from "../../hoc/privateRoute"
import { AsyncAction } from "../../redux/middleware/asyncMiddleware"
import { fetchBiographies } from "../../redux/modules/biography"
import { StoreState } from "../../redux/reducer"
import { formatDateString } from "../../utils/DateUtils"

interface StoreProps {
  biographies: Array<Biography>
}

interface DispatchProps {
  fetchBiographies: () => Promise<AsyncAction>
}

interface BiographiesPageProps extends StoreProps, DispatchProps {}

const BiographiesPage: React.FC<BiographiesPageProps> = ({
  biographies,
  fetchBiographies,
}) => {
  useEffect(() => {
    fetchBiographies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWrapper>
      <ActionBar>
        <Button component={RouterLink} to="/biography/new">
          ADD NEW
        </Button>
      </ActionBar>

      <HoovusPaper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Pseudonym</TableCell>
              <TableCell width="115" align="center">
                User
              </TableCell>
              <TableCell width="115" align="right">
                Updated
              </TableCell>
              <TableCell width="115" align="right">
                Created
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {biographies.map(biography => (
              <TableRow key={biography.id}>
                <TableCell>{biography.realName}</TableCell>
                <TableCell>{biography.pseudonym}</TableCell>
                <TableCell align="center">
                  {biography.userId && (
                    <Button
                      component={RouterLink}
                      to={`/user/${biography.userId}`}
                    >
                      View user
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  {formatDateString(biography.updatedAt, "dd.MM.Y HH:mm")}
                </TableCell>
                <TableCell>
                  {formatDateString(biography.createdAt, "dd.MM.Y HH:mm")}
                </TableCell>
                <TableCell align="right">
                  <Button
                    component={RouterLink}
                    to={`/biography/${biography.id}`}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </HoovusPaper>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      biographies: state.biography.biographies,
    }),
    {
      fetchBiographies,
    }
  )
)(BiographiesPage)
