import React from "react"
import styled from "styled-components"

interface GridProps {
  columns: string
  gap?: string
  align?: string
}

const StyledGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  grid-gap: ${({ gap }) => gap || "0px"};
  ${({ align }) => align && `align-items: ${align};`}
`

const Grid: React.FC<GridProps> = ({ children, ...props }) => {
  return <StyledGrid {...props}>{children}</StyledGrid>
}

export default Grid
