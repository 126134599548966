import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link as RouterLink } from "react-router-dom"
import { compose } from "redux"
import { OrderStatusChip } from "../components/EnumChip"

import HoovusPaper from "../components/HoovusPaper"
import { PageWrapper } from "../components/Layout"
import Order, { OrderProduct } from "../core/models/Order"
import privateRoute from "../hoc/privateRoute"
import { AsyncAction } from "../redux/middleware/asyncMiddleware"
import { fetchOrders } from "../redux/modules/order"
import { StoreState } from "../redux/reducer"
import { formatDateString } from "../utils/DateUtils"

interface StoreProps {
  orders: Array<Order>
}

interface DispatchProps {
  fetchOrders: () => Promise<AsyncAction>
}

interface OrdersPageProps extends StoreProps, DispatchProps {}

const OrdersPage: React.FC<OrdersPageProps> = ({ orders, fetchOrders }) => {
  useEffect(() => {
    fetchOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const calculateTotal = (orderItems: Array<OrderProduct>) =>
    orderItems.reduce(
      (total, orderItem) =>
        total + orderItem.quantity * (orderItem.product.price || 0),
      0
    )

  const calculateQuantity = (orderItems: Array<OrderProduct>) =>
    orderItems.reduce((quantity, orderItem) => quantity + orderItem.quantity, 0)

  return (
    <PageWrapper>
      <HoovusPaper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Customer</TableCell>
              <TableCell align="center">Total</TableCell>
              <TableCell align="center">Quantity</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell width="115" align="right">
                Updated
              </TableCell>
              <TableCell width="115" align="right">
                Created
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map(order => (
              <TableRow key={order.id}>
                <TableCell>
                  {order.shipping.firstName} {order.shipping.lastName}
                </TableCell>
                <TableCell align="center">{`€${calculateTotal(
                  order.orderProducts
                ).toFixed(2)}`}</TableCell>
                <TableCell align="center">
                  {calculateQuantity(order.orderProducts)}
                </TableCell>
                <TableCell align="center">
                  {order.transactionStatus ? (
                    <OrderStatusChip value={order.transactionStatus} />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell align="right">
                  {formatDateString(order.updatedAt, "dd.MM.Y HH:mm")}
                </TableCell>
                <TableCell align="right">
                  {formatDateString(order.createdAt, "dd.MM.Y HH:mm")}
                </TableCell>
                <TableCell align="right">
                  <Button component={RouterLink} to={`/order/${order.id}`}>
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </HoovusPaper>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      orders: state.order.orders,
    }),
    {
      fetchOrders,
    }
  )
)(OrdersPage)
