import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { compose } from "redux"
import styled from "styled-components"

import Avatar from "../components/Avatar"
import ExhibitionBlock from "../components/ExhibitionBlock"
import Grid from "../components/Grid"
import HistoryLink, { HistoryLocation } from "../components/HistoryLink"
import HoovusPaper, { PaperHeader } from "../components/HoovusPaper"
import { PageWrapper } from "../components/Layout"
import ProductGridItem from "../components/ProductGridItem"
import PropertyItem from "../components/PropertyItem"
import Role from "../core/enums/Role"
import BankDetails from "../core/models/BankDetails"
import { Biography } from "../core/models/Biography"
import Location from "../core/models/Location"
import Product from "../core/models/Product"
import User from "../core/models/User"
import privateRoute from "../hoc/privateRoute"
import { AsyncResult } from "../redux/middleware/asyncMiddleware"
import { clearUserProducts, fetchUserProducts } from "../redux/modules/product"
import { fetchUser, fetchUserBiography } from "../redux/modules/users"
import { StoreState } from "../redux/reducer"
import { formatDateString } from "../utils/DateUtils"

const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
`

const RoleChip = styled(Chip)`
  margin-right: 8px;
`

interface StoreProps {
  user: User
  userProducts: Array<Product>
  userBiography?: Biography
}

interface DispatchProps {
  fetchUser: (userId: string) => Promise<AsyncResult>
  fetchUserProducts: (userId: string) => Promise<AsyncResult>
  fetchUserBiography: (userId: string) => Promise<AsyncResult>
  clearUserProducts: () => void
}

interface UsersPageProps extends StoreProps, DispatchProps {}

const UserPage: React.FC<UsersPageProps> = ({
  user,
  userProducts,
  userBiography,
  fetchUser,
  fetchUserProducts,
  fetchUserBiography,
  clearUserProducts,
}) => {
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    fetchUser(id).then(response => {
      if (response.body.roles.includes(Role.Artist)) {
        fetchUserBiography(id)
        fetchUserProducts(id)
      }
    })

    return clearUserProducts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    console.log(loc.state)
    if (loc.state?.prevPath) return history.goBack()
    history.push("/users")
  }

  if (!user) return null

  const {
    firstName,
    lastName,
    email,
    roles,
    location = {} as Location,
    bankDetails = {} as BankDetails,
    createdAt,
    updatedAt,
  } = user

  const { accountNumber } = bankDetails

  const {
    addressLine1,
    addressLine2,
    city,
    country,
    phone,
    postalCode,
    state,
  } = location

  return (
    <PageWrapper>
      <Button onClick={goBack}>Back</Button>

      <HoovusPaper>
        <PaperHeader>General</PaperHeader>
        <Grid columns="1fr 1fr 1fr" gap="0 20px">
          <PropertyItem label="Name">{`${firstName} ${lastName}`}</PropertyItem>
          <PropertyItem label="Email">{email}</PropertyItem>
          <PropertyItem label="Roles">
            {roles.map(role => (
              <RoleChip label={role} />
            ))}
          </PropertyItem>
          <PropertyItem label="Updated at">
            {formatDateString(updatedAt, "dd.MM.Y HH:mm")}
          </PropertyItem>
          <PropertyItem label="Created at">
            {formatDateString(createdAt, "dd.MM.Y HH:mm")}
          </PropertyItem>
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Bank details</PaperHeader>
        <PropertyItem label="Account number">{accountNumber}</PropertyItem>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Delivery location</PaperHeader>
        <Grid columns="1fr 1fr">
          <PropertyItem label="Address line 1">{addressLine1}</PropertyItem>
          <PropertyItem label="Address line 2">{addressLine2}</PropertyItem>
          <PropertyItem label="City">{city}</PropertyItem>
          <PropertyItem label="Country">{country}</PropertyItem>
          <PropertyItem label="Phone">{phone}</PropertyItem>
          <PropertyItem label="Postal code">{postalCode}</PropertyItem>
          <PropertyItem label="State">{state}</PropertyItem>
        </Grid>
      </HoovusPaper>

      {user.roles.includes(Role.Artist) && (
        <HoovusPaper>
          <PaperHeader>Biography</PaperHeader>
          {userBiography && (
            <>
              <Grid columns="4fr 1fr" gap="0 60px">
                <Grid columns="1fr 1fr">
                  <PropertyItem label="Pseudonym">
                    {userBiography.pseudonym}
                  </PropertyItem>
                  <PropertyItem label="Real name">
                    {userBiography.realName}
                  </PropertyItem>
                  <PropertyItem label="Description" col="1 / span 2">
                    {userBiography.description}
                  </PropertyItem>
                  <PropertyItem label="Birth year">
                    {userBiography.birthYear}
                  </PropertyItem>
                  <PropertyItem label="Birth country">
                    {userBiography.birthCountry}
                  </PropertyItem>
                  <PropertyItem label="Birth city">
                    {userBiography.birthCity}
                  </PropertyItem>
                </Grid>
                <Avatar src={userBiography.image?.thumbnail?.url} size={200} />
              </Grid>

              <PaperHeader padding="40px 0 0">Education</PaperHeader>
              {(userBiography.education || []).length > 0 ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Start year</TableCell>
                      <TableCell>End year</TableCell>
                      <TableCell>School</TableCell>
                      <TableCell>Field of study</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(userBiography.education || []).map(
                      (educationItem, index) => (
                        <TableRow key={index}>
                          <TableCell>{educationItem.startYear}</TableCell>
                          <TableCell>{educationItem.endYear}</TableCell>
                          <TableCell>{educationItem.school}</TableCell>
                          <TableCell>{educationItem.fieldOfStudy}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              ) : (
                "Education is missing"
              )}

              <ExhibitionBlock
                title="Solo exhibitions"
                exhibitions={userBiography.soloExhibitions || []}
              />

              <ExhibitionBlock
                title="Group exhibitions"
                exhibitions={userBiography.groupExhibitions || []}
              />
            </>
          )}
        </HoovusPaper>
      )}

      <HoovusPaper>
        <PaperHeader>Artworks</PaperHeader>

        <ImagesWrapper>
          {(userProducts || []).map(product => (
            <HistoryLink key={product.id} to={`/product/${product.id}`}>
              <ProductGridItem
                title={product.title}
                price={product.price}
                image={product.images[0]?.thumbnail?.url}
              />
            </HistoryLink>
          ))}
        </ImagesWrapper>
      </HoovusPaper>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      user: state.users.user,
      userProducts: state.product.userProducts,
      userBiography: state.users.userBiography,
    }),
    {
      fetchUser,
      fetchUserProducts,
      fetchUserBiography,
      clearUserProducts,
    }
  )
)(UserPage)
