import styled from "styled-components"

const Avatar = styled.div<{ size?: number; src?: string }>`
  background-image: url(${({ src }) => src || "/image-placeholder.png"});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  width: ${({ size }) => size || 36}px;
  height: ${({ size }) => size || 36}px;
  border-radius: 12px;
  overflow: hidden;
`

export default Avatar
