enum Surface {
  Canvas = "Canvas",
  LinenCanvas = "LinenCanvas",
  Paper = "Paper",
  CardBoard = "CardBoard",
  Wood = "Wood",
  Metal = "Metal",
  Fabric = "Fabric",
  Plastic = "Plastic",
  Leather = "Leather",
  Stone = "Stone",
  Glass = "Glass",
  Plexiglass = "Plexiglass",
  Synthetic = "Synthetic",
  Object = "Object",
  Wall = "Wall",
  Other = "Other",
}

export const SurfaceTranslations = {
  [Surface.Canvas]: "Canvas",
  [Surface.LinenCanvas]: "Linen Canvas",
  [Surface.Paper]: "Paper",
  [Surface.CardBoard]: "Card Board",
  [Surface.Wood]: "Wood",
  [Surface.Metal]: "Metal",
  [Surface.Fabric]: "Fabric",
  [Surface.Plastic]: "Plastic",
  [Surface.Leather]: "Leather",
  [Surface.Stone]: "Stone",
  [Surface.Glass]: "Glass",
  [Surface.Plexiglass]: "Plexiglass",
  [Surface.Synthetic]: "Synthetic",
  [Surface.Object]: "Object",
  [Surface.Wall]: "Wall",
  [Surface.Other]: "Other substrate",
}
export default Surface
