import set from "lodash/fp/set"
import React from "react"
import styled from "styled-components"

import FormErrors from "../../core/errors/FormError"
import AuctionItem, { NewAuctionItem } from "../../core/models/AuctionItem"
import Grid from "../Grid"
import HoovusPaper, { PaperHeader } from "../HoovusPaper"
import DateTime from "../Input/DateTime"
import StyledInput from "../Input/StyledInput"

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

interface AuctionItemFormProps {
  auctionItem: NewAuctionItem | AuctionItem
  errors?: FormErrors
  onChange: (updatedAuction: NewAuctionItem) => void
}

const AuctionItemForm: React.FC<AuctionItemFormProps> = ({
  auctionItem,
  errors = {},
  onChange,
}) => {
  const onDateFieldChange = (field: string) => (newValue: Date | null) =>
    onChange(set(field, newValue || undefined, auctionItem))

  const onNumberChange = (path: string) => ({ target: { value = "" } }) =>
    onChange(set(path, value ? Number(value) : undefined, auctionItem))

  return (
    <FormWrapper>
      <HoovusPaper>
        <PaperHeader>General</PaperHeader>
        <Grid columns="1fr 1fr" gap="20px">
          <DateTime
            label="Starts at *"
            value={auctionItem.startsAt}
            onChange={onDateFieldChange("startsAt")}
            error={!!errors.startsAt}
            helperText={errors.startsAt}
          />
          <DateTime
            label="Ends at *"
            value={auctionItem.endsAt}
            onChange={onDateFieldChange("endsAt")}
            minDate={auctionItem.startsAt || undefined}
            error={!!errors.endsAt}
            helperText={errors.endsAt}
          />
          <StyledInput
            label="Starting price *"
            variant="outlined"
            type="number"
            value={auctionItem.startingPrice}
            onChange={onNumberChange("startingPrice")}
            error={!!errors.startingPrice}
            helperText={errors.startingPrice}
          />
          <StyledInput
            label="Bid step *"
            variant="outlined"
            type="number"
            value={auctionItem.bidStep}
            onChange={onNumberChange("bidStep")}
            error={!!errors.bidStep}
            helperText={errors.bidStep}
          />
        </Grid>
      </HoovusPaper>
    </FormWrapper>
  )
}

export default AuctionItemForm
