import { TextField } from "@material-ui/core"
import React, { FormEvent, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { compose } from "redux"
import styled from "styled-components"

import { PrimaryButton } from "../components/Buttons"
import User from "../core/models/User"
import { AsyncAction } from "../redux/middleware/asyncMiddleware"
import { login, LoginData } from "../redux/modules/auth"
import { StoreState } from "../redux/reducer"

const PageWrapper = styled.div``

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-itemst: center;
  justify-content: center;
  max-width: 360px;
  width: 100%;
  margin: 20vh auto;
`

const LoginButton = styled(PrimaryButton)`
  &.MuiButtonBase-root {
    margin-top: 12px;
  }
`

interface StoreProps {
  user: User | null
  path: string
}

interface DispatchProps {
  login: (loginData: LoginData) => Promise<AsyncAction>
}

interface LoginPageProps extends StoreProps, DispatchProps {}

const LoginPage: React.FC<LoginPageProps> = ({ login }) => {
  const location = useLocation<{ redirectTo?: string }>()
  const history = useHistory()

  const { redirectTo } = location.state || {}

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  function submitLogin(e: FormEvent | MouseEvent) {
    e.preventDefault()

    login({ email, password }).then(() => {
      history.push(redirectTo || "/dashboard")
    })
  }

  return (
    <PageWrapper>
      <LoginForm onSubmit={submitLogin}>
        <TextField
          type="email"
          label="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          type="password"
          label="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <LoginButton
          variant="contained"
          color="primary"
          disableElevation
          type="submit"
          onClick={submitLogin}
        >
          Log in
        </LoginButton>
      </LoginForm>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  connect(
    (state: StoreState) => ({
      user: state.auth.user,
    }),
    {
      login,
    }
  )
)(LoginPage)
