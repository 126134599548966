/*
 * This combines the reducer modules located in ./modules.
 * No reducer switch/cases should be written in this file.
 */
import { Action, combineReducers } from "redux"
import auction, { AuctionStore } from "./modules/auction"

import auth, { AuthStore } from "./modules/auth"
import biography, { BiographyStore } from "./modules/biography"
import order, { OrderStore } from "./modules/order"
import product, { ProductStore } from "./modules/product"
import toast, { ToastStore } from "./modules/toasts"
import users, { UsersStore } from "./modules/users"
import search, { SearchStore } from "./modules/search"
import campaign, { CampaignStore } from "./modules/campaign"

export interface StoreState {
  auth: AuthStore
  users: UsersStore
  product: ProductStore
  order: OrderStore
  biography: BiographyStore
  auction: AuctionStore
  toast: ToastStore
  search: SearchStore
  campaign: CampaignStore
}

const combinedReducers = combineReducers({
  auth,
  users,
  product,
  order,
  biography,
  auction,
  toast,
  search,
  campaign,
})

function buildReducer(state: any, action: Action<any>) {
  return combinedReducers(state, action)
}

export default buildReducer
