import { FormControl, FormLabel, RadioGroup } from "@material-ui/core"
import React, { ChangeEvent } from "react"
import styled from "styled-components"

const StyledRadioButtons = styled(FormControl)`
  width: 100%;

  &.MuiFormControl-root {
    margin: 8px 0;
  }

  .MuiFormLabel-root {
    font-size: 16px;
    font-family: "Montserrat";
    color: hsla(0, 0%, 0%, 0.54);
  }
`

interface RadioButtonsProps {
  value: any
  label: string
  onChange: (newValue: string) => void
}

const RadioButtons: React.FC<RadioButtonsProps> = ({
  label,
  value,
  onChange,
  children,
}) => {
  return (
    <StyledRadioButtons>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
      >
        {children}
      </RadioGroup>
    </StyledRadioButtons>
  )
}

export default RadioButtons
