import { DebouncedFunc } from "lodash"
import throttle from "lodash/throttle"
import { useRef } from "react"

const useThrottle = (
  func: (...args: any) => any,
  timeout: number
): DebouncedFunc<(...args: any) => any> => {
  const throttleFunc = useRef(throttle(func, timeout))

  return throttleFunc.current
}

export default useThrottle
