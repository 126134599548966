import { Button } from "@material-ui/core"
import React from "react"
import { connect } from "react-redux"
import { Link as RouterLink } from "react-router-dom"
import { compose } from "redux"
import styled from "styled-components"

import { AsyncAction } from "../redux/middleware/asyncMiddleware"
import { logOut } from "../redux/modules/auth"
import { StoreState } from "../redux/reducer"

const StyledHeader = styled.header`
  height: 64px;
  box-shadow: rgba(7, 51, 84, 0.17) 0px 0px 30px !important;
  background: white;
`

const HeaderContent = styled.div`
  max-width: calc(100vw - 48px);
  width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: 64px;
  justify-content: flex-end;
  align-items: center;
`

interface HeaderProps {
  loggedIn: boolean
  logOut: () => Promise<AsyncAction>
}

const Header: React.FC<HeaderProps> = ({ loggedIn, logOut }) => {
  if (!loggedIn) return null

  return (
    <StyledHeader>
      <HeaderContent>
        <Button component={RouterLink} to="/dashboard">
          Dashboard
        </Button>
        <Button component={RouterLink} to="/products">
          Products
        </Button>
        <Button component={RouterLink} to="/biographies">
          Biographies
        </Button>
        <Button component={RouterLink} to="/users">
          Users
        </Button>
        <Button component={RouterLink} to="/orders">
          Orders
        </Button>
        <Button component={RouterLink} to="/auctions">
          Auctions
        </Button>
        <Button component={RouterLink} to="/campaigns">
          Campaigns
        </Button>
        <Button onClick={logOut}>Log out</Button>
      </HeaderContent>
    </StyledHeader>
  )
}

export default compose<React.FC & HeaderProps>(
  connect(
    (state: StoreState) => ({
      loggedIn: !!state.auth.user,
    }),
    {
      logOut,
    }
  )
)(Header)
