enum ProductStatus {
  ForSale = "ForSale",
  NotForSale = "NotForSale",
  Sold = "Sold",
}

export const ProductStatusTranslations = {
  [ProductStatus.ForSale]: "For sale",
  [ProductStatus.NotForSale]: "Not for sale",
  [ProductStatus.Sold]: "Sold",
}

export default ProductStatus
