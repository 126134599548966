export function buildFilterQueryParams(filter: Object) {
  const query: Array<any> = []

  Object.entries(filter).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        if (!!item) {
          query.push(`${key}[]=${item}`)
        }
      })
    } else {
      if (!!value) {
        query.push(`${key}=${encodeURIComponent(value)}`)
      }
    }
  })

  return query
}
