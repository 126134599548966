import { Button } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { compose } from "redux"
import styled from "styled-components"

import ActionBar from "../../components/ActionBar"
import Avatar from "../../components/Avatar"
import { PrimaryButton, PrimaryLink } from "../../components/Buttons"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import Grid from "../../components/Grid"
import HistoryLink, { HistoryLocation } from "../../components/HistoryLink"
import HoovusPaper, { PaperHeader } from "../../components/HoovusPaper"
import { PageWrapper } from "../../components/Layout"
import PropertyItem from "../../components/PropertyItem"
import Location from "../../core/models/Location"
import Product, {
  Dimensions,
  LimitedEditionConf,
} from "../../core/models/Product"
import Translations from "../../core/Translations"
import privateRoute from "../../hoc/privateRoute"
import {
  AsyncAction,
  AsyncResult,
} from "../../redux/middleware/asyncMiddleware"
import { deleteProduct, fetchProduct } from "../../redux/modules/product"
import { dispatchToast, ToastType } from "../../redux/modules/toasts"
import { StoreState } from "../../redux/reducer"
import { formatDateString } from "../../utils/DateUtils"
import { dimensionToString } from "../../utils/StringUtils"

const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
`

const ProductImage = styled.div`
  img {
    width: 100%;
    margin: 0;
  }
`

interface StoreProps {
  product: Product
}

interface DispatchProps {
  fetchProduct: (productId: string) => Promise<AsyncAction>
  deleteProduct: (id: string) => Promise<AsyncResult>
  dispatchToast: (message: string, type: ToastType, timeout?: number) => string
}

interface ProductPageProps extends StoreProps, DispatchProps {}

const ProductPage: React.FC<ProductPageProps> = ({
  product,
  fetchProduct,
  deleteProduct,
  dispatchToast,
}) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const loc = useLocation<HistoryLocation>()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  useEffect(() => {
    fetchProduct(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    if (loc.state?.prevPath) return history.goBack()
    history.push("/products")
  }

  if (!product) return null

  const openDeleteConfirmation = () => setShowDeleteConfirmation(true)
  const closeDeleteConfirmation = () => setShowDeleteConfirmation(false)

  const onDeleteProduct = () =>
    deleteProduct(id)
      .then(() => {
        dispatchToast("Product successfully deleted.", ToastType.Success, 8000)
        history.push("/products")
      })
      .catch(() => {
        dispatchToast(
          "Failed to delete product. Please try again.",
          ToastType.Error,
          8000
        )
      })

  const {
    title,
    description,
    year,
    nsfw,
    price,
    style,
    status,
    medium,
    surface,
    images,
    dimensions = {} as Dimensions,
    hasFrame,
    tags,
    isLimitedEdition,
    limitedEditionConf = {} as LimitedEditionConf,
    signatures = [],
    location = {} as Location,
    updatedAt,
    createdAt,
  } = product

  const { width, height, thickness, weight } = dimensions
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    country,
    phone,
    instructions,
  } = location

  const {
    copies,
    copiesForSale,
    copiesOutOfTrade,
    artistProofs,
  } = limitedEditionConf

  return (
    <PageWrapper>
      {showDeleteConfirmation && (
        <ConfirmationDialog
          title="Delete product"
          description="Are you sure you want to delete this product?"
          onSubmit={onDeleteProduct}
          onCancel={closeDeleteConfirmation}
        />
      )}

      <ActionBar>
        <Button onClick={goBack}>Back</Button>
        <PrimaryLink to={`/product/${product.id}/edit`}>Edit</PrimaryLink>
        <PrimaryButton onClick={openDeleteConfirmation}>Delete</PrimaryButton>
      </ActionBar>

      <Grid columns="2fr 1fr" gap="24px">
        <HoovusPaper>
          <PaperHeader>General</PaperHeader>
          <Grid columns="1fr 1fr">
            <PropertyItem label="Title">{title}</PropertyItem>
            <PropertyItem label="Description">{description}</PropertyItem>
            <PropertyItem label="Style">
              {style && Translations.Enum.ArtStyle[style]}
            </PropertyItem>
            <PropertyItem label="Keywords">{tags.join(", ")}</PropertyItem>
            <PropertyItem label="Year">{year}</PropertyItem>
            <PropertyItem label="Has frame">{hasFrame}</PropertyItem>
            <PropertyItem label="Updated at">
              {formatDateString(updatedAt, "dd.MM.Y HH:mm")}
            </PropertyItem>
            <PropertyItem label="Created at">
              {formatDateString(createdAt, "dd.MM.Y HH:mm")}
            </PropertyItem>
          </Grid>
        </HoovusPaper>

        {product.author && (
          <HoovusPaper>
            <PaperHeader>Artist</PaperHeader>
            <Grid columns="140px 1fr">
              <Avatar src={product.author.image?.thumbnail?.url} size={120} />
              <div>
                <PropertyItem label="Real name">
                  {product.author.realName}
                </PropertyItem>
                <PropertyItem label="Pseudonym">
                  {product.author.pseudonym}
                </PropertyItem>
              </div>
            </Grid>
            <Button
              component={HistoryLink}
              to={`/user/${product.author?.userId}`}
            >
              GO TO PROFILE
            </Button>
          </HoovusPaper>
        )}
      </Grid>

      <HoovusPaper>
        <PaperHeader>Images</PaperHeader>
        <ProductsWrapper>
          {images.map(image => (
            <ProductImage key={image.id}>
              <img src={image.thumbnail?.url} alt="" />
            </ProductImage>
          ))}
        </ProductsWrapper>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Visuals</PaperHeader>
        <PropertyItem label="Not suitable for children and general public">
          {nsfw ? "Not safe for work" : "Safe for work"}
        </PropertyItem>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Classification</PaperHeader>
        <Grid columns="1fr 1fr">
          <PropertyItem label="Medium">
            {medium && Translations.Enum.ArtMedium[medium]}
          </PropertyItem>
          <PropertyItem label="Support or surface">
            {surface ? Translations.Enum.Surface[surface] : undefined}
          </PropertyItem>
          <PropertyItem label="Artwork type">
            {isLimitedEdition ? "Limited edition" : "One of a kind"}
          </PropertyItem>
          {isLimitedEdition && (
            <>
              <PropertyItem label="Copies">{copies}</PropertyItem>
              <PropertyItem label="Copies for sale">
                {copiesForSale}
              </PropertyItem>
              <PropertyItem label="Copies out of trade">
                {copiesOutOfTrade}
              </PropertyItem>
              <PropertyItem label="Artist proofs">{artistProofs}</PropertyItem>
            </>
          )}
          <PropertyItem label="Signature">{signatures.join(", ")}</PropertyItem>
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Dimensions</PaperHeader>
        <Grid columns="1fr 1fr">
          <PropertyItem label="Width">
            {dimensionToString(width, "cm")}
          </PropertyItem>
          <PropertyItem label="Height">
            {dimensionToString(height, "cm")}
          </PropertyItem>
          <PropertyItem label="Thickness">
            {dimensionToString(thickness, "cm")}
          </PropertyItem>
          <PropertyItem label="Weight">
            {dimensionToString(weight, "kg")}
          </PropertyItem>
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Sales</PaperHeader>
        <Grid columns="1fr 1fr">
          <PropertyItem label="Status">
            {Translations.Enum.ProductStatus[status]}
          </PropertyItem>
          <PropertyItem label="Price">€{price}</PropertyItem>
        </Grid>
      </HoovusPaper>

      <HoovusPaper>
        <PaperHeader>Location</PaperHeader>
        <Grid columns="1fr 1fr">
          <PropertyItem label="Address line 1">{addressLine1}</PropertyItem>
          <PropertyItem label="Address line 2">{addressLine2}</PropertyItem>
          <PropertyItem label="City">{city}</PropertyItem>
          <PropertyItem label="State">{state}</PropertyItem>
          <PropertyItem label="Postal code">{postalCode}</PropertyItem>
          <PropertyItem label="Country">
            {country && Translations.Enum.Country[country]}
          </PropertyItem>
          <PropertyItem label="Phone">{phone}</PropertyItem>
          <PropertyItem label="Instructions">{instructions}</PropertyItem>
        </Grid>
      </HoovusPaper>
    </PageWrapper>
  )
}

export default compose<React.FC & StoreProps & DispatchProps>(
  privateRoute,
  connect(
    (state: StoreState) => ({
      product: state.product.activeProduct,
    }),
    {
      fetchProduct,
      deleteProduct,
      dispatchToast,
    }
  )
)(ProductPage)
